<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" rowspan="2">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" rowspan="2" colspan="3">
            <h1>
              Manpower Demand Requirement Application<br />中石化南京工程中东公司劳动力需求计划表
            </h1>
          </td>
          <td class="text-right">Application Date<br />申请日期</td>
          <td class="text-center">
            {{ RequestDate | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N<br />表单编号</td>
          <td class="text-center">
            <p>{{ ApplicationNumber }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-center">Project Name<br />项目名称</td>
          <td class="text-center">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">Subproject Name<br />分项目名称</td>
          <td class="text-center">
            {{ SubProjectID | projectFormater(SubProjectList) }}
          </td>
          <td class="text-center">Working Department<br />需求部门</td>
          <td class="text-center">
            {{ DepartmentID | departmentFormater(departmentList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Job Profession<br />岗位名称</td>
          <td class="text-center">
            {{ PositionID | positionFormater(positionList) }}
          </td>
          <td class="text-center">Vacancy Quantity<br />需求数量</td>
          <td class="text-center">
            {{ Qty }}
          </td>
          <td class="text-center">Expected Start Date<br />拟到岗日期</td>
          <td class="text-center">
            {{ WorkStartDate | tableDateFrom }}
          </td>
        </tr>

        <tr>
          <td class="text-center">Employment Type<br />用工形式</td>
          <td class="text-left" colspan="6">
            <div class="flex">
              <el-checkbox v-model="SNEMECEmployed"></el-checkbox>
              <div class="name">SNEMEC Employed 沙特直聘；</div>
              <el-checkbox v-model="KSAManpower"></el-checkbox>
              <div class="name">KSA Manpower 当地外包；</div>
            </div>
            <div class="flex">
              <el-checkbox v-model="SNEIEmployed"></el-checkbox>
              <div class="name">SNEI Employed 合同制；</div>
              <el-checkbox v-model="CHNManpower"></el-checkbox>
              <div class="name">CHN Manpower 国内外包；</div>
              <el-checkbox v-model="CHNSubContractor"></el-checkbox>
              <div class="name">CHN SubContractor 国内分包；</div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">Working duration<br />拟使用周期</td>
          <td class="text-left" colspan="6">
            <div class="flex">
              <el-checkbox
                @change="changeDurationType(1)"
                v-model="DurationType1"
              ></el-checkbox>
              <div>Less than 1 year 少于一年:</div>
              <div class="input-box">
                <el-input
                  disabled
                  v-model="DurationValue1"
                  @input="validateInput"
                ></el-input>
              </div>
              <div class="M">M</div>

              <el-checkbox
                @change="changeDurationType(2)"
                v-model="DurationType2"
              ></el-checkbox>
              <div>More than 1 year 大于一年:</div>
              <div class="input-box">
                <el-input
                  v-model="DurationValue2"
                  @input="validateInput2"
                  disabled
                ></el-input>
              </div>
              <div>Y</div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">Brief Job Description <br />工作内容描述</td>
          <td class="text-center2" colspan="5">
            <div class="div1">
              Please attach the job description for special position
              特殊岗位请附岗位说明书
            </div>
            <div class="div2">
              {{ JobDescription }}
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-left2 text-size" colspan="6">
            Certification and Qualifications Requirements 岗位素质要求
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="6">基本要求 General Requirement</td>
        </tr>
        <tr>
          <td class="text-center">
            <div>年龄Age</div>
          </td>
          <td class="text-center" colspan="2">
            <el-checkbox v-model="Age1"></el-checkbox><span>20-35Y</span>
            <el-checkbox v-model="Age2"></el-checkbox><span>35-40Y</span>
          </td>
          <td class="text-center">
            <div>性别Gender</div>
          </td>
          <td class="text-center" colspan="2">
            <el-checkbox v-model="Male"></el-checkbox><span>男Male</span>
            <el-checkbox v-model="Female"></el-checkbox><span>女Female</span>
          </td>
        </tr>

        <tr>
          <td class="text-center">Nationality 国籍</td>
          <td class="text-center" colspan="6">
            <el-checkbox v-model="Philippines"></el-checkbox><span>菲PHI</span>
            <el-checkbox v-model="India"></el-checkbox><span>印IND</span>
            <el-checkbox v-model="Bangladesh"></el-checkbox><span>孟BAN</span>
            <el-checkbox v-model="Nepal"></el-checkbox><span>尼NEP</span>
            <el-checkbox v-model="SaudiArabia"></el-checkbox><span>沙KSA</span>
            <el-checkbox v-model="Nationality"></el-checkbox
            ><span>其他Others</span>
            <div class="input-box">
              <el-input
                :disabled="!Nationality"
                v-model="NationalityOthers"
              ></el-input>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">Religion 宗教</td>
          <td class="text-center" colspan="5">
            <el-checkbox
              @change="religion(1)"
              v-model="NoReligionRequirement"
            ></el-checkbox
            ><span>不限N/A</span>
            <el-checkbox @change="religion(2)" v-model="Muslim"></el-checkbox
            ><span>穆斯林Muslim</span>
            <el-checkbox @change="religion(2)" v-model="NonMuslim"></el-checkbox
            ><span>非穆斯林Non-Muslim</span>
            <el-checkbox @change="religion(2)" v-model="Religion"></el-checkbox
            ><span>其他Others</span>
            <div class="input-box">
              <el-input
                :disabled="!Religion"
                v-model="ReligionOthers"
              ></el-input>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-left2 text-size" colspan="6">
            外语及计算机水平要求Language & computer skill
          </td>
        </tr>
        <tr>
          <td class="text-left" rowspan="3">
            语言要求与交流<br />
            Language & communication
          </td>
          <td colspan="5" class="text-left2">
            <span>英语English:</span>
            <el-checkbox
              @change="changeLanguage(1)"
              v-model="SpokenEnglish"
            ></el-checkbox
            ><span>口语Spoken Skill</span>
            <el-checkbox
              @change="changeLanguage(1)"
              v-model="WritingAndReadingEnglish"
            ></el-checkbox
            ><span>英语读写 Writing and Reading</span>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="text-left2">
            <span>阿语Arabic:</span>
            <el-checkbox
              @change="changeLanguage(1)"
              v-model="SpokenArabic"
            ></el-checkbox
            ><span>口语Spoken Skill</span>
            <el-checkbox
              @change="changeLanguage(1)"
              v-model="WritingAndReadingArabic"
            ></el-checkbox
            ><span>英语读写 Writing and Reading</span>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="text-left2">
            <div class="zhan"></div>
            <!-- <el-checkbox
              @change="changeLanguage(2)"
              v-model="NoLanguageRequirement"
            ></el-checkbox
            ><span>无N/A</span>
            <el-checkbox
              @change="changeLanguage(1)"
              v-model="Language"
            ></el-checkbox
            ><span>其他Others</span>
            <div class="input-box">
              <el-input
                :disabled="!Language"
                v-model="LanguageOther"
              ></el-input>
            </div> -->
          </td>
        </tr>

        <tr>
          <td class="text-left" rowspan="3">
            计算机水平<br />
            Computer Skills
          </td>
          <td colspan="5" class="text-left2">
            <el-checkbox
              @change="changeComputer(1)"
              v-model="GoodatMSOffice"
            ></el-checkbox
            ><span>熟练常规办公软件Good at MS Office (Word, Excel, PPT)</span>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="text-left2">
            <el-checkbox
              @change="changeComputer(1)"
              v-model="ComputerSkill"
            ></el-checkbox
            ><span>其他限N/A计算机软件Other Computer Skill</span>
            <!-- <div class="input-box">
              <el-input
                :disabled="!ComputerSkill"
                v-model="ComputerSkillOthers"
              ></el-input>
            </div> -->
          </td>
        </tr>
        <tr>
          <td colspan="5" class="text-left2">
            <div class="zhan"></div>
            <!-- <el-checkbox
              @change="changeComputer(2)"
              v-model="NoLimitComputerSkill"
            ></el-checkbox
            ><span>不限N/A</span> -->
          </td>
        </tr>
        <tr>
          <td class="text-left text-size" colspan="6">
            Working Experience & Certificates Requirement
            工作年限及上岗（从业）资质要求
          </td>
        </tr>

        <tr>
          <td class="text-center">Working Experience<br />本岗位工作年限</td>
          <td class="text-center" colspan="5">
            <el-checkbox
              @change="changeWorking(1)"
              v-model="WorkingExperienceOne"
            ></el-checkbox
            ><span>1Y</span>
            <el-checkbox
              @change="changeWorking(2)"
              v-model="WorkingExperienceTwo"
            ></el-checkbox
            ><span>1-2Yrs</span>
            <el-checkbox
              @change="changeWorking(3)"
              v-model="WorkingExperienceThree"
            ></el-checkbox
            ><span>3-5Yrs</span>
            <el-checkbox
              @change="changeWorking(4)"
              v-model="WorkingExperienceFive"
            ></el-checkbox
            ><span>more than 5Yrs</span>
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="4">
            Certificates Requiremen<br />上岗资质证书
          </td>
          <td class="text-center" colspan="2">
            <div>证书名称</div>
            <div>Name of certificate</div>
          </td>
          <td class="text-center">
            <div>等级</div>
            <div>Level</div>
          </td>
          <td class="text-center">
            <div>取证年限</div>
            <div>Date of issue</div>
          </td>
          <td class="text-center">
            <div>备注说明</div>
            <div>Remark</div>
          </td>
        </tr>
        <tr v-for="(item, index) in Details" :key="index">
          <td class="text-center2" colspan="2">
            <div class="div9">{{ item.NameOfCertificate }}</div>
          </td>
          <td class="text-center2">
            <div class="div9">{{ item.CertificateLevel }}</div>
          </td>
          <td class="text-center2">
            <div class="div9">{{ item.DateOfIssue }}</div>
          </td>
          <td class="text-center2">
            <div class="div9">{{ item.Remark }}</div>
          </td>
        </tr>

        <tr>
          <td class="text-center">
            Recommended Resource<br />
            有无推荐人选
          </td>
          <td class="text-center2" colspan="5">
            <el-checkbox
              @change="changeHas(1)"
              v-model="HasRecommendedResource1"
            ></el-checkbox
            ><span>无N/A</span>
            <el-checkbox
              @change="changeHas(2)"
              v-model="HasRecommendedResource2"
            ></el-checkbox
            ><span>有Yes</span>
            <div class="div3">
              If you have suitable recommendations, please upload their resumes
              and qualification certificates.
              <br />
              如有合适的推荐人选，请上传其个人简历和资质证书。
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">
            Working skill Requirement<br />
            其他上岗要求(技能）/ 备注 Remark
          </td>
          <td class="text-center" colspan="5">
            <div class="div9">{{ SpecialWorkingSkill }}</div>
          </td>
        </tr>
      </tbody>
    </table>

    <table
      class="customize-form customize-form2"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr></tr>
      </tbody>
    </table>

    <table
      class="customize-form customize-form2"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr>
          <td class="text-center" rowspan="2">Approval Record<br />审批记录</td>
          <td class="text-center2">
            <div class="div4">Approved by Dept. <br />单位/部门</div>

            <div class="textarea-box">
              {{ RequestDepartmentRemark }}
            </div>

            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="RequestDepartment"
                  :src="RequestDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span><span>{{ RequestDepartmentDate }}</span>
              </div>
            </div>
          </td>

          <td class="text-center2">
            <div class="div4">Approved by Proejct Authorized <br />项目部</div>

            <div class="textarea-box">
              {{ ProjectManagerRemark }}
            </div>

            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ProjectManager"
                  :src="ProjectManager"
                />
              </div>
              <div>
                <span>日期Date：</span><span>{{ ProjectManagerDate }}</span>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center2">
            <div class="div4">
              Approved by SNEMEC HR Dept. <br />中东公司人力资源部
            </div>

            <div class="textarea-box">
              {{ HRDepartmentOfBSNEIRemark }}
            </div>

            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="HRDepartmentOfBSNEI"
                  :src="HRDepartmentOfBSNEI"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ HRDepartmentOfBSNEIDate }}</span>
              </div>
            </div>
          </td>
          <td class="text-center2">
            <div class="div4">
              Approved by SNEMEC Authorized <br />中东公司授权人
            </div>

            <div class="textarea-box">
              {{ AuthorizedPersonOfBSNEIRemark }}
            </div>

            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="AuthorizedPersonOfBSNEI"
                  :src="AuthorizedPersonOfBSNEI"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ AuthorizedPersonOfBSNEIDate }}</span>
              </div>
            </div>
          </td>
        </tr>

        <!-- <tr>
          <td class="text-left text-size">
            <div class="name">备注</div>
            <div class="name">Remark</div>
          </td>
          <td class="text-left text-size">
            <el-input
              type="textarea"
              v-model="Remark"
              :rows="2"
              placeholder="请输入内容"
            >
            </el-input>
          </td>
        </tr> -->
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  addManpowerRequest,
  editManpowerRequest,
  getProjectAll,
  getDepartmentAll,
  allPosition,
  getProjectsChildren,
} from "@/api/user";
import { getNewDate } from "@/utils/date";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : "";
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : "";
    },
  },
  data() {
    return {
      projectList: [],
      departmentList: [],
      positionList: [],
      SubProjectList: [],
      TableDate: null,
      ApplicationNumber: null,
      ID: null,
      KeyID: null,
      ProjectID: null,
      DepartmentID: null,
      PositionID: null,
      SubProjectID: null,
      Qty: null,
      RequestDate: null,
      WorkStartDate: null,
      DurationType: null,
      DurationValue: null,
      DurationType1: false,
      DurationType2: false,
      DurationValue1: null,
      DurationValue2: null,
      lastInputContent: null,
      lastInputContent2: null,
      JobDescription: null,
      Age1: false,
      Age2: false,
      Male: false,
      Female: false,
      Philippines: false,
      India: false,
      Bangladesh: false,
      Nepal: false,
      SaudiArabia: false,
      Nationality: false,
      NationalityOthers: null,
      NoReligionRequirement: null,
      Muslim: false,
      NonMuslim: false,
      Religion: false,
      ReligionOthers: null,
      SpokenEnglish: false,
      WritingAndReadingEnglish: false,
      SpokenArabic: false,
      WritingAndReadingArabic: false,
      NoLanguageRequirement: false,
      Language: false,
      LanguageOther: null,
      GoodatMSOffice: false,
      ComputerSkillOthers: null,
      ComputerSkill: false,
      NoLimitComputerSkill: false,
      WorkingExperienceOne: false,
      WorkingExperienceTwo: false,
      WorkingExperienceThree: false,
      WorkingExperienceFive: false,
      SpecialWorkingSkill: null,
      HasRecommendedResource: false,
      HasRecommendedResource1: false,
      HasRecommendedResource2: false,
      RecommendedResourceBiographicalNotes: null,
      Remark: null,
      EditState: 1,

      SNEMECEmployed: false,
      KSAManpower: false,
      SNEIEmployed: false,
      CHNManpower: false,
      CHNSubContractor: false,
      Details: [
        {
          ID: null,
          KeyID: null,
          ManpowerRequestKeyID: null,
          NameOfCertificate: null,
          CertificateLevel: null,
          DateOfIssue: null,
          Remark: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerRequestKeyID: null,
          NameOfCertificate: null,
          CertificateLevel: null,
          DateOfIssue: null,
          Remark: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerRequestKeyID: null,
          NameOfCertificate: null,
          CertificateLevel: null,
          DateOfIssue: null,
          Remark: null,
        },
      ],

      RequestDepartment: null, //部门
      RequestDepartmentRemark: null,
      RequestDepartmentDate: null,

      HRDepartment: null,
      HRDepartmentRemark: null,
      HRDepartmentDate: null,

      ProjectManager: null,
      ProjectManagerRemark: null,
      ProjectManagerDate: null,

      RequestCategoryLinkedDepartmentOfBSNEI: null,
      RequestCategoryLinkedDepartmentOfBSNEIRemark: null,
      RequestCategoryLinkedDepartmentOfBSNEIDate: null,

      HRDepartmentOfBSNEI: null,
      HRDepartmentOfBSNEIRemark: null,
      HRDepartmentOfBSNEIDate: null,

      AuthorizedPersonOfBSNEI: null,
      AuthorizedPersonOfBSNEIRemark: null,
      AuthorizedPersonOfBSNEIDate: null,

      ApplicationNumber: null,
    };
  },
  methods: {
    //项目改变
    changeProjectID() {
      if (this.ProjectID) {
        this.getProjectsChildren(this.ProjectID);
      } else {
        this.SubProjectList = [];
      }
      this.SubProjectID = null;
    },
    getProjectsChildren(id) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.SubProjectList = res.response;
        } else {
          this.SubProjectList = [];
        }
      });
    },

    //创建时接收数据
    getCreateData(data1, data2, data3) {
      this.projectList = data1;
      this.departmentList = data2;
      this.positionList = data3;
      this.RequestDate = getNewDate();
    },
    //判断选中周期
    changeDurationType(type) {
      this.DurationType = type;
      if (type === 1) {
        if (this.DurationType1) {
          this.DurationType2 = false;
        }
      }
      if (type === 2) {
        if (this.DurationType2) {
          this.DurationType1 = false;
        }
      }
    },
    //只能输入一至12
    validateInput() {
      if (this.DurationValue1 === "") {
        this.lastInputContent = "";
        return;
      }
      var reg = /^([1-9]|1[0-2])$/;
      if (reg.test(this.DurationValue1) == false) {
        this.DurationValue1 = this.lastInputContent;
        return false;
      } else {
        this.lastInputContent = this.DurationValue1;
      }
    },
    //只能输入一至100
    validateInput2() {
      if (this.DurationValue2 === "") {
        this.lastInputContent2 = "";
        return;
      }
      var reg = /^(1|([1-9]\d{0,1})|100)$/;
      if (reg.test(this.DurationValue2) == false) {
        this.DurationValue2 = this.lastInputContent2;
        return false;
      } else {
        this.lastInputContent2 = this.DurationValue2;
      }
    },

    //宗教变化
    religion(type) {
      if (type === 1) {
        if (this.NoReligionRequirement) {
          this.Muslim = false;
          this.NonMuslim = false;
          this.Religion = false;
          this.ReligionOthers = null;
        }
      }
      if (type === 2) {
        if (this.Religion || this.NonMuslim || this.Muslim) {
          this.NoReligionRequirement = false;
        }
      }
    },

    //语言变化
    changeLanguage(type) {
      if (type === 2) {
        if (this.NoLanguageRequirement) {
          this.SpokenEnglish = false;
          this.WritingAndReadingEnglish = false;
          this.SpokenArabic = false;
          this.WritingAndReadingArabic = false;
          this.Language = false;
          this.LanguageOther = null;
        }
      }
      if (type === 1) {
        if (
          this.Language ||
          this.WritingAndReadingArabic ||
          this.SpokenArabic ||
          this.WritingAndReadingEnglish ||
          this.SpokenEnglish
        ) {
          this.NoLanguageRequirement = false;
        }
      }
    },
    //计算机变化
    changeComputer(type) {
      if (type === 1) {
        if (this.GoodatMSOffice || this.ComputerSkill) {
          this.NoLimitComputerSkill = false;
        }
      }
      if (type === 2) {
        if (this.NoLimitComputerSkill) {
          this.GoodatMSOffice = false;
          this.ComputerSkill = false;
          this.ComputerSkillOthers = null;
        }
      }
    },
    //工作年限变化
    changeWorking(type) {
      if (type === 1) {
        if (this.WorkingExperienceOne) {
          this.WorkingExperienceTwo = false;
          this.WorkingExperienceThree = false;
          this.WorkingExperienceFive = false;
        }
      }
      if (type === 2) {
        if (this.WorkingExperienceTwo) {
          this.WorkingExperienceOne = false;
          this.WorkingExperienceThree = false;
          this.WorkingExperienceFive = false;
        }
      }
      if (type === 3) {
        if (this.WorkingExperienceThree) {
          this.WorkingExperienceOne = false;
          this.WorkingExperienceTwo = false;
          this.WorkingExperienceFive = false;
        }
      }
      if (type === 4) {
        if (this.WorkingExperienceFive) {
          this.WorkingExperienceOne = false;
          this.WorkingExperienceThree = false;
          this.WorkingExperienceTwo = false;
        }
      }
    },
    //有无推荐人
    changeHas(type) {
      if (type === 1) {
        if (this.HasRecommendedResource1) {
          this.HasRecommendedResource2 = false;
          this.HasRecommendedResource = false;
        }
      }
      if (type === 2) {
        if (this.HasRecommendedResource2) {
          this.HasRecommendedResource1 = false;
          this.HasRecommendedResource = true;
        }
      }
    },

    //保存
    addManpowerRequest(type) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        DepartmentID: this.DepartmentID,
        PositionID: this.PositionID,
        Qty: this.Qty,
        RequestDate: this.RequestDate,
        WorkStartDate: this.WorkStartDate,
        DurationType: this.DurationType,
        DurationValue:
          this.DurationType === 1 ? this.DurationValue1 : this.DurationValue2,
        JobDescription: this.JobDescription,
        Age1: this.Age1,
        Age2: this.Age2,
        Male: this.Male,
        Female: this.Female,
        Philippines: this.Philippines,
        India: this.India,
        Bangladesh: this.Bangladesh,
        Nepal: this.Nepal,
        SaudiArabia: this.SaudiArabia,
        NationalityOthers: this.Nationality ? this.NationalityOthers : null,
        NoReligionRequirement: this.NoReligionRequirement,
        Muslim: this.Muslim,
        NonMuslim: this.NonMuslim,
        ReligionOthers: this.Religion ? this.ReligionOthers : null,
        SpokenEnglish: this.SpokenEnglish,
        WritingAndReadingEnglish: this.WritingAndReadingEnglish,
        SpokenArabic: this.SpokenArabic,
        WritingAndReadingArabic: this.WritingAndReadingArabic,
        NoLanguageRequirement: this.NoLanguageRequirement,
        LanguageOther: this.Language ? this.LanguageOther : null,
        GoodatMSOffice: this.GoodatMSOffice,
        ComputerSkillOthers: this.ComputerSkill
          ? this.ComputerSkillOthers
          : null,
        NoLimitComputerSkill: this.NoLimitComputerSkill,
        WorkingExperienceOne: this.WorkingExperienceOne,
        WorkingExperienceTwo: this.WorkingExperienceTwo,
        WorkingExperienceThree: this.WorkingExperienceThree,
        WorkingExperienceFive: this.WorkingExperienceFive,
        SpecialWorkingSkill: this.SpecialWorkingSkill,
        HasRecommendedResource: this.HasRecommendedResource,
        RecommendedResourceBiographicalNotes:
          this.RecommendedResourceBiographicalNotes,
        Remark: this.Remark,
        EditState: type,
        SNEMECEmployed: this.SNEMECEmployed,
        KSAManpower: this.KSAManpower,
        SNEIEmployed: this.SNEIEmployed,
        CHNManpower: this.CHNManpower,
        CHNSubContractor: this.CHNSubContractor,
        SubProjectID: this.SubProjectID,
        Details: [],
      };
      this.Details.forEach((item) => {
        if (item.NameOfCertificate) {
          data.Details.push(item);
        }
      });
      if (!data.ID) {
        addManpowerRequest(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editManpowerRequest(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },

    clearData() {
      this.projectList = [];
      this.companyList = [];
      this.departmentList = [];
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.DepartmentID = null;
      this.PositionID = null;
      this.Qty = null;
      this.RequestDate = null;
      this.WorkStartDate = null;
      this.DurationType = null;
      this.DurationValue = null;
      this.DurationType1 = false;
      this.DurationType2 = false;
      this.DurationValue1 = null;
      this.DurationValue2 = null;
      this.lastInputContent = null;
      this.lastInputContent2 = null;
      this.JobDescription = null;
      this.Age1 = false;
      this.Age2 = false;
      this.Male = false;
      this.Female = false;
      this.Philippines = false;
      this.India = false;
      this.Bangladesh = false;
      this.Nepal = false;
      this.SaudiArabia = false;
      this.Nationality = false;
      this.NationalityOthers = null;
      this.NoReligionRequirement = null;
      this.Muslim = false;
      this.NonMuslim = false;
      this.Religion = false;
      this.ReligionOthers = null;
      this.SpokenEnglish = false;
      this.WritingAndReadingEnglish = false;
      this.SpokenArabic = false;
      this.WritingAndReadingArabic = false;
      this.NoLanguageRequirement = false;
      this.Language = false;
      this.LanguageOther = null;
      this.GoodatMSOffice = false;
      this.ComputerSkillOthers = null;
      this.ComputerSkill = false;
      this.NoLimitComputerSkill = false;
      this.WorkingExperienceOne = false;
      this.WorkingExperienceTwo = false;
      this.WorkingExperienceThree = false;
      this.WorkingExperienceFive = false;
      this.SpecialWorkingSkill = null;
      this.HasRecommendedResource = false;
      this.HasRecommendedResource1 = false;
      this.HasRecommendedResource2 = false;
      this.RecommendedResourceBiographicalNotes = null;
      this.Remark = null;
      this.EditState = 1;
      this.ApplicationNumber = null;
      this.Details = [
        {
          ID: null,
          KeyID: null,
          ManpowerRequestKeyID: null,
          NameOfCertificate: null,
          CertificateLevel: null,
          DateOfIssue: null,
          Remark: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerRequestKeyID: null,
          NameOfCertificate: null,
          CertificateLevel: null,
          DateOfIssue: null,
          Remark: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerRequestKeyID: null,
          NameOfCertificate: null,
          CertificateLevel: null,
          DateOfIssue: null,
          Remark: null,
        },
      ];
      this.RequestDepartment = null; //部门
      this.RequestDepartmentRemark = null;
      this.RequestDepartmentDate = null;

      this.HRDepartment = null;
      this.HRDepartmentRemark = null;
      this.HRDepartmentDate = null;

      this.ProjectManager = null;
      this.ProjectManagerRemark = null;
      this.ProjectManagerDate = null;

      this.RequestCategoryLinkedDepartmentOfBSNEI = null;
      this.RequestCategoryLinkedDepartmentOfBSNEIRemark = null;
      this.RequestCategoryLinkedDepartmentOfBSNEIDate = null;

      this.HRDepartmentOfBSNEI = null;
      this.HRDepartmentOfBSNEIRemark = null;
      this.HRDepartmentOfBSNEIDate = null;

      this.AuthorizedPersonOfBSNEI = null;
      this.AuthorizedPersonOfBSNEIRemark = null;
      this.AuthorizedPersonOfBSNEIDate = null;
      this.SNEMECEmployed = false;
      this.KSAManpower = false;
      this.SNEIEmployed = false;
      this.CHNManpower = false;
      this.CHNSubContractor = false;
      this.SubProjectID = null;
    },

    //编辑回显
    getEditData(data1, data2, data3, data) {
      this.projectList = data1;
      this.departmentList = data2;
      this.positionList = data3;
      this.ApplicationNumber = data.ApplicationNumber;
      this.TableDate = data.TableDate;
      this.ID = data.ID;
      this.KeyID = data.KeyID;
      this.ProjectID = data.ProjectID;
      this.getProjectsChildren(this.ProjectID);
      this.DepartmentID = data.DepartmentID;
      this.PositionID = data.PositionID;
      this.Qty = data.Qty;
      this.RequestDate = data.RequestDate;
      this.WorkStartDate = data.WorkStartDate;
      this.DurationType = data.DurationType;
      if (data.DurationType === 1) {
        this.DurationType1 = true;
        this.DurationValue1 = data.DurationValue;
      } else {
        this.DurationType2 = true;
        this.DurationValue2 = data.DurationValue;
      }
      this.JobDescription = data.JobDescription;
      this.Age1 = data.Age1;
      this.Age2 = data.Age2;
      this.Male = data.Male;
      this.Female = data.Female;
      this.Philippines = data.Philippines;
      this.India = data.India;
      this.Bangladesh = data.Bangladesh;
      this.Nepal = data.Nepal;
      this.SaudiArabia = data.SaudiArabia;
      this.Nationality = data.NationalityOthers ? true : false;
      this.NationalityOthers = data.NationalityOthers;
      this.NoReligionRequirement = data.NoReligionRequirement;
      this.Muslim = data.Muslim;
      this.NonMuslim = data.NonMuslim;
      this.Religion = data.ReligionOthers ? true : false;
      this.ReligionOthers = data.ReligionOthers;
      this.SpokenEnglish = data.SpokenEnglish;
      this.WritingAndReadingEnglish = data.WritingAndReadingEnglish;
      this.SpokenArabic = data.SpokenArabic;
      this.WritingAndReadingArabic = data.WritingAndReadingArabic;
      this.NoLanguageRequirement = data.NoLanguageRequirement;
      this.Language = data.LanguageOther ? true : false;
      this.LanguageOther = data.LanguageOther;
      this.GoodatMSOffice = data.GoodatMSOffice;
      this.ComputerSkillOthers = data.ComputerSkillOthers;
      this.ComputerSkill = data.ComputerSkillOthers ? true : false;
      this.NoLimitComputerSkill = data.NoLimitComputerSkill;
      this.WorkingExperienceOne = data.WorkingExperienceOne;
      this.WorkingExperienceTwo = data.WorkingExperienceTwo;
      this.WorkingExperienceThree = data.WorkingExperienceThree;
      this.WorkingExperienceFive = data.WorkingExperienceFive;
      this.SpecialWorkingSkill = data.SpecialWorkingSkill;
      this.HasRecommendedResource1 = !data.HasRecommendedResource;
      this.HasRecommendedResource2 = data.HasRecommendedResource;
      this.RecommendedResourceBiographicalNotes =
        data.RecommendedResourceBiographicalNotes;
      this.Remark = data.Remark;
      this.ApplicationNumber = data.ApplicationNumber;

      this.RequestDepartmentRemark = data.RequestDepartmentRemark;
      this.RequestDepartment = data.RequestDepartment;
      this.RequestDepartmentDate = data.RequestDepartmentDate;

      this.HRDepartment = data.HRDepartment;
      this.HRDepartmentRemark = data.HRDepartmentRemark;
      this.HRDepartmentDate = data.HRDepartmentDate;

      this.ProjectManager = data.ProjectManager;
      this.ProjectManagerRemark = data.ProjectManagerRemark;
      this.ProjectManagerDate = data.ProjectManagerDate;

      this.RequestCategoryLinkedDepartmentOfBSNEI =
        data.RequestCategoryLinkedDepartmentOfBSNEI;
      this.RequestCategoryLinkedDepartmentOfBSNEIRemark =
        data.RequestCategoryLinkedDepartmentOfBSNEIRemark;
      this.RequestCategoryLinkedDepartmentOfBSNEIDate =
        data.RequestCategoryLinkedDepartmentOfBSNEIDate;

      this.HRDepartmentOfBSNEI = data.HRDepartmentOfBSNEI;
      this.HRDepartmentOfBSNEIRemark = data.HRDepartmentOfBSNEIRemark;
      this.HRDepartmentOfBSNEIDate = data.HRDepartmentOfBSNEIDate;

      this.AuthorizedPersonOfBSNEI = data.AuthorizedPersonOfBSNEI;
      this.AuthorizedPersonOfBSNEIRemark = data.AuthorizedPersonOfBSNEIRemark;
      this.AuthorizedPersonOfBSNEIDate = data.AuthorizedPersonOfBSNEIDate;

      this.SNEMECEmployed = data.SNEMECEmployed;
      this.KSAManpower = data.KSAManpower;
      this.SNEIEmployed = data.SNEIEmployed;
      this.CHNManpower = data.CHNManpower;
      this.CHNSubContractor = data.CHNSubContractor;
      this.SubProjectID = data.SubProjectID;
      if (data.Details) {
        this.Details.forEach((_, index, Array) => {
          if (data.Details[index]) {
            Array[index] = data.Details[index];
          }
        });
      }
      this.Details = this.Details.concat();
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #000;
      .tbale-img1 {
        width: 100%;
      }
      h1 {
        font-size: 20px;
        color: #000;
        margin: 10px 0;
      }
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
        margin-left: 5px;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
    .text-center2 {
      font-size: 13px;
      color: #000;
      .el-checkbox {
        margin: 10px 0;
        margin-left: 10px;
      }
      span {
        margin-right: 20px;
        margin-left: 5px;
      }
      .div1 {
        line-height: 40px;
        border-bottom: 1px solid #e0e0e0;
      }
      .div4 {
        line-height: 22px;
        border-bottom: 1px solid #e0e0e0;
        text-align: center;
        padding: 5px;
      }
      .div3 {
        line-height: 22px;
        border-top: 1px solid #e0e0e0;
        padding: 5px;
      }
      .div2 {
        padding: 5px;
        height: 100px;
        display: flex;
        align-items: center;
      }
      .div5 {
        padding: 5px;
      }
      .div9 {
        padding: 5px;
        min-height: 40px;
        line-height: 40px;
        text-align: center;
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        min-height: 30px;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
    }
    .text-left {
      padding: 5px;
      font-size: 13px;
      color: #000;
      .flex {
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        color: #000;
        align-items: center;
        .el-checkbox {
          font-size: 13px;
          color: #000;

          margin: 5px 10px;
        }
        .M {
          margin-right: 30px;
        }
        .name {
          margin-right: 30px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
    }
    .text-left2 {
      text-align: left;
      font-size: 13px;
      color: #000;
      padding: 5px;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
        margin-left: 5px;
      }
      .zhan {
        min-height: 20px;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
    .text-right {
      font-size: 13px;
      text-align: right;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.sign {
  width: 100%;
  max-width: 100px;
}
.el-input >>> .el-input__inner {
  background: #fff !important;
  color: #000 !important;
}
.textarea-box {
  min-height: 50px;
  line-height: 20px;
  font-size: 14px;
  color: #606266;
  border: none;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>