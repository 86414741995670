<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td rowspan="2" colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="6">
            Flight Ticket Change Application 机票变更申请
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center">Project Name<br />项目名称</td>
          <td class="text-center">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">SubProject Name<br />分项目名称</td>
          <td class="text-center">
            {{ SubProjectID | projectFormater(subProjectList) }}
          </td>
          <td class="text-center">Working Unit<br />用工单位</td>
          <td class="text-center">
            {{ CompanyID | companyFormater(companyList) }}
          </td>
          <td class="text-center">Working Department<br />部门信息</td>
          <td class="text-center">
            {{ DepartmentID | departmentFormater(departmentList) }}
          </td>
          <td class="text-center">Job Profession<br />岗位名称</td>
          <td class="text-center">
            {{ JobProfession | positionFormater(positionList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">Employee ID<br />员工编码</td>
          <td class="text-center" rowspan="2">
            {{ EmployeeCode }}
          </td>
          <td class="text-center">Employee Name<br />员工姓名</td>
          <td class="text-center">
            {{ EmployeeName }}
          </td>
          <td class="text-center">Nationality<br />国籍</td>
          <td class="text-center">
            {{ Nationality | nationalityFormater(nationalityList) }}
          </td>
          <td class="text-center">Passport No.<br />护照号</td>
          <td class="text-center">
            {{ PassportCode }}
          </td>
          <td class="text-center">IQAMA No.<br />暂住证号</td>
          <td class="text-center">
            {{ IQAMAID }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Employee Name<br />拼音姓名</td>
          <td class="text-center">
            {{ EmployeeNameEN }}
          </td>
          <td class="text-center">
            Visa Type<br />
            签证类型
          </td>
          <td class="text-center">
            {{ VisaType | labelFormater(VisaTypeOptions) }}
          </td>
          <td class="text-center">
            Visa Expiry Date<br />
            签证有效期
          </td>
          <td class="text-center">
            {{ VisaExpiryDate | tableDateFrom }}
          </td>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">
            {{ Sponsor | labelFormater(options) }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Reason of Reschedule<br />机票变更原因描述
          </td>
          <td class="text-center" colspan="8">
            {{ ReasonofReschedule }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Ticket Reschedule Info.<br />
            机票变更信息
          </td>
          <td class="text-center">
            Reschedule Status<br />
            变更状态
          </td>
          <td class="text-center">
            Ticket Reference<br />
            机票编号
          </td>
          <td class="text-center">
            One Way/<br />
            Round-Trip
          </td>
          <td class="text-center">
            Air Ticket Route<br />
            飞行路线
          </td>
          <td class="text-center">
            Departure Date from KSA<br />
            沙特起飞日期
          </td>
          <td class="text-center">
            Aarrival Date at KSA<br />
            沙特到达日期
          </td>
          <td class="text-center">
            Days of Duration<br />
            动迁天数
          </td>
          <td class="text-center">
            Remark<br />
            备注
          </td>
        </tr>
        <tr>
          <td class="text-right" colspan="2">
            Previous Ticket Info<br />
            原机票信息
          </td>
          <td class="text-center" rowspan="2">
            {{ RescheduleStatus | labelFormater(options3) }}
          </td>
          <td class="text-center">{{ TicketReferenceofPreviousTicket }}</td>
          <td class="text-center">
            {{ OneWayorRoundTripofPreviousTicket | labelFormater(options4) }}
          </td>
          <td class="text-center">
            {{
              AirTicketRouteofPreviousTicket | ticketFormater(ticketGroupList)
            }}
          </td>
          <td class="text-center">
            {{ DepartureDatefromKSAofPreviousTicket | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ AarrivalDateatKSAofPreviousTicket | tableDateFrom }}
          </td>
          <td class="text-center">{{ DaysofDurationofPreviousTicket }}</td>
          <td class="text-center">-</td>
        </tr>
        <tr>
          <td class="text-right" colspan="2">
            Reschedule Ticket Info<br />
            变更后机票信息
          </td>
          <td class="text-center">
            {{ TicketReferenceofRescheduleTicket }}
          </td>
          <td class="text-center">
            {{ OneWayorRoundTripofRescheduleTicket | labelFormater(options4) }}
          </td>
          <td class="text-center">
            <span v-if="AirTicketRouteofRescheduleTicket">
              {{
                AirTicketRouteofRescheduleTicket |
                ticketFormater(ticketGroupList)
              }}
            </span>
          </td>
          <td class="text-center">
            {{ DepartureDatefromKSAofRescheduleTicket | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ AarrivalDateatKSAofRescheduleTicket | tableDateFrom }}
          </td>
          <td class="text-center">
            {{
              DaysofDurationofRescheduleTicket
                ? DaysofDurationofRescheduleTicket
                : "N/A"
            }}
          </td>
          <td class="text-center">
            {{ RemarkofRescheduleTicket }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">Project 项目</td>
          <td class="text-center">
            Admin of Working Unit<br />
            用工单位综合部
          </td>
          <td class="text-center" colspan="2">
            {{ WorkingUnitAdminDeptRemark }}
          </td>
          <td class="text-center">
            Manager of Working Unit<br />
            单位负责人
          </td>
          <td class="text-center" colspan="5">
            {{ WorkingUnitManagerRemark }}
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Project Administration Dept.<br />
            项目综合部
          </td>
          <td class="text-center" colspan="2">{{ ProjectAdminDeptRemark }}</td>
          <td class="text-center">
            Manager of Project<br />
            项目经理
          </td>
          <td class="text-center" colspan="5">{{ ProjectManagerRemark }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">SNEMEC 中东公司</td>
          <td class="text-center">
            HR Dept.<br />
            人力资源部
          </td>
          <td class="text-center" colspan="2">{{ HRDeptRemark }}</td>
          <td class="text-center">
            SNEMEC Authorized<br />
            中东公司领导
          </td>
          <td class="text-center" colspan="5">{{ SNEMECAuthorizedRemark }}</td>
        </tr>
        <tr>
          <td class="text-center">
            HR Officer<br />
            人事专员
          </td>
          <td class="text-center" colspan="2">{{ HROfficerRemark }}</td>
          <td class="text-center">
            Foreign Affair Officer <br />
            外事专员
          </td>
          <td class="text-center" colspan="2">
            {{ ForeignAffairOfficerRemark }}
          </td>
          <td class="text-center">
            Foreign Affair Officer <br />
            票务专员
          </td>
          <td class="text-center" colspan="2">{{ TicketOfficerRemark }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Note <br />
            备注
          </td>
          <td class="text-center" colspan="3" rowspan="2">
            {{ HROfficerMemo }}
          </td>
          <td class="text-right" colspan="2">
            Change Foreign Relations of Applicant to<br />
            申请人员外事关系变更为
          </td>
          <td class="text-center">
            {{ ChangeForeignRelationsofApplicantto | labelFormater(options2) }}
          </td>
          <td class="text-center" colspan="3" rowspan="2">
            {{ TicketOfficerMemo }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            {{ ForeignAffairOfficerMemo }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getProjectsChildren,
  getDepartmentAll,
  uploadAttachment,
} from "@/api/user";
import {
  getUsersCode,
  getGeneralBasic,
  getLastestTicketBooking,
  getAirlinesAll,
  addFlightTicketRescheduleApplication,
  editFlightTicketRescheduleApplicationItem,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    ticketFormater(id, data) {
      if (!data) {
        return;
      }
      if (!id) {
        return;
      }
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item
        ? `${item.DeparatureCityName}——${item.ArrivalCityName}`
        : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      positionList: [],
      nationalityList: [],
      CostAllocationDetails: [],
      religionsList: [],
      subProjectList: [],
      departmentList: [],
      ticketGroupList: [],
      ID: null,
      KeyID: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      DepartmentID: null,
      JobProfession: null,
      EmployeeCode: null,
      EmployeeName: null,
      EmployeeNameEN: null,
      Nationality: null,
      PassportCode: null,
      IQAMAID: null,
      VisaType: null,
      VisaExpiryDate: null,
      Sponsor: null,
      ReasonofReschedule: null,
      RescheduleStatus: null,
      TicketReferenceofPreviousTicket: null,
      OneWayorRoundTripofPreviousTicket: null,
      AirTicketRouteofPreviousTicket: null,
      AirTicketRouteNameofPreviousTicket: null,
      DepartureDatefromKSAofPreviousTicket: null,
      AarrivalDateatKSAofPreviousTicket: null,
      DaysofDurationofPreviousTicket: null,
      RemarkofPreviousTicket: null,
      RescheduleStatusofRescheduleTicket: null,
      TicketReferenceofRescheduleTicket: null,
      OneWayorRoundTripofRescheduleTicket: null,
      AirTicketRouteofRescheduleTicket: null,
      AirTicketRouteNameofRescheduleTicket: null,
      DepartureDatefromKSAofRescheduleTicket: null,
      AarrivalDateatKSAofRescheduleTicket: null,
      DaysofDurationofRescheduleTicket: null,
      RemarkofRescheduleTicket: null,
      HROfficerMemo: null,
      ForeignAffairOfficerMemo: null,
      TicketOfficerMemo: null,
      ChangeForeignRelationsofApplicantto: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      VisaTypeOptions: [
        {
          value: 1,
          label: "Re-entry Visa",
        },
        {
          value: 2,
          label: "Exit Visa",
        },
      ],
      options: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      options2: [
        {
          label: "在沙",
          value: 1,
        },
        {
          label: "保留",
          value: 2,
        },
        {
          label: "调离",
          value: 3,
        },
        {
          label: "休假",
          value: 5,
        },
        {
          label: "访问",
          value: 6,
        },
      ],
      options3: [
            {
          label: "Reschedul",
          value: 2,
        },
        {
          label: "Open",
          value: 3,
        },

        {
          label: "Cancel",
          value: 4,
        },
      ],
      options4: [
        {
          label: "One Way",
          value: 1,
        },
        {
          label: "Round-Trip",
          value: 2,
        },
      ],
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.AarrivalDateatKSAofRescheduleTicket) {
            return (
              time.getTime() >
              new Date(this.AarrivalDateatKSAofRescheduleTicket).getTime() -
                8.64e7
            );
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.DepartureDatefromKSAofRescheduleTicket) {
            return (
              time.getTime() <
              new Date(this.DepartureDatefromKSAofRescheduleTicket).getTime()
            );
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      WorkingUnitAdminDeptRemark: null,
      WorkingUnitManagerRemark: null,
      ProjectAdminDeptRemark: null,
      ProjectManagerRemark: null,
      HRDeptRemark: null,
      SNEMECAuthorizedRemark: null,
      HROfficerRemark: null,
      ForeignAffairOfficerRemark: null,
      TicketOfficerRemark: null,
    };
  },

  methods: {
    //变更后日期改变
    changeDate() {
      if (
        this.DepartureDatefromKSAofRescheduleTicket &&
        this.AarrivalDateatKSAofRescheduleTicket
      ) {
        this.DaysofDurationofRescheduleTicket = this.compareDates(
          this.DepartureDatefromKSAofRescheduleTicket,
          this.AarrivalDateatKSAofRescheduleTicket
        );
      }
    },
    compareDates(date1, date2) {
      // 将字符串转换为Date对象
      var d1 = new Date(date1);
      var d2 = new Date(date2);

      // 获取时间戳（单位：毫秒）
      var timeDiff = Math.abs(d2.getTime() - d1.getTime());

      // 计算天数差
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays+1;
    },

    //变更状态
    changeRescheduleStatus() {
      if (this.RescheduleStatus !== 2) {
        this.TicketReferenceofRescheduleTicket = null;
        this.OneWayorRoundTripofRescheduleTicket = null;
        this.AirTicketRouteofRescheduleTicket = null;
        this.DepartureDatefromKSAofRescheduleTicket = null;
        this.AarrivalDateatKSAofRescheduleTicket = null;
        this.DaysofDurationofRescheduleTicket = null;
        this.RemarkofRescheduleTicket = null;
      }
    },
    getNew() {
      this.TableDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        DepartmentID: this.DepartmentID,
        JobProfession: this.JobProfession,
        EmployeeCode: this.EmployeeCode,
        EmployeeName: this.EmployeeName,
        EmployeeNameEN: this.EmployeeNameEN,
        Nationality: this.Nationality,
        PassportCode: this.PassportCode,
        IQAMAID: this.IQAMAID,
        VisaType: this.VisaType,
        VisaExpiryDate: this.VisaExpiryDate,
        Sponsor: this.Sponsor,
        ReasonofReschedule: this.ReasonofReschedule,
        RescheduleStatus: this.RescheduleStatus,
        TicketReferenceofPreviousTicket: this.TicketReferenceofPreviousTicket,
        OneWayorRoundTripofPreviousTicket:
          this.OneWayorRoundTripofPreviousTicket,
        AirTicketRouteofPreviousTicket: this.AirTicketRouteofPreviousTicket,
        AirTicketRouteNameofPreviousTicket:
          this.AirTicketRouteNameofPreviousTicket,
        DepartureDatefromKSAofPreviousTicket:
          this.DepartureDatefromKSAofPreviousTicket,
        AarrivalDateatKSAofPreviousTicket:
          this.AarrivalDateatKSAofPreviousTicket,
        DaysofDurationofPreviousTicket: this.DaysofDurationofPreviousTicket,
        RemarkofPreviousTicket: this.RemarkofPreviousTicket,
        RescheduleStatusofRescheduleTicket:
          this.RescheduleStatusofRescheduleTicket,
        TicketReferenceofRescheduleTicket:
          this.TicketReferenceofRescheduleTicket,
        OneWayorRoundTripofRescheduleTicket:
          this.OneWayorRoundTripofRescheduleTicket,
        AirTicketRouteofRescheduleTicket: this.AirTicketRouteofRescheduleTicket,
        AirTicketRouteNameofRescheduleTicket:
          this.AirTicketRouteNameofRescheduleTicket,
        DepartureDatefromKSAofRescheduleTicket:
          this.DepartureDatefromKSAofRescheduleTicket,
        AarrivalDateatKSAofRescheduleTicket:
          this.AarrivalDateatKSAofRescheduleTicket,
        DaysofDurationofRescheduleTicket: this.DaysofDurationofRescheduleTicket,
        RemarkofRescheduleTicket: this.RemarkofRescheduleTicket,
        HROfficerMemo: this.HROfficerMemo,
        ForeignAffairOfficerMemo: this.ForeignAffairOfficerMemo,
        TicketOfficerMemo: this.TicketOfficerMemo,
        ChangeForeignRelationsofApplicantto:
          this.ChangeForeignRelationsofApplicantto,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
      };

      if (!this.ID) {
        addFlightTicketRescheduleApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editFlightTicketRescheduleApplicationItem(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    getEditData(params) {
      console.log("params", params);
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.DepartmentID = params.DepartmentID;
      this.JobProfession = params.JobProfession;
      this.EmployeeCode = params.EmployeeCode;
      this.EmployeeName = params.EmployeeName;
      this.EmployeeNameEN = params.EmployeeNameEN;
      this.Nationality = params.Nationality;
      this.PassportCode = params.PassportCode;
      this.IQAMAID = params.IQAMAID;
      this.VisaType = params.VisaType;
      this.VisaExpiryDate = params.VisaExpiryDate;
      this.Sponsor = params.SPONSOR;
      this.ReasonofReschedule = params.ReasonofReschedule;
      this.RescheduleStatus = params.RescheduleStatus;
      this.TicketReferenceofPreviousTicket =
        params.TicketReferenceofPreviousTicket;
      this.OneWayorRoundTripofPreviousTicket =
        params.OneWayorRoundTripofPreviousTicket;
      this.AirTicketRouteofPreviousTicket =
        params.AirTicketRouteofPreviousTicket;
      this.AirTicketRouteNameofPreviousTicket =
        params.AirTicketRouteNameofPreviousTicket;
      this.DepartureDatefromKSAofPreviousTicket =
        params.DepartureDatefromKSAofPreviousTicket;
      this.AarrivalDateatKSAofPreviousTicket =
        params.AarrivalDateatKSAofPreviousTicket;
      this.DaysofDurationofPreviousTicket =
        params.DaysofDurationofPreviousTicket;
      this.RemarkofPreviousTicket = params.RemarkofPreviousTicket;
      this.RescheduleStatusofRescheduleTicket =
        params.RescheduleStatusofRescheduleTicket;
      this.TicketReferenceofRescheduleTicket =
        params.TicketReferenceofRescheduleTicket;
      this.OneWayorRoundTripofRescheduleTicket =
        params.OneWayorRoundTripofRescheduleTicket;
      this.AirTicketRouteofRescheduleTicket =
        params.AirTicketRouteofRescheduleTicket;
      this.AirTicketRouteNameofRescheduleTicket =
        params.AirTicketRouteNameofRescheduleTicket;
      this.DepartureDatefromKSAofRescheduleTicket =
        params.DepartureDatefromKSAofRescheduleTicket;
      this.AarrivalDateatKSAofRescheduleTicket =
        params.AarrivalDateatKSAofRescheduleTicket;
      this.DaysofDurationofRescheduleTicket =
        params.DaysofDurationofRescheduleTicket;
      this.RemarkofRescheduleTicket = params.RemarkofRescheduleTicket;
      this.HROfficerMemo = params.HROfficerMemo;
      this.ForeignAffairOfficerMemo = params.ForeignAffairOfficerMemo;
      this.TicketOfficerMemo = params.TicketOfficerMemo;
      this.ChangeForeignRelationsofApplicantto =
        params.ChangeForeignRelationsofApplicantto;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.getProjectsChildren();
      this.WorkingUnitAdminDeptRemark = params.WorkingUnitAdminDeptRemark;
      this.WorkingUnitManagerRemark = params.WorkingUnitManagerRemark;
      this.ProjectAdminDeptRemark = params.ProjectAdminDeptRemark;
      this.ProjectManagerRemark = params.ProjectManagerRemark;
      this.HRDeptRemark = params.HRDeptRemark;
      this.SNEMECAuthorizedRemark = params.SNEMECAuthorizedRemark;
      this.HROfficerRemark = params.HROfficerRemark;
      this.ForeignAffairOfficerRemark = params.ForeignAffairOfficerRemark;
      this.TicketOfficerRemark = params.TicketOfficerRemark;
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.DepartmentID = null;
      this.JobProfession = null;
      this.EmployeeCode = null;
      this.EmployeeName = null;
      this.EmployeeNameEN = null;
      this.Nationality = null;
      this.PassportCode = null;
      this.IQAMAID = null;
      this.VisaType = null;
      this.VisaExpiryDate = null;
      this.Sponsor = null;
      this.ReasonofReschedule = null;
      this.RescheduleStatus = null;
      this.TicketReferenceofPreviousTicket = null;
      this.OneWayorRoundTripofPreviousTicket = null;
      this.AirTicketRouteofPreviousTicket = null;
      this.AirTicketRouteNameofPreviousTicket = null;
      this.DepartureDatefromKSAofPreviousTicket = null;
      this.AarrivalDateatKSAofPreviousTicket = null;
      this.DaysofDurationofPreviousTicket = null;
      this.RemarkofPreviousTicket = null;
      this.RescheduleStatusofRescheduleTicket = null;
      this.TicketReferenceofRescheduleTicket = null;
      this.OneWayorRoundTripofRescheduleTicket = null;
      this.AirTicketRouteofRescheduleTicket = null;
      this.AirTicketRouteNameofRescheduleTicket = null;
      this.DepartureDatefromKSAofRescheduleTicket = null;
      this.AarrivalDateatKSAofRescheduleTicket = null;
      this.DaysofDurationofRescheduleTicket = null;
      this.RemarkofRescheduleTicket = null;
      this.HROfficerMemo = null;
      this.ForeignAffairOfficerMemo = null;
      this.TicketOfficerMemo = null;
      this.ChangeForeignRelationsofApplicantto = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;

      this.WorkingUnitAdminDeptRemark = null;
      this.WorkingUnitManagerRemark = null;
      this.ProjectAdminDeptRemark = null;
      this.ProjectManagerRemark = null;
      this.HRDeptRemark = null;
      this.SNEMECAuthorizedRemark = null;
      this.HROfficerRemark = null;
      this.ForeignAffairOfficerRemark = null;
      this.TicketOfficerRemark = null;
    },
    async changePassportCode() {
      if (!this.PassportCode) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: this.PassportCode, codeType: 1 }).then(
        (res) => {
          if (res.status === 200 && res.response) {
            userInfo = res.response;
          }
        }
      );
      if (!userInfo) {
        return;
      }
      this.ProjectID = userInfo.ProjectID;
      this.SubProjectID = userInfo.SubProjectID;
      this.CompanyID = userInfo.CompanyID;
      this.DepartmentID = userInfo.DepartmentID;
      this.JobProfession = userInfo.PositionID;
      this.EmployeeCode = userInfo.UserCode;
      this.EmployeeName = userInfo.ChineseName;
      this.EmployeeNameEN = userInfo.EnglishName;
      this.Nationality = userInfo.CountryID;
      this.IQAMAID = userInfo.IQAMAID;
      this.Sponsor = userInfo.Sponsor;
      this.LastVacationPeriod = userInfo.LastVacationPeriod;
      this.DeservedVacationPeriod = userInfo.DeservedVacationPeriod;
      this.DueVacationDays = userInfo.DueVacationDays;
      this.getProjectsChildren();
      getLastestTicketBooking({ ppCode: this.PassportCode }).then((res) => {
        if (res.status === 200 && res.response) {
          console.log("最后一次机票信息", res.response);
          const data = res.response;
          this.TicketReferenceofPreviousTicket = data.TicketReference;
          this.OneWayorRoundTripofPreviousTicket = data.OneWayorRoundTrip;
          this.AirTicketRouteofPreviousTicket = data.AirTicketRoute;
          this.AirTicketRouteNameofPreviousTicket =
            data.AirTicketRouteNameofPreviousTicket;
          this.DepartureDatefromKSAofPreviousTicket =
            data.DeparatureDatefromKSA;
          this.AarrivalDateatKSAofPreviousTicket = data.ArrivalDateatKSA;
          this.DaysofDurationofPreviousTicket = data.DaysofDuration;
        }
      });
      console.log("userInfo", userInfo);
    },
    changeProjectID() {
      this.SubProjectID = null;
      this.getProjectsChildren();
    },
    //获取列表子项目
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },

    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    this.TableDate = getNewDate();

    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍和宗教信仰
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.religionsList = res.response.Religions;
        console.log("国籍", this.nationalityList);
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  justify-content: center;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>