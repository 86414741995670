var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customize-main"},[_c('table',{staticClass:"customize-form",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("tableDateFrom")(_vm.TableDate)))])]),_c('tr',[_vm._m(3),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.ApplicationNumber))])]),_vm._m(4),_vm._l((_vm.Details),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"name no"},[_vm._v(_vm._s(index + 1))])]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm._f("SpecialityNameListFrom")(item.Category,_vm.SpecialityNameList))+" ")])]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm._f("positionListFrom")(item.JobProfession,_vm.positionList))+" ")])]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm._f("labelFrom")(item.QulificationID,_vm.opinions1))+" ")])]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.MinimumRate)+" ")])]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.MaximumRate)+" ")])]),_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.Remark)+" ")])])])}),_vm._m(5),_vm._m(6),_c('tr',[_c('td',{staticClass:"text-title",attrs:{"colspan":"2"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img",attrs:{"src":_vm.ApprovedbySNEMCHRDepartment}})])]),_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img",attrs:{"src":_vm.ApprovedbySNEMCAuthorized}})])])])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"rowspan":"2","colspan":"2"}},[_c('img',{staticClass:"teding",attrs:{"src":require("@/assets/image/teding.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"customize-title1",attrs:{"rowspan":"2","colspan":"3"}},[_vm._v(" 沙特地区当地岗位服务外包人员单价信息"),_c('br'),_vm._v(" Saudi Manpower Recruitment Hourly ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_vm._v("Application Date "),_c('br'),_vm._v("申请日期")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_vm._v("Workflow Table S/N "),_c('br'),_vm._v("表单编号")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("SN")]),_c('td',{staticClass:"text-center"},[_vm._v("Category & Discipline")]),_c('td',{staticClass:"text-center"},[_vm._v("Job Profession")]),_c('td',{staticClass:"text-center"},[_vm._v("Qualification")]),_c('td',{staticClass:"text-center"},[_vm._v("Min Rate (SAR/Hr)")]),_c('td',{staticClass:"text-center"},[_vm._v("Max Rate (SAR/Hr)")]),_c('td',{staticClass:"text-center"},[_vm._v("Remark")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Note")]),_c('td',{staticClass:"text-left",attrs:{"colspan":"6"}},[_vm._v(" Food Accomdation and Transportation Provided by Local Supplier. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2","rowspan":"2"}},[_vm._v(" Approval Record"),_c('br'),_vm._v(" 审批记录 ")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" Approved by SNEMC HR Department 中东公司人力资源部 ")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(" Approved by SNEMC Authorized 中东公司授权人 ")])])
}]

export { render, staticRenderFns }