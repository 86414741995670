<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="3">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="9">
            Invitation Letter Application of Sinopec Nanjing Engineering Middle
            East Co., Ltd
            <br />
            中石化南京工程中东公司访问签证邀请函办理申请表
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2" colspan="3">
            Company Name of Invitees<br />
            受邀人所属公司信息
          </td>
          <td class="text-center">
            Company Name in English <br />公司英文名称
          </td>
          <td class="text-center" colspan="2">
            {{ CompanyNameinEnglish }}
          </td>
          <td class="text-center">Company CR NO <br />营业执照号</td>
          <td class="text-center">
            {{ CompanyCRNO }}
          </td>
          <td class="text-center">Invite Project Name <br />邀请项目名称</td>
          <td class="text-center">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            Invite SubProject Name<br />邀请分项目名称
          </td>
          <td class="text-center">
            {{ SubProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">Invite Working Unit<br />邀请用工单位</td>
          <td class="text-center">
            {{ CompanyID | companyFormater(companyList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Business scope<br />营业范围（英文）</td>
          <td class="text-center" colspan="5">
            {{ BusinessScope }}
          </td>
          <td class="text-center">
            Invitation Puporse <br />
            来访原因
          </td>
          <td class="text-center" colspan="4">
            {{ InvitationPuporse }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Visit Visa Requirement<br />邀请函所涉签证需求
          </td>
          <td class="text-center" colspan="2">
            Place of Visa Application<br />签证办理地点
          </td>
          <td class="text-center" colspan="2">
            {{ PlaceofVisaApplication }}
          </td>
          <td class="text-center" colspan="2">
            Valid Duration<br />签证有效期
          </td>
          <td class="text-center" colspan="2">
            {{ ValidDuration === 1 ? "30天" : "90天" }}
          </td>
          <td class="text-center">Single/Multiple Entry<br />入境次数</td>
          <td class="text-center" colspan="2">
            {{ SingleMultipleEntry === 1 ? "单次" : "多次" }}
          </td>
        </tr>
        <tr>
          <td colspan="14" class="text-center">
            Information of Employee Relatives 员工家属基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center">
            S/N<br />
            序号
          </td>
          <td class="text-center">
            Employee ID<br />
            员工编码
          </td>
          <td class="text-center">Name in Chinese<br />员工姓名</td>
          <td class="text-center">
            Name in English<br />
            拼音姓名
          </td>
          <td class="text-center">
            Gender<br />
            性别
          </td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            Date of Birth<br />
            出生日期
          </td>
          <td class="text-center">
            Age<br />
            年龄
          </td>
          <td class="text-center">
            Religion<br />
            宗教信仰
          </td>
          <td class="text-center" colspan="2">Job Profession<br />岗位名称</td>
          <td class="text-center">
            Passport No<br />
            护照号
          </td>
          <td class="text-center">
            PP Expiry Date<br />
            护照有效期
          </td>
          <td class="text-center">
            Employment Type <br />
            用工形式
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{ item.EmployeeID }}
          </td>
          <td class="text-center">
            {{ item.NameinChinese }}
          </td>
          <td class="text-center">{{ item.NameinEnglish }}</td>
          <td class="text-center">
            <span v-if="item.Gender">{{
              item.Gender === 1 ? "男" : "女"
            }}</span>
          </td>

          <td class="text-center">
            {{ item.Nationality | nationalityFormater(nationalityList) }}
          </td>

          <td class="text-center">{{ item.DateofBirth | tableDateFrom }}</td>
          <td class="text-center">{{ item.Age }}</td>

          <td class="text-center">
            {{ item.Religion | rligionFormater(religionsList) }}
          </td>
          <td class="text-center" colspan="2">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">{{ item.PassportCode }}</td>
          <td class="text-center">{{ item.PPValidDate | tableDateFrom }}</td>
          <td class="text-center">
            {{ item.EmploymentType | employmentTypeFormater }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Note<br />
            填表说明
          </td>
          <td class="text-center" colspan="12">
            * 申请单位需落实受邀人不得有尚未处理完毕的违法或治安处罚。<br />
            * The applicant unit needs to confirm the invitee there must be no
            unsolved illegal cases.
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Approval Record<br />
            审批记录
          </td>

          <td class="text-center" colspan="3">
            Approved by Department 单位/部门
          </td>
          <td class="text-center" colspan="3">
            Approved by Proejct Authorized 项目部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC HR Department 中东公司人力资源部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC Authorized 中东公司授权人
          </td>
        </tr>
        <tr>
          <td class="text-title" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyProejctAuthorized" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCHRDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import { getProjectAll, getCompanyAll, allPosition } from "@/api/user";
import {
  getUsersCode,
  getGeneralBasic,
  addInvitationLetterApplication,
  updateInvitationLetterApplication,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    rligionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.ReligionCHS : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      positionList: [],
      nationalityList: [],
      CostAllocationDetails: [],
      religionsList: [],
      ID: null,
      KeyID: null,
      CompanyNameinEnglish: null,
      CompanyCRNO: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      BusinessScope: null,
      InvitationPuporse: null,
      PlaceofVisaApplication: null,
      ValidDuration: null,
      SingleMultipleEntry: null,
      EditState: null,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        NameinEnglish: null,
        Gender: null,
        Nationality: null,
        DateofBirth: null,
        Age: null,
        Religion: null,
        JobProfession: null,
        PassportCode: null,
        PPValidDate: null,
        EmploymentType: null,
      },
      ApprovedbyDept: null,
      ApprovedbyProejctAuthorized: null,
      ApprovedbySNEMCHRDept: null,
      ApprovedbySNEMCAuthorized: null,

      validDurationOption: [
        {
          value: 1,
          label: "30天",
        },
        {
          value: 2,
          label: "90天",
        },
      ],
      singleMultipleEntryOption: [
        {
          value: 1,
          label: "单次",
        },
        {
          value: 2,
          label: "多次",
        },
      ],
      option3: [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ],
    };
  },

  methods: {
    getNew(data1, data2) {
      this.projectList = data1;
      this.companyList = data2;
      this.TableDate = getNewDate();

      //获取各个信息
      console.log(JSON.parse(localStorage.getItem("userInfo")));

      if (localStorage.getItem("userInfo")) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.Employee) {
          this.ProjectID = userInfo.Employee.ProjectID;
          this.SubProjectID = userInfo.Employee.SubProjectID;
          this.CompanyID = userInfo.Employee.CompanyID;
        }
      }
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        CompanyNameinEnglish: this.CompanyNameinEnglish,
        CompanyCRNO: this.CompanyCRNO,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        BusinessScope: this.BusinessScope,
        InvitationPuporse: this.InvitationPuporse,
        PlaceofVisaApplication: this.PlaceofVisaApplication,
        ValidDuration: this.ValidDuration,
        SingleMultipleEntry: this.SingleMultipleEntry,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.EmployeeID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (!this.ID) {
        addInvitationLetterApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        updateInvitationLetterApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      //   this.projectList = data1;
      //   this.companyList = data2;

      console.log("params", params);
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;

      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.CompanyNameinEnglish = params.CompanyNameinEnglish;
      this.CompanyCRNO = params.CompanyCRNO;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.BusinessScope = params.BusinessScope;
      this.InvitationPuporse = params.InvitationPuporse;
      this.PlaceofVisaApplication = params.PlaceofVisaApplication;
      this.ValidDuration = params.ValidDuration;
      this.SingleMultipleEntry = params.SingleMultipleEntry;
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].EmployeeID = params.Details[index].EmployeeCode;

          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      this.ApprovedbyDept = params.ApprovedbyDept;
      this.ApprovedbyProejctAuthorized = params.ApprovedbyProejctAuthorized;
      this.ApprovedbySNEMCHRDept = params.ApprovedbySNEMCHRDept;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
    },
    clearData() {
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.ID = null;
      this.KeyID = null;
      this.CompanyNameinEnglish = null;
      this.CompanyCRNO = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.BusinessScope = null;
      this.InvitationPuporse = null;
      this.PlaceofVisaApplication = null;
      this.ValidDuration = null;
      this.SingleMultipleEntry = null;
    },
    //通过录入护照号拿个人信息
    async changeEmployeeID(index) {
      const value = this.CostAllocationDetails[index].EmployeeID;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 0 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (!userInfo) {
        return;
      }
      let item = this.CostAllocationDetails[index];
      item.IsEmploymentOption = false;
      item.NameinEnglish = userInfo.EnglishName;
      item.Gender = userInfo.Gender;
      item.Nationality = userInfo.CountryID;
      item.DateofBirth = userInfo.DateofBirth;
      item.Age = this.calculateAge(item.DateofBirth);
      item.Religion = userInfo.Religion;
      item.JobProfession = userInfo.PositionID;
      item.PassportCode = userInfo.Passport.PPCode;
      item.PPValidDate = userInfo.Passport.PPValidDate;
      item.EmploymentType = userInfo.EmploymentType;
      if (!userInfo.EmploymentType) {
        item.EmploymentType = null;
        item.IsEmploymentOption = true;
      }
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("userInfo", userInfo);
    },
    //计算年龄
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday); // 将生日字符串转换为Date对象
      let age = today.getFullYear() - birthDate.getFullYear(); // 计算年份差
      // 检查是否已经过了生日
      const todayMonth = today.getMonth();
      const birthMonth = birthDate.getMonth();
      const todayDay = today.getDate();
      const birthDay = birthDate.getDate();
      if (
        todayMonth < birthMonth ||
        (todayMonth === birthMonth && todayDay < birthDay)
      ) {
        age--; // 如果还没过生日，年龄减一
      }
      return age;
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍和宗教信仰
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.religionsList = res.response.Religions;
        console.log("国籍", res.response);
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>