<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" rowspan="2" colspan="2">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" rowspan="2" colspan="10">
            <h1>
              Foreign Affairs Application of Sinopec Nanjing Engineering Middle
              East Co., Ltd <br />
              中东公司外事业务审批表
            </h1>
          </td>
          <td class="text-right" colspan="2">Application Date<br />申请日期</td>
          <td class="text-center">
            {{ TableDate | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-right" colspan="2">
            Workflow Table S/N<br />表单编号
          </td>
          <td class="text-center">
            <p>{{ ApplicationNumber }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-center">SN</td>
          <td class="text-center">
            Foreign Affairs Category<br />
            外事业务类型
          </td>
          <td class="text-center">
            Employee ID <br />
            员工编码
          </td>
          <td class="text-center">Name in English<br />拼音姓名</td>

          <td class="text-center">
            Project Name<br />
            项目名称
          </td>
          <td class="text-center">
            SubProject Name<br />
            分项目名称
          </td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center">
            Passport No<br />
            护照号
          </td>
          <td class="text-center">
            IQAMA/ID No. / Border Number<br />
            暂住证号/海关入境号
          </td>

          <td class="text-center">
            IQAMA Valid Date<br />
            暂住证有效期
          </td>
          <td class="text-center">
            Druation<br />
            业务需求时长
          </td>
          <td class="text-center">
            Valid Date<br />
            业务有效期
          </td>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">
            急件<br />
            Urgent
          </td>
          <td class="text-center">
            Remark<br />
            备注
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{
              item.ForeignAffairsCategory
                | labelFormater(foreignAffairsCategoryList)
            }}
          </td>
          <td class="text-center">
            {{ item.EmployeeID }}
          </td>
          <td class="text-center">
            {{ item.NameinEnglish }}
          </td>
          <td class="text-center">
            {{ item.ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            {{ item.SubProjectID | projectFormater(item.subProjectList) }}
          </td>
          <td class="text-center">
            {{ item.CompanyID | companyFormater(companyList) }}
          </td>
          <td class="text-center">
            {{ item.PassportCode }}
          </td>
          <td class="text-center">
            {{ item.IQAMAID }}
          </td>
          <td class="text-center">
            {{ item.IQAMAValidDate | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.Druation | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.ValidDate | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.SPONSOR | labelFormater(opinions1) }}
          </td>
          <td class="text-center">
            {{ item.Urgent | labelFormater(opinions2) }}
          </td>
          <td class="text-center">
            {{ item.Remark }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">Note <br />填表说明</td>
          <td class="text-left" colspan="13">
            <p>
              * Please note new job profession in "Remark” cell for Change IQAMA
              Profession. <br />* IQAMA更改职位变更需在备注区域注明新职位名称。
            </p>
            <p>
              * Please note irregular requirements in "Remark” , such as IQAMA
              valid (3/6/9/12 month), exit re-entry visa type (Single/Multiple)
              , visit visa (Single/Multiple Entry).
              <br />
              *外事业务有特殊要求需在备注栏注明，如IQAMA办理时长（3/6/9/12
              month），往返签证办理类型（单词/多次往返），访问签证办理（单词/多次入境）
            </p>
            <p>
              * Please note Business Trip Application for Non-Working Country
              S/N in "Remark” cell for a “Issue Visit Visa of Non-working
              Country” and list "Exit Re-entry Visa" in same Foreign Affairs
              Application form, and submit 4 photo hard copies same as passport
              format.
              <br />* 办理第三国签证业务需
              在备注栏注明出国（境）任务审批表编号，并在同一任务单中列出离往返签证办理业务和员工护照格式招聘硬拷贝4张。
            </p>
            <p>
              * Please note “Flight Ticket Application for Demobilized Employee”
              S/N in "Remark” cell for “Cancel IQAMA or Issue Exit Visa”.
              <br />*
              离境签证、注销IQAMA业务需在备注栏注明“人员撤离动迁审批表”编号，另注销IQAMA业务需提交IQAMA原件。
            </p>
            <p>
              * Please note new passport NO in "Remark” cell for update
              passport. <br />* 护照信息更新需在备注栏注明新护照号。
            </p>
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2" colspan="2">
            Approval Record<br />
            审批记录
          </td>

          <td class="text-center" colspan="3">
            Approved by Department <br />
            单位/部门
          </td>
          <td class="text-center" colspan="3">
            Approved by Proejct Authorized <br />
            项目部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC HR Department <br />
            中东公司人力资源部
          </td>
          <td class="text-center" colspan="4">
            Approved by SNEMC Authorized <br />
            中东公司授权人
          </td>
        </tr>
        <tr>
          <td class="text-title" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyDepartment" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyProjectAuthorized" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCHRDepartment" />
            </div>
          </td>
          <td class="text-center" colspan="4">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import { foreignAffairsCategoryList } from "@/utils/optionsData";
import {
  getProjectAll,
  getCompanyAll,
  uploadAttachment,
  getProjectsChildren,
} from "@/api/user";
import {
  getGeneralBasic,
  getUsersCode,
  addTransferSponsorApplication,
  editTransferSponsorApplications,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      TableDate: null,
      ID: null,
      KeyID: null,
      ApplicationDate: null,
      ApplicationNumber: null,
      Remark: null,
      ForeignAffairsCategory: null,
      ForeignAffairsCategoryName: null,
      ApprovedbyDepartment: null,
      ApprovedbyDepartmentRemark: null,
      ApprovedbyDepartmentDate: null,
      ApprovedbyProjectAuthorized: null,
      ApprovedbyProjectAuthorizedRemark: null,
      ApprovedbyProjectAuthorizedDate: null,
      ApprovedbySNEMCHRDepartment: null,
      ApprovedbySNEMCHRDepartmentRemark: null,
      ApprovedbySNEMCHRDepartmentDate: null,
      ApprovedbySNEMCAuthorized: null,
      ApprovedbySNEMCAuthorizedRemark: null,
      ApprovedbySNEMCAuthorizedDate: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      UploadingDocument6: null,
      UploadingDocument7: null,
      UploadingDocument8: null,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        ForeignAffairsCategory: null,
        ForeignAffairsCategoryName: null,
        TaskNumber: null,
        EmployeeID: null,
        EmployeeCode: null,
        Nameinchinese: null,
        NameinEnglish: null,
        ProjectID: null,
        ProjectName: null,
        SubProjectID: null,
        SubProjectName: null,
        CompanyID: null,
        WorkingUnit: null,
        DepartmentID: null,
        WorkingDepartment: null,
        PassportCode: null,
        IQAMAID: null,
        IQAMAValidDate: null,
        BorderNumber: null,
        Druation: null,
        CurrentPositionID: null,
        CurrentPositionName: null,
        NewPositionID: null,
        NewPositionName: null,
        NewPassportCode: null,
        ValidDate: null,
        Urgent: null,
        SPONSOR: null,
        Remark: null,
        subProjectList: [],
      },
      opinions1: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      opinions2: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      foreignAffairsCategoryList: foreignAffairsCategoryList,
      projectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      nationalityList: [],
    };
  },
  methods: {
    getNew(value) {
      this.TableDate = getNewDate();
      this.ForeignAffairsCategory = value;
      this.costAllocationItem.ForeignAffairsCategory = value;
      this.CostAllocationDetails = this.CostAllocationDetails.map((item) => {
        return { ...item, ForeignAffairsCategory: value };
      });
      // console.log('1111',this.CostAllocationDetails)
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ApplicationDate: this.ApplicationDate,
        ApplicationNumber: this.ApplicationNumber,
        Remark: this.Remark,
        ForeignAffairsCategory: this.ForeignAffairsCategory,
        ForeignAffairsCategoryName: this.ForeignAffairsCategoryName,
        ApprovedbyDepartment: this.ApprovedbyDepartment,
        ApprovedbyDepartmentRemark: this.ApprovedbyDepartmentRemark,
        ApprovedbyDepartmentDate: this.ApprovedbyDepartmentDate,
        ApprovedbyProjectAuthorized: this.ApprovedbyProjectAuthorized,
        ApprovedbyProjectAuthorizedRemark:
          this.ApprovedbyProjectAuthorizedRemark,
        ApprovedbyProjectAuthorizedDate: this.ApprovedbyProjectAuthorizedDate,
        ApprovedbySNEMCHRDepartment: this.ApprovedbySNEMCHRDepartment,
        ApprovedbySNEMCHRDepartmentRemark:
          this.ApprovedbySNEMCHRDepartmentRemark,
        ApprovedbySNEMCHRDepartmentDate: this.ApprovedbySNEMCHRDepartmentDate,
        ApprovedbySNEMCAuthorized: this.ApprovedbySNEMCAuthorized,
        ApprovedbySNEMCAuthorizedRemark: this.ApprovedbySNEMCAuthorizedRemark,
        ApprovedbySNEMCAuthorizedDate: this.ApprovedbySNEMCAuthorizedDate,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        UploadingDocument6: this.UploadingDocument6,
        UploadingDocument7: this.UploadingDocument7,
        UploadingDocument8: this.UploadingDocument8,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.ForeignAffairsCategory && item.EmployeeID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (!this.ID) {
        addTransferSponsorApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editTransferSponsorApplications(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.Remark = params.Remark;
      this.ForeignAffairsCategory = params.ForeignAffairsCategory;
      this.ForeignAffairsCategoryName = params.ForeignAffairsCategoryName;
      this.ApprovedbyDepartment = params.ApprovedbyDepartment;
      this.ApprovedbyDepartmentRemark = params.ApprovedbyDepartmentRemark;
      this.ApprovedbyDepartmentDate = params.ApprovedbyDepartmentDate;
      this.ApprovedbyProjectAuthorized = params.ApprovedbyProjectAuthorized;
      this.ApprovedbyProjectAuthorizedRemark =
        params.ApprovedbyProjectAuthorizedRemark;
      this.ApprovedbyProjectAuthorizedDate =
        params.ApprovedbyProjectAuthorizedDate;
      this.ApprovedbySNEMCHRDepartment = params.ApprovedbySNEMCHRDepartment;
      this.ApprovedbySNEMCHRDepartmentRemark =
        params.ApprovedbySNEMCHRDepartmentRemark;
      this.ApprovedbySNEMCHRDepartmentDate =
        params.ApprovedbySNEMCHRDepartmentDate;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
      this.ApprovedbySNEMCAuthorizedRemark =
        params.ApprovedbySNEMCAuthorizedRemark;
      this.ApprovedbySNEMCAuthorizedDate = params.ApprovedbySNEMCAuthorizedDate;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.UploadingDocument6 = params.UploadingDocument6;
      this.UploadingDocument7 = params.UploadingDocument7;
      this.UploadingDocument8 = params.UploadingDocument8;
      params.Details.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].EmployeeID = params.Details[index].EmployeeCode;
          params.Details[index].subProjectList = [];
          if (index <= 9) {
            this.CostAllocationDetails[index] = JSON.parse(
              JSON.stringify(params.Details[index])
            );
          } else {
            this.CostAllocationDetails.push(
              JSON.parse(JSON.stringify(params.Details[index]))
            );
          }
        }
      });
      this.CostAllocationDetails.forEach((item, index, Array) => {
        if (item.ProjectID) {
          this.getProjectsChildren(item.ProjectID, index);
        }
        // Array[index].ForeignAffairsCategory = this.ForeignAffairsCategory;
      });
      this.costAllocationItem.ForeignAffairsCategory =
        this.ForeignAffairsCategory;
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ApplicationDate = null;
      this.ApplicationNumber = null;
      this.Remark = null;
      this.ForeignAffairsCategory = null;
      this.ForeignAffairsCategoryName = null;
      this.ApprovedbyDepartment = null;
      this.ApprovedbyDepartmentRemark = null;
      this.ApprovedbyDepartmentDate = null;
      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;
      this.ApprovedbySNEMCHRDepartment = null;
      this.ApprovedbySNEMCHRDepartmentRemark = null;
      this.ApprovedbySNEMCHRDepartmentDate = null;
      this.ApprovedbySNEMCAuthorized = null;
      this.ApprovedbySNEMCAuthorizedRemark = null;
      this.ApprovedbySNEMCAuthorizedDate = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.UploadingDocument6 = null;
      this.UploadingDocument7 = null;
      this.UploadingDocument8 = null;

      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    //通过员工编码读取数据
    async changeEmployeeID(index) {
      const value = this.CostAllocationDetails[index].EmployeeID;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 2 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (userInfo) {
        console.log("userInfo", userInfo);
        this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
        this.CostAllocationDetails[index].ProjectID = userInfo.ProjectID;
        this.CostAllocationDetails[index].SubProjectID = userInfo.SubProjectID;
        this.CostAllocationDetails[index].CompanyID = userInfo.CompanyID;
        this.CostAllocationDetails[index].PassportCode =
          userInfo.Passport.PPCode;
        this.CostAllocationDetails[index].IQAMAID = userInfo.IQAMAID;
        this.CostAllocationDetails[index].SPONSOR = userInfo.Sponsor;
        this.CostAllocationDetails[index].BorderNumber = userInfo.BorderNumber;
        this.getProjectsChildren(userInfo.ProjectID, index);
      }

      if (index === this.CostAllocationDetails.length - 1) {
        this.CostAllocationDetails.push(
          JSON.parse(JSON.stringify(this.costAllocationItem))
        );
      }
    },

    //通过选中项目获取分项目
    changeProjectID(index) {
      const ProjectID = this.CostAllocationDetails[index].ProjectID;
      this.CostAllocationDetails[index].subProjectList = [];
      this.CostAllocationDetails[index].SubProjectID = null;
      if (ProjectID || ProjectID === 0) {
        this.getProjectsChildren(ProjectID, index);
      }
    },

    getProjectsChildren(id, index) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.CostAllocationDetails[index].subProjectList = res.response;
        }
      });
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
        case 6:
          this.UploadingDocument6 = null;
          break;
        case 7:
          this.UploadingDocument7 = null;
          break;
        case 8:
          this.UploadingDocument8 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment6(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument6 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment7(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument7 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment8(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument8 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      location.href = data;
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .tbale-img1 {
    max-width: 120px;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 50px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
      p {
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}

.el-checkbox
  >>> .el-checkbox__input.is-disabled.is-checked
  .el-checkbox__inner::after {
  border-color: #000 !important;
}
</style>