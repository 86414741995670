<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" colspan="2">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" colspan="9">
            <h1 v-if="TypeID === 1">
              The Work Visa Purchase Application of Sinopec Nanjing Engineering
              Middle East Co., Ltd <br />
              中石化南京工程中东公司工作签证购买审批表
            </h1>

            <h1 v-if="TypeID === 2">
              The Work Visa Authorize Application of Sinopec Nanjing Engineering
              Middle East Co., Ltd
              <br />中石化南京工程中东公司工作签证授权审批表
            </h1>
            <h1 v-if="TypeID === 3">
              The Work Visa Cancel Application of Sinopec Nanjing Engineering
              Middle East Co., Ltd <br />
              中石化南京工程中东公司工作签证取消审批表
            </h1>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="9">
            <div class="select-box">
              <div class="select-name">申请单位:</div>
              {{ CompanyID | companyFormater(companyList) }}
            </div>
          </td>
          <td class="text-center" colspan="2">
            申请时间: {{ ApplicationDate }}
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">序号</td>
          <td class="text-center" rowspan="2">使用时间</td>
          <td class="text-center" rowspan="2">国籍</td>
          <td class="text-center" rowspan="2">岗位或工种</td>
          <td class="text-center" colspan="3">需求数量</td>
          <td class="text-center" rowspan="2">拟工作地点</td>
          <td class="text-center" rowspan="2">计划来沙时间</td>
          <td class="text-center" rowspan="2">计划在沙工作期限</td>
          <td class="text-center" rowspan="2">备注</td>
        </tr>
        <tr>
          <td class="text-center">中石化员工</td>
          <td class="text-center">非中石化员工</td>
          <td class="text-center">小计</td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{ item.UsageTime | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.Nationality | nationalityFormater(nationalityList) }}
          </td>
          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">
            {{ item.AmouttoSINOPEC }}
          </td>
          <td class="text-center">
            {{ item.AmouttoNonSINOPEC }}
          </td>
          <td class="text-center">{{ item.Amount }}</td>
          <td class="text-center">
            {{ item.WorkingPlace }}
          </td>
          <td class="text-center">
            {{ item.AarrivalDateatKSA | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.WorkingDuration }}
          </td>
          <td class="text-center">
            {{ item.Remark }}
          </td>
        </tr>

        <tr>
          <td class="text-center" colspan="4">合计</td>
          <td class="text-center">{{ QtyAll1 }}</td>
          <td class="text-center">{{ QtyAll2 }}</td>
          <td class="text-center">{{ QtyAll3 }}</td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">申请单位</td>
          <td class="text-center" colspan="4"></td>
        </tr>

        <tr>
          <td class="text-center" colspan="7">
            <div class="img-name-box">
              <div class="name">经办人：</div>
              <img :src="ApprovedbyManager" />
            </div>
          </td>
          <td class="text-center" colspan="4">
            <div class="img-name-box">
              <div class="name">人力资源部意见:</div>
              <img :src="ApprovedbyAuthorized" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">
            <div class="img-name-box">
              <div class="name">领导审批：</div>
              <img :src="ApprovedbySNEMCHRDepartment" />
            </div>
          </td>
          <td class="text-center" colspan="4">
            <div class="img-name-box">
              <div class="name">领导审批：</div>
              <img :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  allPosition,
} from "@/api/user";
import {
  getGeneralBasic,
  getEmployeeMedicalInsuranceClassLevelsAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  computed: {
    // 计算属性的getter
    QtyAll1() {
      let number = 0;
      this.CostAllocationDetails.forEach((item) => {
        if (item.AmouttoSINOPEC) {
          number = number + item.AmouttoSINOPEC;
        }
      });
      return number;
    },
    // 计算属性的getter
    QtyAll2() {
      let number = 0;
      this.CostAllocationDetails.forEach((item) => {
        if (item.AmouttoNonSINOPEC) {
          number = number + parseFloat(item.AmouttoNonSINOPEC);
        }
      });
      return number;
    },
    QtyAll3() {
      let number = 0;
      this.CostAllocationDetails.forEach((item) => {
        if (item.Amount) {
          number = number + parseFloat(item.Amount);
        }
      });
      return number;
    },
  },
  data() {
    return {
      ApplicationDate: null,
      ID: null,
      KeyID: null,
      ApplicationNumber: null,
      Date: null,
      Remark: null,
      SPONSOR: null,
      CompanyID: null,
      ApprovedbyManager: null,
      ApprovedbyManagerRemark: null,
      ApprovedbyManagerDate: null,
      ApprovedbyAuthorized: null,
      ApprovedbyAuthorizedRemark: null,
      ApprovedbyAuthorizedDate: null,
      ApprovedbySNEMCHRDepartment: null,
      ApprovedbySNEMCHRDepartmentRemark: null,
      ApprovedbySNEMCHRDepartmentDate: null,
      ApprovedbySNEMCAuthorized: null,
      ApprovedbySNEMCAuthorizedRemark: null,
      ApprovedbySNEMCAuthorizedDate: null,
      TypeID: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        UsageTime: null,
        Nationality: null,
        JobProfession: null,
        AmouttoSINOPEC: null,
        AmouttoNonSINOPEC: null,
        Amount: null,
        WorkingPlace: null,
        AarrivalDateatKSA: null,
        WorkingDuration: null,
        Remark: null,
      },
      opinions1: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      opinions2: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      opinions3: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
        {
          value: 3,
          label: "未知",
        },
      ],
      projectList: [],
      subPojectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      nationalityList: [],
    };
  },
  methods: {
    getNew(data) {
      this.TypeID = data;
      this.ApplicationDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ApplicationNumber: this.ApplicationNumber,
        Date: this.Date,
        Remark: this.Remark,
        SPONSOR: this.SPONSOR,
        CompanyID: this.CompanyID,
        ApprovedbyManager: this.ApprovedbyManager,
        ApprovedbyManagerRemark: this.ApprovedbyManagerRemark,
        ApprovedbyManagerDate: this.ApprovedbyManagerDate,
        ApprovedbyAuthorized: this.ApprovedbyAuthorized,
        ApprovedbyAuthorizedRemark: this.IApprovedbyAuthorizedRemark,
        ApprovedbyAuthorizedDate: this.ApprovedbyAuthorizedDate,
        ApprovedbySNEMCHRDepartment: this.ApprovedbySNEMCHRDepartment,
        ApprovedbySNEMCHRDepartmentRemark:
          this.ApprovedbySNEMCHRDepartmentRemark,
        ApprovedbySNEMCHRDepartmentDate: this.ApprovedbySNEMCHRDepartmentDate,
        ApprovedbySNEMCAuthorized: this.ApprovedbySNEMCAuthorized,
        ApprovedbySNEMCAuthorizedRemark: this.ApprovedbySNEMCAuthorizedRemark,
        ApprovedbySNEMCAuthorizedDate: this.ApprovedbySNEMCAuthorizedDate,
        TypeID: this.TypeID,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.UsageTime && item.Nationality) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      return data;
    },
    geteditData(params) {
      console.log("params", params);
      this.ApplicationDate = params.CreateDate;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ApplicationNumber = params.ApplicationNumber;
      this.Date = params.Date;
      this.Remark = params.Remark;
      this.SPONSOR = params.SPONSOR;
      this.CompanyID = params.CompanyID;
      this.ApprovedbyManager = params.ApprovedbyManager;
      this.ApprovedbyManagerRemark = params.ApprovedbyManagerRemark;
      this.ApprovedbyManagerDate = params.ApprovedbyManagerDate;
      this.ApprovedbyAuthorized = params.ApprovedbyAuthorized;
      this.ApprovedbyAuthorizedRemark = params.ApprovedbyAuthorizedRemark;
      this.ApprovedbyAuthorizedDate = params.ApprovedbyAuthorizedDate;
      this.ApprovedbySNEMCHRDepartment = params.ApprovedbySNEMCHRDepartment;
      this.ApprovedbySNEMCHRDepartmentRemark =
        params.ApprovedbySNEMCHRDepartmentRemark;
      this.ApprovedbySNEMCHRDepartmentDate =
        params.ApprovedbySNEMCHRDepartmentDate;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
      this.ApprovedbySNEMCAuthorizedRemark =
        params.ApprovedbySNEMCAuthorizedRemark;
      this.ApprovedbySNEMCAuthorizedDate = params.ApprovedbySNEMCAuthorizedDate;
      this.TypeID = params.TypeID;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      params.Details.forEach((_, index, Array) => {
        if (params.Details[index]) {
          if (index <= 8) {
            this.CostAllocationDetails[index] = JSON.parse(
              JSON.stringify(params.Details[index])
            );
          } else {
            this.CostAllocationDetails.push(
              JSON.parse(JSON.stringify(params.Details[index]))
            );
          }
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.ApplicationDate = null;
      this.ID = null;
      this.KeyID = null;
      this.ApplicationNumber = null;
      this.Date = null;
      this.Remark = null;
      this.SPONSOR = null;
      this.CompanyID = null;
      this.ApprovedbyManager = null;
      this.ApprovedbyManagerRemark = null;
      this.ApprovedbyManagerDate = null;
      this.ApprovedbyAuthorized = null;
      this.ApprovedbyAuthorizedRemark = null;
      this.ApprovedbyAuthorizedDate = null;
      this.ApprovedbySNEMCHRDepartment = null;
      this.ApprovedbySNEMCHRDepartmentRemark = null;
      this.ApprovedbySNEMCHRDepartmentDate = null;
      this.ApprovedbySNEMCAuthorized = null;
      this.ApprovedbySNEMCAuthorizedRemark = null;
      this.ApprovedbySNEMCAuthorizedDate = null;
      this.TypeID = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 8; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    changeAdd(index) {
      if (index === this.CostAllocationDetails.length - 1) {
        this.CostAllocationDetails.push(
          JSON.parse(JSON.stringify(this.costAllocationItem))
        );
      }
    },
    changeNumber(index) {
      const item = this.CostAllocationDetails[index];
      this.CostAllocationDetails[index].Amount =
        item.AmouttoSINOPEC + item.AmouttoNonSINOPEC;
    },
  },
  created() {
    for (let i = 0; i < 8; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.Marriages = res.response.Marriages;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getEmployeeMedicalInsuranceClassLevelsAll().then((res) => {
      if (res.status === 200) {
        this.classList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .tbale-img1 {
    max-width: 120px;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .select-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .select-name {
      flex-shrink: 0;
      margin-right: 10px;
    }
    .el-select {
      max-width: 500px;
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .div-box-content {
    width: 100%;
    display: flex;
    .div-box-content-left {
      width: 40%;
      padding: 10px 0;
      margin-right: 5%;
      flex-shrink: 0;
      line-height: 20px;
      p {
        margin-top: 20px;
      }
    }
    .div-box-content-right {
      width: 50%;
      .content {
        text-align: center;
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 50px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
      p {
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
.img-name-box {
  width: 100%;
  display: flex;
  padding: 15px 5px;
  align-items: center;
  .name {
    font-size: 18px;
    color: #000;
    font-weight: bolder;
    margin-right: 20px;
    flex-shrink: 0;
    width: 150px;
    text-align: left;
  }
  img {
    max-width: 200px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}

.el-checkbox
  >>> .el-checkbox__input.is-disabled.is-checked
  .el-checkbox__inner::after {
  border-color: #000 !important;
}
</style>