<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td rowspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="4">
            Registration Application for Manpower Supplier <br />
            当地岗位服务外包单位引入审批表
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Manpower Supplier <br />
            岗位外包单位名称
          </td>
          <td class="text-center" colspan="2">
            {{ ManpowerSupplier }}
          </td>
          <td class="text-center">
            Office Address <br />
            岗位外包单位办公地址
          </td>
          <td class="text-center" colspan="2">
            {{ OfficeAddress }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Company Registration NO.<br />
            营业执照号
          </td>
          <td class="text-center" colspan="2">
            {{ CRNumber }}
          </td>
          <td class="text-center">
            Valid Date of Company Registration<br />
            营业执照有效期
          </td>
          <td class="text-center" colspan="2">
            {{ ValidDateOfCR | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Main Category of Manpower<br />
            外派人员主要类别
          </td>
          <td class="text-center" colspan="2">
            {{ MainCategory }}
          </td>
          <td class="text-center">
            Quantity of Sponsored Employee <br />
            自有员工数量
          </td>
          <td class="text-center" colspan="2">
            {{ QuantityOfManpower }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Recommended By<br />
            引荐项目
          </td>
          <td class="text-center" colspan="2">
            {{ RecommendedBy }}
          </td>
          <td class="text-center">
            First Party<br />
            计划签约主体
          </td>
          <td class="text-center" colspan="2">
            {{ FirstParty | governmentListFrom(governmentList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">
            <div class="name">推荐原因Recommended Reason</div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">
            {{ RecommendedReason }}
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="5">
            The manpower requirement is verified in the list or not.<br />
            相关人力需求是否已在短名单中核实。
          </td>
          <td class="text-left" colspan="2">
            <div class="flex">
              <div>
                <el-checkbox
                  @change="changeRequirementVerified(1)"
                  v-model="RequirementVerified1"
                ></el-checkbox>
                <span>是Yes</span>
              </div>
              <div>
                <el-checkbox
                  @change="changeRequirementVerified(2)"
                  v-model="RequirementVerified2"
                ></el-checkbox>
                <span>否Not</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">Verification Record 核实记录</td>
        </tr>
        <tr>
          <td class="text-center">
            S/N<br />
            序号
          </td>
          <td class="text-center" colspan="2">
            Name of Manpower supplier<br />
            岗位服务外包单位名称
          </td>
          <td class="text-center" colspan="2">
            Date of Verification<br />
            核实日期
          </td>
          <td class="text-center" colspan="2">
            Result of verification<br />
            核实结果
          </td>
        </tr>
        <tr v-for="(item, index) in Details" :key="index">
          <td class="text-center">
            {{ item.ManpowerSupplierKeyID }}
          </td>
          <td class="text-center" colspan="2">
            {{ item.NameOfManpowerSupplier }}
          </td>
          <td class="text-center" colspan="2">
            {{ item.DateOfVerification | tableDateFrom }}
          </td>
          <td class="text-center" colspan="2">
            {{ item.ResultOfverification }}
          </td>
        </tr>

        <tr>
          <td class="text-center text-size" colspan="3">
            Confirmed by Recommended Dept.<br />
            推荐单位确认
          </td>
          <td class="text-left text-size" colspan="4">
            <div class="textarea-box">
              {{ ConfirmedbyRecommendedDeptRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ConfirmedbyRecommendedDept"
                  :src="ConfirmedbyRecommendedDept"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ConfirmedbyRecommendedDeptDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Manager<br />
            单位/部门领导审批
          </td>
          <td class="text-left text-size" colspan="4">
            <div class="textarea-box">
              {{ ApprovedbyManagerRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyManager"
                  :src="ApprovedbyManager"
                />
              </div>
              <div>
                <span>日期Date：</span><span>{{ ApprovedbyManagerDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Project Authorized<br />
            项目授权人审批
          </td>
          <td class="text-left text-size" colspan="4">
            <div class="textarea-box">
              {{ ApprovedbyProjectAuthorizedRemark }}
            </div>

            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyProjectAuthorized"
                  :src="ApprovedbyProjectAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyProjectAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC HR Dept.<br />
            中东公司人力资源部审批
          </td>
          <td class="text-left text-size" colspan="4">
            <div class="textarea-box">
              {{ ApprovedbySNEMCHRDeptRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCHRDept"
                  :src="ApprovedbySNEMCHRDept"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCHRDeptDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC Compliance Dept.<br />
            中东公司合规管理部审批
          </td>
          <td class="text-left text-size" colspan="4">
            <div class="textarea-box">
              {{ ApprovedbySNEMCComplianceDeptRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCComplianceDept"
                  :src="ApprovedbySNEMCComplianceDept"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCComplianceDeptDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC Authorized<br />
            中东公司授权人审批
          </td>
          <td class="text-left text-size" colspan="4">
            <div class="textarea-box">
              {{ ApprovedbySNEMCAuthorizedRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCAuthorized"
                  :src="ApprovedbySNEMCAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Remark <br />
            备注
          </td>
          <td class="text-center" colspan="4">
            {{ Remark }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { addManpowerSupplier, editManpowerSupplier } from "@/api/user";
import { getGovernmentSystemDicAll } from "@/api/table";
import { getNewDate } from "@/utils/date";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    governmentListFrom(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.NameinChinese : "";
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      ID: null,
      KeyID: null,
      ManpowerSupplier: null,
      OfficeAddress: null,
      CRNumber: null,
      ValidDateOfCR: null,
      MainCategory: null,
      QuantityOfManpower: 0,
      RecommendedReason: null,
      RequirementVerified: false,
      RequirementVerified1: false,
      RequirementVerified2: true,
      RecommendedBy: null,
      FirstParty: null,
      Remark: null,
      EditState: null,
      Details: [
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
      ],
      governmentList: [],
      ConfirmedbyRecommendedDept: null,
      ConfirmedbyRecommendedDeptRemark: null,
      ConfirmedbyRecommendedDeptDate: null,

      ApprovedbyManager: null,
      ApprovedbyManagerRemark: null,
      ApprovedbyManagerDate: null,

      ApprovedbyProjectAuthorized: null,
      ApprovedbyProjectAuthorizedRemark: null,
      ApprovedbyProjectAuthorizedDate: null,

      ApprovedbySNEMCHRDept: null,
      ApprovedbySNEMCHRDeptRemark: null,
      ApprovedbySNEMCHRDeptDate: null,

      ApprovedbySNEMCComplianceDept: null,
      ApprovedbySNEMCComplianceDeptRemark: null,
      ApprovedbySNEMCComplianceDeptDate: null,

      ApprovedbySNEMCAuthorized: null,
      ApprovedbySNEMCAuthorizedRemark: null,
      ApprovedbySNEMCAuthorizedDate: null,
    };
  },
  methods: {
    getNew() {
      this.TableDate = getNewDate();
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ManpowerSupplier = null;
      this.OfficeAddress = null;
      this.CRNumber = null;
      this.ValidDateOfCR = null;
      this.MainCategory = null;
      this.QuantityOfManpower = 0;
      this.RecommendedReason = null;
      this.RequirementVerified = false;
      this.RequirementVerified1 = null;
      this.RequirementVerified2 = true;
      this.TableDate = null;
      this.ApplicationNumber = null;
      this.RecommendedBy = null;
      this.FirstParty = null;
      this.Remark = null;
      this.Details = [
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
        {
          ID: null,
          KeyID: null,
          ManpowerSupplierKeyID: null,
          NameOfManpowerSupplier: null,
          DateOfVerification: null,
          ResultOfverification: null,
        },
      ];
      this.ConfirmedbyRecommendedDept = null;
      this.ConfirmedbyRecommendedDeptRemark = null;
      this.ConfirmedbyRecommendedDeptDate = null;

      this.ApprovedbyManager = null;
      this.ApprovedbyManagerRemark = null;
      this.ApprovedbyManagerDate = null;

      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;

      this.ApprovedbySNEMCHRDept = null;
      this.ApprovedbySNEMCHRDeptRemark = null;
      this.ApprovedbySNEMCHRDeptDate = null;

      this.ApprovedbySNEMCComplianceDept = null;
      this.ApprovedbySNEMCComplianceDeptRemark = null;
      this.ApprovedbySNEMCComplianceDeptDate = null;

      this.ApprovedbySNEMCAuthorized = null;
      this.ApprovedbySNEMCAuthorizedRemark = null;
      this.ApprovedbySNEMCAuthorizedDate = null;
    },
    //是否核实
    changeRequirementVerified(type) {
      if (type === 1) {
        if (this.RequirementVerified1) {
          this.RequirementVerified2 = false;
          this.RequirementVerified = true;
        }
      }
      if (type === 2) {
        if (this.RequirementVerified2) {
          this.RequirementVerified1 = false;
          this.RequirementVerified = false;
        }
      }
    },
    //添加和编辑
    addManpowerSupplier(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ManpowerSupplier: this.ManpowerSupplier,
        OfficeAddress: this.OfficeAddress,
        CRNumber: this.CRNumber,
        ValidDateOfCR: this.ValidDateOfCR,
        MainCategory: this.MainCategory,
        QuantityOfManpower: this.QuantityOfManpower,
        RecommendedReason: this.RecommendedReason,
        RequirementVerified: this.RequirementVerified,
        RecommendedBy: this.RecommendedBy,
        FirstParty: this.FirstParty,
        Remark: this.Remark,
        EditState: EditState,
        Details: [],
      };
      this.Details.forEach((item) => {
        if (item.ManpowerSupplierKeyID) {
          data.Details.push(item);
        }
      });

      if (!data.ID) {
        addManpowerSupplier(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      } else {
        editManpowerSupplier(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      }
    },
    //编辑查询时获取数据
    getManpowerSupplierData(data) {
      this.ID = data.ID;
      this.KeyID = data.KeyID;
      this.ManpowerSupplier = data.ManpowerSupplier;
      this.OfficeAddress = data.OfficeAddress;
      this.CRNumber = data.CRNumber;
      this.ValidDateOfCR = data.ValidDateOfCR;
      this.MainCategory = data.MainCategory;
      this.QuantityOfManpower = data.QuantityOfManpower;
      this.RecommendedReason = data.RecommendedReason;
      this.RequirementVerified = data.RequirementVerified;
      this.RequirementVerified1 = data.RequirementVerified ? true : false;
      this.RequirementVerified2 = data.RequirementVerified ? false : true;
      this.RecommendedBy = data.RecommendedBy;
      this.FirstParty = data.FirstParty;
      this.Remark = data.Remark;
      this.ApplicationNumber = data.ApplicationNumber;
      this.TableDate = data.CreateDate;

      this.ConfirmedbyRecommendedDept = data.ConfirmedbyRecommendedDept;
      this.ConfirmedbyRecommendedDeptRemark =
        data.ConfirmedbyRecommendedDeptRemark;
      this.ConfirmedbyRecommendedDeptDate = data.ConfirmedbyRecommendedDeptDate;

      this.ApprovedbyManager = data.ApprovedbyManager;
      this.ApprovedbyManagerRemark = data.ApprovedbyManagerRemark;
      this.ApprovedbyManagerDate = data.ApprovedbyManagerDate;

      this.ApprovedbyProjectAuthorized = data.ApprovedbyProjectAuthorized;
      this.ApprovedbyProjectAuthorizedRemark =
        data.ApprovedbyProjectAuthorizedRemark;
      this.ApprovedbyProjectAuthorizedDate =
        data.ApprovedbyProjectAuthorizedDate;

      this.ApprovedbySNEMCHRDept = data.ApprovedbySNEMCHRDept;
      this.ApprovedbySNEMCHRDeptRemark = data.ApprovedbySNEMCHRDeptRemark;
      this.ApprovedbySNEMCHRDeptDate = data.ApprovedbySNEMCHRDeptDate;

      this.ApprovedbySNEMCComplianceDept = data.ApprovedbySNEMCComplianceDept;
      this.ApprovedbySNEMCComplianceDeptRemark =
        data.ApprovedbySNEMCComplianceDeptRemark;
      this.ApprovedbySNEMCComplianceDeptDate =
        data.ApprovedbySNEMCComplianceDeptDate;

      this.ApprovedbySNEMCAuthorized = null;
      this.ApprovedbySNEMCAuthorizedRemark = null;
      this.ApprovedbySNEMCAuthorizedDate = null;
      this.Details.forEach((_, index, Array) => {
        if (data.Details && data.Details[index]) {
          Array[index] = data.Details[index];
        }
      });
      this.Details = this.Details.concat();
    },
  },
  created() {
    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        this.governmentList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;
        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
          .sign {
            width: 100%;
            max-width: 100px;
          }
        }
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .nameEn {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .el-checkbox {
        margin-right: 0;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
      .textarea {
        min-width: 65vw;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}.textarea-box {
  min-height: 50px;
  line-height: 20px;
  font-size: 14px;
  color: #606266;
  border: none;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>