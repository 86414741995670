<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" rowspan="2" colspan="3">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" rowspan="2" colspan="12">
            <h1>
              Employee Medical Insurance Registration Application
              <br />中东公司员工医疗保险注册审批表
            </h1>
          </td>
          <td class="text-right">Application Date<br />申请日期</td>
          <td class="text-center">
            {{ ApplicationDate | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N<br />表单编号</td>
          <td class="text-center">
            <p>{{ ApplicationNumber }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-center">SN</td>
          <td class="text-center">
            Employee ID <br />
            员工编码
          </td>
          <td class="text-center">Name in English<br />拼音姓名</td>
          <td class="text-center">
            Gender<br />
            性别
          </td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            Date of Birth<br />
            出生日期
          </td>
          <td class="text-center">
            Phone No. In Working Country<br />
            工作国家电话
          </td>

          <td class="text-center">
            Marital status<br />
            婚姻情况
          </td>

          <td class="text-center">
            Project Name<br />
            项目名称
          </td>
          <td class="text-center">
            Sub Project Name<br />
            分项目名称
          </td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center">
            Job Profession<br />
            岗位名称
          </td>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">
            Customs Border Number<br />
            海关入境号
          </td>
          <td class="text-center">
            Temporary Residence ID <br />
            暂住证号
          </td>

          <td class="text-center">
            Date to Temporary Residence ID <br />
            暂住证日期
          </td>
          <td class="text-center">
            Class Name<br />
            医保等级
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{ item.EmployeeCode }}
          </td>
          <td class="text-center">
            {{ item.NameinEnglish }}
          </td>
          <td class="text-center">
            {{ item.Gender | labelFormater(opinions3) }}
          </td>

          <td class="text-center">
            {{ item.Nationality | nationalityFormater(nationalityList) }}
          </td>

          <td class="text-center">
            {{ item.DateofBirth | tableDateFrom }}
          </td>

          <td class="text-center">
            {{ item.PhoneNOinWorkingCountry }}
          </td>

          <td class="text-center">
            {{ item.MaritalStatus | MarriageFrom(Marriages) }}
          </td>
          <td class="text-center">
            {{ item.ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            {{ item.SubProjectID | projectFormater(item.subProjectList) }}
          </td>
          <td class="text-center">
            {{ item.CompanyID | companyFormater(companyList) }}
          </td>

          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>

          <td class="text-center">
            {{ item.SPONSOR | labelFormater(opinions1) }}
          </td>
          <td class="text-center">
            {{ item.BorderNumber }}
          </td>
          <td class="text-center">
            {{ item.IQAMAID }}
          </td>
          <td class="text-center">
            {{ item.IQAMAValidDate | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.ClassID | classListFrom(classList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">Note 说明</td>
          <td class="text-left" colspan="14">
            <div class="div-box-content">
              <div class="div-box-content-left">
                <p>
                  * This application is applicable for new employees to register
                  medical insurance for the first time after joining the
                  company. The HR department of SNEMEC will purchase the
                  corresponding medical insurance class according to the
                  employee information.
                </p>
                <p>
                  *
                  此审批表仅用于新员工入职后首次注册购买医疗保险，由中东公司人力资源部按照员工信息注册购买相对应医疗保险级别
                </p>
              </div>
              <div class="div-box-content-right">
                <table
                  class="customize-form"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <tbody>
                    <tr>
                      <td class="content">Insurance Class</td>
                      <td class="content">Saudi Nationality</td>
                      <td class="content">non-Saudi Nationality</td>
                    </tr>
                    <tr>
                      <td class="content">Class VIP</td>
                      <td class="content">
                        About SNEMEC/Project Manager Level
                      </td>
                      <td class="content">
                        About SNEMEC/Project Manager Level
                      </td>
                    </tr>
                    <tr>
                      <td class="content">Class A</td>
                      <td class="content">Same Level As Deparment Manger</td>
                      <td class="content">
                        Same Level As SNEMEC/Project Manager Level
                      </td>
                    </tr>
                    <tr>
                      <td class="content">Class B</td>
                      <td class="content">Below Department Manager Level</td>
                      <td class="content">Same Level As Deparment Manger</td>
                    </tr>
                    <tr>
                      <td class="content">Class Staff</td>
                      <td class="content"></td>
                      <td class="content">Below Department Manager Level</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Manager<br />
            单位/部门领导审批
          </td>
          <td class="text-left text-size" colspan="14">
            <div class="textarea-box">
              {{ ApprovedbyDepartmentRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyDepartment"
                  :src="ApprovedbyDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Project Authorized<br />
            项目授权人审批
          </td>
          <td class="text-left text-size" colspan="14">
            <div class="textarea-box">
              {{ ApprovedbyProjectAuthorizedRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyProjectAuthorized"
                  :src="ApprovedbyProjectAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyProjectAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC HR Department<br />
            中东公司人力资源部审批
          </td>
          <td class="text-left text-size" colspan="14">
            <div class="textarea-box">
              {{ ApprovedbySNEMCHRDepartmentRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCHRDepartment"
                  :src="ApprovedbySNEMCHRDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCHRDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMC Authorized<br />
            中东公司授权人审批
          </td>
          <td class="text-left text-size" colspan="14">
            <div class="textarea-box">
              {{ ApprovedbySNEMCAuthorizedRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMCAuthorized"
                  :src="ApprovedbySNEMCAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMCAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  uploadAttachment,
  getProjectsChildren,
  getDepartmentAll,
  allPosition,
} from "@/api/user";
import {
  getGeneralBasic,
  getUsersCode,
  getEmployeeMedicalInsuranceClassLevelsAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    MarriageFrom(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.MarriageNameCHS : null;
    },
    classListFrom(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.NameinChinese : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      ID: null,
      KeyID: null,
      ApplicationDate: null,
      ApplicationNumber: null,
      Remark: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      ApprovedbyDepartment: null,
      ApprovedbyDepartmentRemark: null,
      ApprovedbyDepartmentDate: null,
      ApprovedbyProjectAuthorized: null,
      ApprovedbyProjectAuthorizedRemark: null,
      ApprovedbyProjectAuthorizedDate: null,
      ApprovedbySNEMCHRDepartment: null,
      ApprovedbySNEMCHRDepartmentRemark: null,
      ApprovedbySNEMCHRDepartmentDate: null,
      ApprovedbySNEMCAuthorized: null,
      ApprovedbySNEMCAuthorizedRemark: null,
      ApprovedbySNEMCAuthorizedDate: null,
      EditState: 1,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        EmployeeCode: null,
        NameinChinese: null,
        NameinEnglish: null,
        Gender: null,
        Nationality: null,
        NationalityName: null,
        DateofBirth: null,
        PhoneNOinWorkingCountry: null,
        MaritalStatus: null,
        MaritalStatusName: null,
        ProjectID: null,
        ProjectName: null,
        SubProjectID: null,
        SubProjectName: null,
        CompanyID: null,
        WorkingUnit: null,
        DepartmentID: null,
        WorkingDepartment: null,
        JobProfession: null,
        JobProfessionName: null,
        SPONSOR: null,
        BorderNumber: null,
        IQAMAID: null,
        IQAMAValidDate: null,
        ClassID: null,
        ClassName: null,
        subProjectList: [],
      },
      opinions1: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      opinions2: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      opinions3: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
        {
          value: 3,
          label: "未知",
        },
      ],
      projectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      nationalityList: [],
      Marriages: [],
      departmentList: [],
      classList: [],
    };
  },
  methods: {
    getNew() {
      this.ApplicationDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ApplicationDate: this.ApplicationDate,
        ApplicationNumber: this.ApplicationNumber,
        Remark: this.Remark,
        ApprovedbyDepartment: this.ApprovedbyDepartment,
        ApprovedbyDepartmentRemark: this.ApprovedbyDepartmentRemark,
        ApprovedbyDepartmentDate: this.ApprovedbyDepartmentDate,
        ApprovedbyProjectAuthorized: this.ApprovedbyProjectAuthorized,
        ApprovedbyProjectAuthorizedRemark:
          this.ApprovedbyProjectAuthorizedRemark,
        ApprovedbyProjectAuthorizedDate: this.ApprovedbyProjectAuthorizedDate,
        ApprovedbySNEMCHRDepartment: this.ApprovedbySNEMCHRDepartment,
        ApprovedbySNEMCHRDepartmentRemark:
          this.ApprovedbySNEMCHRDepartmentRemark,
        ApprovedbySNEMCHRDepartmentDate: this.ApprovedbySNEMCHRDepartmentDate,
        ApprovedbySNEMCAuthorized: this.ApprovedbySNEMCAuthorized,
        ApprovedbySNEMCAuthorizedRemark: this.ApprovedbySNEMCAuthorizedRemark,
        ApprovedbySNEMCAuthorizedDate: this.ApprovedbySNEMCAuthorizedDate,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.EmployeeID && item.ProjectID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      return data;
    },
    geteditData(params) {
      console.log("params", params);
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ApplicationDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.Remark = params.Remark;
      this.ApprovedbyDepartment = params.ApprovedbyDepartment;
      this.ApprovedbyDepartmentRemark = params.ApprovedbyDepartmentRemark;
      this.ApprovedbyDepartmentDate = params.ApprovedbyDepartmentDate;
      this.ApprovedbyProjectAuthorized = params.ApprovedbyProjectAuthorized;
      this.ApprovedbyProjectAuthorizedRemark =
        params.ApprovedbyProjectAuthorizedRemark;
      this.ApprovedbyProjectAuthorizedDate =
        params.ApprovedbyProjectAuthorizedDate;
      this.ApprovedbySNEMCHRDepartment = params.ApprovedbySNEMCHRDepartment;
      this.ApprovedbySNEMCHRDepartmentRemark =
        params.ApprovedbySNEMCHRDepartmentRemark;
      this.ApprovedbySNEMCHRDepartmentDate =
        params.ApprovedbySNEMCHRDepartmentDate;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
      this.ApprovedbySNEMCAuthorizedRemark =
        params.ApprovedbySNEMCAuthorizedRemark;
      this.ApprovedbySNEMCAuthorizedDate = params.ApprovedbySNEMCAuthorizedDate;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      params.Details.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].EmployeeID = params.Details[index].EmployeeCode;
          params.Details[index].subProjectList = [];
          if (index <= 9) {
            this.CostAllocationDetails[index] = JSON.parse(
              JSON.stringify(params.Details[index])
            );
          } else {
            this.CostAllocationDetails.push(
              JSON.parse(JSON.stringify(params.Details[index]))
            );
          }
        }
      });
      this.CostAllocationDetails.forEach((item, index, Array) => {
        if (item.ProjectID) {
          this.getProjectsChildren(item.ProjectID, index);
        }
        Array[index].ForeignAffairsCategory = this.ForeignAffairsCategory;
      });
      this.costAllocationItem.ForeignAffairsCategory =
        this.ForeignAffairsCategory;
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ApplicationDate = null;
      this.ApplicationNumber = null;
      this.Remark = null;
      this.ApprovedbyDepartment = null;
      this.ApprovedbyDepartmentRemark = null;
      this.ApprovedbyDepartmentDate = null;
      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;
      this.ApprovedbySNEMCHRDepartment = null;
      this.ApprovedbySNEMCHRDepartmentRemark = null;
      this.ApprovedbySNEMCHRDepartmentDate = null;
      this.ApprovedbySNEMCAuthorized = null;
      this.ApprovedbySNEMCAuthorizedRemark = null;
      this.ApprovedbySNEMCAuthorizedDate = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;

      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    //通过员工编码读取数据
    async changeEmployeeID(index) {
      const value = this.CostAllocationDetails[index].EmployeeID;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 2 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (userInfo) {
        console.log("userInfo", userInfo);
        this.CostAllocationDetails[index].Gender = userInfo.Gender;

        this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
        this.CostAllocationDetails[index].DateofBirth = userInfo.DateofBirth;

        this.CostAllocationDetails[index].PhoneNOinWorkingCountry =
          userInfo.PhoneNOinWorkingCountry;
        this.CostAllocationDetails[index].MaritalStatus =
          userInfo.MaritalStatus;

        this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
        this.CostAllocationDetails[index].ProjectID = userInfo.ProjectID;
        this.CostAllocationDetails[index].SubProjectID = userInfo.SubProjectID;
        this.CostAllocationDetails[index].CompanyID = userInfo.CompanyID;
        this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
        this.CostAllocationDetails[index].PassportCode =
          userInfo.Passport.PPCode;
        this.CostAllocationDetails[index].IQAMAID = userInfo.IQAMAID;
        this.CostAllocationDetails[index].SPONSOR = userInfo.Sponsor;
        this.CostAllocationDetails[index].BorderNumber = userInfo.BorderNumber;
        this.CostAllocationDetails[index].IQAMAValidDate =
          userInfo.IQAMAValidDate;

        // this.CostAllocationDetails[index].EmployeeCode = userInfo.UserCode;
        this.getProjectsChildren(userInfo.ProjectID, index);
      }

      if (index === this.CostAllocationDetails.length - 1) {
        this.CostAllocationDetails.push(
          JSON.parse(JSON.stringify(this.costAllocationItem))
        );
      }
    },

    //通过选中项目获取分项目
    changeProjectID(index) {
      const ProjectID = this.CostAllocationDetails[index].ProjectID;
      this.CostAllocationDetails[index].subProjectList = [];
      this.CostAllocationDetails[index].SubProjectID = null;
      if (ProjectID || ProjectID === 0) {
        this.getProjectsChildren(ProjectID, index);
      }
    },

    getProjectsChildren(id, index) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.CostAllocationDetails[index].subProjectList = res.response;
        }
      });
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      location.href = data;
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.Marriages = res.response.Marriages;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getEmployeeMedicalInsuranceClassLevelsAll().then((res) => {
      if (res.status === 200) {
        this.classList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .tbale-img1 {
    max-width: 120px;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .div-box-content {
    width: 100%;
    display: flex;
    .div-box-content-left {
      width: 40%;
      padding: 10px 0;
      margin-right: 5%;
      flex-shrink: 0;
      line-height: 20px;
      p {
        margin-top: 20px;
      }
    }
    .div-box-content-right {
      width: 50%;
      .content {
        text-align: center;
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 30px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
      p {
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}

.el-checkbox
  >>> .el-checkbox__input.is-disabled.is-checked
  .el-checkbox__inner::after {
  border-color: #000 !important;
}.textarea-box {
  min-height: 50px;
  line-height: 20px;
  font-size: 14px;
  color: #606266;
  border: none;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>