<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="3">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="9">
            nvitation Letter Application of Sinopec Nanjing Engineering Middle
            East Co., Ltd
            <br />
            中石化南京工程中东公司访问签证邀请函办理申请表
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2" colspan="3">
            Employee's Information <br />携带家属员工信息
          </td>
          <td class="text-center">Employee ID <br />员工编码</td>
          <td class="text-center" colspan="2">
            {{ EmployeeID }}
          </td>
          <td class="text-center">
            Name in Chinese<br />
            中文姓名
          </td>
          <td class="text-center">
            {{ NameinChinese }}
          </td>
          <td class="text-center">Project Name <br /></td>
          <td class="text-center">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">SubProject Name <br />分项目名称</td>
          <td class="text-center">
            {{ SubProjectID | projectFormater(subProjectList) }}
          </td>
          <td class="text-center">Working Unit<br />用工单位</td>
          <td class="text-center">
            {{ CompanyID | companyFormater(companyList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Passport No <br />护照号</td>
          <td class="text-center" colspan="2">
            {{ PassportCode }}
          </td>
          <td class="text-center">Name in English <br />拼音姓名</td>
          <td class="text-center">
            {{ NameinEnglish }}
          </td>
          <td class="text-center">
            IQAMA/Border No. <br />
            暂住证号/入境号
          </td>
          <td class="text-center">
            {{ IQAMAIDBorderNO }}
          </td>
          <td class="text-center">Job Profession<br />岗位名称</td>
          <td class="text-center">
            {{ JobProfession | positionFormater(positionList) }}
          </td>

          <td class="text-center">
            Applicant’s length of service<br />申请人工作年限
          </td>
          <td class="text-center">
            {{ ApplicantsLengthofService }}
          </td>
        </tr>
        <tr>
          <td colspan="14" class="text-center">
            Information of Employee Relatives 员工家属基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center">
            S/N<br />
            序号
          </td>
          <td class="text-center">
            Relationship with applicant <br />
            与申请人关系
          </td>
          <td class="text-center">
            Name in Chinese<br />
            中文姓名
          </td>
          <td class="text-center">
            Name in English<br />
            拼音姓名
          </td>
          <td class="text-center">
            Gender<br />
            性别
          </td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            Date of Birth<br />
            出生日期
          </td>
          <td class="text-center">
            Age<br />
            年龄
          </td>
          <td class="text-center">
            Religion<br />
            宗教信仰
          </td>
          <td class="text-center" colspan="2">
            Passport No<br />
            护照号
          </td>
          <td class="text-center">
            PP Expiry Date<br />
            护照有效期
          </td>
          <td class="text-center" colspan="2">
            Remark <br />
            备注
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{ item.RelationshipWithApplicant }}
          </td>
          <td class="text-center" >
            {{ item.NameinChinese }}
          </td>
          <td class="text-center" >
            {{ item.NameinEnglish }}
          </td>
          <td class="text-center">
            <span v-if="item.Gender == 1">男</span>
            <span v-if="item.Gender == 2">女</span>
          </td>

          <td class="text-center">
            {{ item.Nationality | nationalityFormater(Countries) }}
          </td>

          <td class="text-center">
            {{ item.DateofBirth | tableDateFrom }}
          </td>
          <td class="text-center">
            {{ item.Age }}
          </td>

          <td class="text-center">
            {{ item.Religion | rligionFormater(Religions) }}
          </td>
          <td class="text-center" colspan="2">
            {{ item.PassportCode }}
          </td>
          <td class="text-center">
            {{ item.PPValidDate | tableDateFrom }}
          </td>
          <td class="text-center" colspan="2">
            {{ item.Remark }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Note<br />
            填表说明
          </td>
          <td class="text-center" colspan="12">
            *
            申请单人须上传待认证的邀请函文件、本人签字的承诺书、认证费转账记录。<br />
            * The applicant needs to upload documents to be certified, letters
            of commitment, and certification fee transfer records..
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Approval Record<br />
            审批记录
          </td>

          <td class="text-center" colspan="3">
            Approved by Department 单位/部门
          </td>
          <td class="text-center" colspan="3">
            Approved by Proejct Authorized 项目部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC HR Department 中东公司人力资源部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC Authorized 中东公司授权人
          </td>
        </tr>
        <tr>
          <td class="text-title" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyProejctAuthorized" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCHRDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getProjectsChildren,
} from "@/api/user";
import {
  addInvitationLetterApplication2,
  getUsersCode,
  getGeneralBasic,
  editInvitationLetterApplicationItem2,
} from "@/api/table";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    rligionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.ReligionCHS : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
  },
  data() {
    return {
      TableDate: null,
      ListerDept: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      positionList: [],
      subProjectList: [],
      Countries: [],
      Religions: [],
      ID: null,
      KeyID: null,
      EmployeeID: null,
      NameinEnglish: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      PassportCode: null,
      IQAMAIDBorderNO: null,
      JobProfession: null,
      ApplicantsLengthofService: null,
      CostAllocationDetails: [],
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        RelationshipWithApplicant: null,
        NameinEnglish: null,
        Gender: null,
        Nationality: null,
        DateofBirth: null,
        Age: null,
        Religion: null,
        PassportCode: null,
        PPValidDate: null,
        Remark: null,
      },
      ApprovedbyDept: null,
      ApprovedbyProejctAuthorized: null,
      ApprovedbySNEMCHRDept: null,
      ApprovedbySNEMCAuthorized: null,

      sexOptions: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
    };
  },

  methods: {
    getNew(data1, data2, data3) {
      this.projectList = data1;
      this.companyList = data2;
      this.positionList = data3;
      this.TableDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        EmployeeID: this.EmployeeID,
        NameinEnglish: this.NameinEnglish,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        PassportCode: this.PassportCode,
        IQAMAIDBorderNO: this.IQAMAIDBorderNO,
        JobProfession: this.JobProfession,
        ApplicantsLengthofService: this.ApplicantsLengthofService,
        EditState: EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.PassportCode && item.RelationshipWithApplicant) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      if (!this.ID) {
        addInvitationLetterApplication2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editInvitationLetterApplicationItem2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);

      this.TableDate = params.CreateDate;
      this.ListerDept = params.ListerDept;
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.EmployeeID = params.EmployeeID;
      this.NameinEnglish = params.NameinEnglish;
      this.NameinChinese = params.NameinChinese;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.PassportCode = params.PassportCode;
      this.IQAMAIDBorderNO = params.IQAMAIDOrBorderCode;
      this.JobProfession = params.JobProfession;
      this.ApplicantsLengthofService = params.ApplicantsLengthofService;
      this.ApprovedbyDept = params.ApprovedbyDept;
      this.ApprovedbyProejctAuthorized = params.ApprovedbyProejctAuthorized;
      this.ApprovedbySNEMCHRDept = params.ApprovedbySNEMCHRDept;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      this.getProjectsChildren();
    },
    clearData() {
      this.TableDate = null;
      this.ListerDept = null;
      this.ApplicationNumber = null;
      this.ID = null;
      this.KeyID = null;
      this.EmployeeID = null;
      this.NameinEnglish = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.PassportCode = null;
      this.IQAMAIDBorderNO = null;
      this.JobProfession = null;
      this.ApplicantsLengthofService = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.ApprovedbyDept = null;
      this.ApprovedbyProejctAuthorized = null;
      this.ApprovedbySNEMCHRDept = null;
      this.ApprovedbySNEMCAuthorized = null;
    },
    async changePassportCode() {
      let userInfo = null;
      await getUsersCode({ code: this.PassportCode, codeType: 1 }).then(
        (res) => {
          if (res.status === 200 && res.response) {
            userInfo = res.response;
          }
        }
      );
      if (!userInfo) {
        return;
      }
      this.EmployeeID = userInfo.UserCode;
      this.NameinEnglish = userInfo.EnglishName;
      this.ProjectID = userInfo.ProjectID;
      this.SubProjectID = userInfo.SubProjectID;
      this.CompanyID = userInfo.CompanyID;
      this.IQAMAIDBorderNO = userInfo.IQAMAID;
      this.JobProfession = userInfo.PositionID;
      this.getProjectsChildren();
      console.log("userInfo", userInfo);
    },
    changeProjectID() {
      this.SubProjectID = null;
      this.getProjectsChildren();
    },
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    changeDateofBirth(index) {
      this.CostAllocationDetails[index].Age = this.calculateAge(
        this.CostAllocationDetails[index].DateofBirth
      );
    },
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday); // 将生日字符串转换为Date对象
      let age = today.getFullYear() - birthDate.getFullYear(); // 计算年份差
      // 检查是否已经过了生日
      const todayMonth = today.getMonth();
      const birthMonth = birthDate.getMonth();
      const todayDay = today.getDate();
      const birthDay = birthDate.getDate();
      if (
        todayMonth < birthMonth ||
        (todayMonth === birthMonth && todayDay < birthDay)
      ) {
        age--; // 如果还没过生日，年龄减一
      }
      return age;
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.Countries = res.response.Countries;
        this.Religions = res.response.Religions;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 60px;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>