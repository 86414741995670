<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="4">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="11">
            Employee Visa Application of Sinopec Nanjing Engineering Middle East
            Co., Ltd
            <br />
            中石化南京工程中东公司员工签证审批表
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Project Name<br />
            项目名称
          </td>
          <td class="text-center" colspan="2">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">SubProject Name<br />分项目名称</td>
          <td class="text-center">
            {{ SubProjectID | projectFormater(subProjectList) }}
          </td>
          <td class="text-center">Working Unit<br />用工单位</td>
          <td class="text-center" colspan="2">
            {{ CompanyID | companyFormater(companyList) }}
          </td>
          <td class="text-center">Purpose<br />动迁目的</td>
          <td class="text-center">
            {{ Purpose }}
          </td>
          <td class="text-center">Date<br />动迁日期</td>
          <td class="text-center">
            {{ RelocationDate | tableDateFrom }}
          </td>
          <td class="text-center">Visa Type<br />签证类型</td>
          <td class="text-center">
            {{ VisaType | visaTypeFormater(opinions1) }}
          </td>
          <td class="text-center">New Sponsor<br />新办签证Ssponsor</td>
          <td class="text-center">
            {{ Sponsor | sponsorFormater(opinions2) }}
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            S/N<br />
            序号
          </td>
          <td class="text-center" colspan="6">
            Employee Basic Information<br />
            员工基本信息
          </td>
          <td class="text-center" colspan="4">
            Employee Passport Information<br />
            员工护照信息
          </td>
          <td class="text-center" colspan="2">
            Overseas Work Experience <br />
            过往出国经历
          </td>
          <td class="text-center" colspan="3">
            Latest Demobilization Information of Overseas Work <br />
            最近一次海外工作撤场记录
          </td>
          <td class="text-center" rowspan="2">
            Employment Type <br />
            用工形式
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Employee ID <br />
            员工编码
          </td>
          <td class="text-center">
            Name in Chinese <br />
            中文姓名
          </td>
          <td class="text-center">
            Name in English <br />
            拼音姓名
          </td>
          <td class="text-center">
            ID of Home Country <br />
            员工母国身份证号
          </td>
          <td class="text-center">
            Date of Birth<br />
            出生日期
          </td>
          <td class="text-center">
            Job Profession<br />
            岗位名称
          </td>
          <td class="text-center">
            PP No<br />
            护照号
          </td>
          <td class="text-center">
            Place of Issue<br />
            签发地
          </td>
          <td class="text-center">
            PP Type<br />
            护照类型
          </td>
          <td class="text-center">
            PP Valid Date<br />
            护照有效期
          </td>
          <td class="text-center" colspan="2">
            (Country/ Staty Duration/Times )<br />
            （国家/停留时长/次数）
          </td>
          <td class="text-center" colspan="3">
            （Previous Sponsor/Visa Type/ Demobilization Date）<br />
            （原Sponsor/签证类型/撤离时间）
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td class="text-center">
            <span>{{ item.EmployeeID }}</span>
          </td>
          <td class="text-center">
            <span>{{ item.NameinChinese }}</span>
          </td>
          <td class="text-center">
            <span>{{ item.NameinEnglish }}</span>
          </td>
          <td class="text-center">
            <span>{{ item.IDofHomeCountry }}</span>
          </td>
          <td class="text-center">
            <span>{{ item.DateofBirth | tableDateFrom }}</span>
          </td>
          <td class="text-center">
            <span>
              {{ item.JobProfession | positionFormater(positionList) }}</span
            >
          </td>
          <td class="text-center">
            {{ item.PassportCode }}
          </td>
          <td class="text-center">
            {{ item.PlaceofIssue }}
          </td>
          <td class="text-center">
            <span>{{
              item.PPType === 0 ? "因私" : item.PPType === 1 ? "因公" : ""
            }}</span>
          </td>
          <td class="text-center">
            <span>{{ item.PPValidDate | tableDateFrom }}</span>
          </td>
          <td class="text-center" colspan="2">
            {{ item.CountryStatyDurationTimes }}
          </td>
          <td class="text-center" colspan="3">
            {{ item.PreviousSponsorVisaTypeDemobilizationDate }}
          </td>
          <td class="text-center">
            <span> {{ item.EmploymentType | employmentTypeFormater }}</span>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Note<br />
            填表说明
          </td>
          <!-- <td class="text-center"></td> -->
          <td class="text-left" colspan="14">
            *各层级在逐级审批时，均可在对应的审批框内录入审批意见。<br />
            *Approval opinions can be entered in the corresponding approval
            record at each level during step-by-step approval.
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3" rowspan="2">
            Approval Record<br />
            审批记录
          </td>
          <!-- <td class="text-center"></td> -->
          <td class="text-center" colspan="3">
            Approved by Department 单位/部门
          </td>
          <td class="text-center" colspan="3">
            Approved by Proejct Authorized 项目部
          </td>
          <td class="text-center" colspan="4">
            Approved by SNEMC HR Department 中东公司人力资源部
          </td>
          <td class="text-center" colspan="4">
            Approved by SNEMC Authorized 中东公司授权人
          </td>
        </tr>
        <tr>
          <!-- <td class="text-center"></td> -->
          <td class="text-title" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyProejctAuthorized" />
            </div>
          </td>
          <td class="text-center" colspan="4">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCHRDept" />
            </div>
          </td>
          <td class="text-center" colspan="4">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getProjectsChildren,
  uploadAttachment,
} from "@/api/user";
import {
  getUsersCode,
  addEmployeeVisaApplication,
  editEmployeeVisaApplication,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    visaTypeFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    sponsorFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      subProjectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      ID: null,
      KeyID: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      Purpose: null,
      RelocationDate: null,
      VisaType: null,
      Sponsor: null,
      EditState: null,
      PassportCopyUrl: null, //护照复印件
      HomeCountryIDCardCopyUrl: null, //员工母国身份证复印件
      HomeCountryMedicalReportUrl: null, //国际健康履行证明
      OverageApprovalLetterUrl: null, //OverageApprovalLetterUrl
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        NameinChinese: null,
        NameinEnglish: null,
        IDofHomeCountry: null,
        DateofBirth: null,
        JobProfession: null,
        PassportCode: null,
        PlaceofIssue: null,
        PPType: null,
        PPValidDate: null,
        CountryStatyDurationTimes: null,
        PreviousSponsorVisaTypeDemobilizationDate: null,
        EmploymentType: null,
      },
      opinions1: [
        {
          value: 1,
          label: "工作",
        },
        {
          value: 2,
          label: "工访",
        },
        {
          value: 3,
          label: "商访",
        },
        {
          value: 4,
          label: "家访",
        },
      ],
      opinions2: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      options3: [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ],
      PPTypeList: [
        {
          value: 0,
          label: "因私",
        },
        {
          value: 1,
          label: "因公",
        },
      ],
      ApprovedbyDept: null,
      ApprovedbyProejctAuthorized: null,
      ApprovedbySNEMCHRDept: null,
      ApprovedbySNEMCAuthorized: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    getNew(data1, data2, data3) {
      this.projectList = data1;
      this.companyList = data2;
      this.positionList = data3;
      this.TableDate = getNewDate();

      if (localStorage.getItem("userInfo")) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.Employee) {
          this.ProjectID = userInfo.Employee.ProjectID;
          this.SubProjectID = userInfo.Employee.SubProjectID;
          this.CompanyID = userInfo.Employee.CompanyID;
          this.getProjectsChildren();
        }
      }
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        Purpose: this.Purpose,
        RelocationDate: this.RelocationDate,
        VisaType: this.VisaType,
        Sponsor: this.Sponsor,
        PassportCopyUrl: this.PassportCopyUrl,
        HomeCountryIDCardCopyUrl: this.HomeCountryIDCardCopyUrl,
        HomeCountryMedicalReportUrl: this.HomeCountryMedicalReportUrl,
        OverageApprovalLetterUrl: this.OverageApprovalLetterUrl,
        EditState: EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.EmployeeID && item.PassportCode) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      // 赴沙员工新办签证审批 选择工作签，判断护照、身份证、健康疫苗证证三个是否上传
      let visaTypeIsTrue = false;
      if (this.VisaType === 1) {
        if (
          !this.PassportCopyUrl ||
          !this.HomeCountryIDCardCopyUrl ||
          !this.HomeCountryMedicalReportUrl
        ) {
          visaTypeIsTrue = true;
        }
      }
      if (visaTypeIsTrue) {
        this.$message.warning("工作签,需上传护照、身份证、健康疫苗证");
        return;
      }
      // 赴沙员工新办签证审批 选择工作签，判断是否超过60岁，超过，则提示需要上传超龄审批附件
      let visaTypeIsTrue2 = false;
      if (this.VisaType === 1) {
        let DateofBirthList = [];
        data.Details.forEach((item) => {
          DateofBirthList.push(item.DateofBirth);
        });
        let DateofBirthList2 = [];
        DateofBirthList.forEach((item) => {
          DateofBirthList2.push(this.calculateAge(item));
        });
        DateofBirthList2.forEach((item) => {
          if (item > 60 && !this.OverageApprovalLetterUrl) {
            visaTypeIsTrue2 = true;
          }
        });
        console.log("DateofBirthList", DateofBirthList2);
      }
      if (visaTypeIsTrue2) {
        this.$message.warning("年龄大于60,需上传超龄审批附件");
        return;
      }

      // 赴沙员工新办签证审批 选择访问签，判断护照是否上传
      let visaTypeIsTrue3 = false;
      if (this.VisaType === 2 || this.VisaType === 3 || this.VisaType === 4) {
        if (!this.PassportCopyUrl) {
          visaTypeIsTrue3 = true;
        }
      }
      if (visaTypeIsTrue3) {
        this.$message.warning("访问签,需上传护照附件");
        return;
      }

      if (!this.ID) {
        addEmployeeVisaApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editEmployeeVisaApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    getInfo() {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        Purpose: this.Purpose,
        RelocationDate: this.RelocationDate,
        VisaType: this.VisaType,
        Sponsor: this.Sponsor,
        PassportCopyUrl: this.PassportCopyUrl,
        HomeCountryIDCardCopyUrl: this.HomeCountryIDCardCopyUrl,
        HomeCountryMedicalReportUrl: this.HomeCountryMedicalReportUrl,
        OverageApprovalLetterUrl: this.OverageApprovalLetterUrl,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.EmployeeID && item.PassportCode) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      return data;
    },
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday); // 将生日字符串转换为Date对象
      let age = today.getFullYear() - birthDate.getFullYear(); // 计算年份差
      // 检查是否已经过了生日
      const todayMonth = today.getMonth();
      const birthMonth = birthDate.getMonth();
      const todayDay = today.getDate();
      const birthDay = birthDate.getDate();
      if (
        todayMonth < birthMonth ||
        (todayMonth === birthMonth && todayDay < birthDay)
      ) {
        age--; // 如果还没过生日，年龄减一
      }
      return age;
    },
    geteditData(data1, data2, data3, params) {
      console.log("params", params);
      this.projectList = data1;
      this.companyList = data2;
      this.positionList = data3;
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.Purpose = params.Purpose;
      this.RelocationDate = params.RelocationDate;
      this.VisaType = params.VisaType;
      this.Sponsor = params.Sponsor;
      this.PassportCopyUrl = params.PassportCopyUrl;
      this.HomeCountryIDCardCopyUrl = params.HomeCountryIDCardCopyUrl;
      this.HomeCountryMedicalReportUrl = params.HomeCountryMedicalReportUrl;
      this.OverageApprovalLetterUrl = params.OverageApprovalLetterUrl;
      this.getProjectsChildren();
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          //员工编号
          params.Details[index].EmployeeID = params.Details[index].EmployeeID
            ? params.Details[index].EmployeeID
            : params.Details[index].EmployeeCode;
          //员工英文名
          params.Details[index].NameinEnglish =
            params.Details[index].EmployeeNameEN;

          //员工中文名
          params.Details[index].NameinChinese =
            params.Details[index].EmployeeName;
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      this.ApprovedbyDept = params.ApprovedbyDept;
      this.ApprovedbyProejctAuthorized = params.ApprovedbyProejctAuthorized;
      this.ApprovedbySNEMCHRDept = params.ApprovedbySNEMCHRDept;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.Purpose = null;
      this.RelocationDate = null;
      this.VisaType = null;
      this.Sponsor = null;
      this.EditState = null;
      this.PassportCopyUrl = null;
      this.HomeCountryIDCardCopyUrl = null;
      this.HomeCountryMedicalReportUrl = null;
      this.OverageApprovalLetterUrl = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.ApprovedbyDept = null;
      this.ApprovedbyProejctAuthorized = null;
      this.ApprovedbySNEMCHRDept = null;
      this.ApprovedbySNEMCAuthorized = null;
    },
    //通过录入护照号拿个人信息
    async changePassportCode(index) {
      const value = this.CostAllocationDetails[index].PassportCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 1 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (!userInfo) {
        this.CostAllocationDetails[index].isUser = false;
        return;
      }
      //判断有几条数据了
      this.CostAllocationDetails[index].isUser = true;
      this.CostAllocationDetails[index].EmployeeID = userInfo.UserCode;
      this.CostAllocationDetails[index].NameinChinese = userInfo.ChineseName;
      this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
      this.CostAllocationDetails[index].IDofHomeCountry = userInfo.IDNumber;
      this.CostAllocationDetails[index].DateofBirth = userInfo.DateofBirth;
      this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
      this.CostAllocationDetails[index].PPType = userInfo.Passport.PPType;
      this.CostAllocationDetails[index].PPValidDate =
        userInfo.Passport.PPValidDate;
      this.CostAllocationDetails[index].EmploymentType =
        userInfo.EmploymentType;
    },
    //获取列表子项目
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    changeProjectID() {
      this.SubProjectID = null;
      this.getProjectsChildren();
    },

    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.PassportCopyUrl =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.HomeCountryIDCardCopyUrl =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.HomeCountryMedicalReportUrl =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.OverageApprovalLetterUrl =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },

    deleteItem(index) {
      switch (index) {
        case 1:
          this.PassportCopyUrl = null;
          break;
        case 2:
          this.HomeCountryIDCardCopyUrl = null;
          break;
        case 3:
          this.HomeCountryMedicalReportUrl = null;
          break;
        case 4:
          this.OverageApprovalLetterUrl = null;
          break;
      }
    },

    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 12px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  cursor: pointer;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>