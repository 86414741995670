<template>
  <div class="customize-main">
    <vue2-water-marker v-if="ApprovedbySNEMECHRDept" :imgSrc="ApprovedbySNEMECHRDept"></vue2-water-marker>

    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="6">
            Purchase Order of Supplier Manpower <br />
            当地岗位服务外包订单
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ DurationofUsage | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">PO No.</td>
          <td class="text-center" colspan="3">
            <el-input v-model="PONO" placeholder="请输入 Enter" />
          </td>
          <td class="text-center" colspan="2">Duration of Usage</td>
          <td class="text-center">{{ DurationofUsage | tableDateFrom }}</td>
          <td class="text-center">to</td>
          <td class="text-center">
            {{ DurationofUsageto }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">To Second Party</td>
          <td class="text-center" colspan="2">
            {{
              SupplierKeyID | purchaseOrderFrom3(purchaseOrderApplicationList)
            }}
          </td>
          <td class="text-center">
            {{
              SupplierKeyID | purchaseOrderFrom(purchaseOrderApplicationList)
            }}
          </td>
          <td class="text-center" colspan="2">From First Party</td>
          <td class="text-center" colspan="3">
            {{ FirstParty | governmentListFrom(GovernmentList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">Supplier's CR NO.</td>
          <td class="text-center" colspan="3">
            {{
              SupplierKeyID | purchaseOrderFrom2(purchaseOrderApplicationList)
            }}
          </td>
          <td class="text-center" colspan="2">Contractor CR NO.</td>
          <td class="text-center" colspan="3">
            {{ CRNOofContractor }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="4">
            Supplier's Contact
          </td>
          <td class="text-center">Coordinator Name</td>
          <td class="text-center" colspan="2">
            {{ CoordinatorName }}
          </td>
          <td class="text-center" rowspan="4" colspan="2">Project's Contact</td>
          <td class="text-center">Project Name</td>
          <td class="text-center">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            {{ SubProjectID | projectFormater(subProjectList) }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Coordinator IQAMA</td>
          <td class="text-center" colspan="2">
            {{ CoordinatorIQAMA }}
          </td>
          <td class="text-center">Prepared by</td>
          <td class="text-center" colspan="2">
            {{ Preparedby }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Mobile Number</td>
          <td class="text-center" colspan="2">
            {{ MobileNumberofSupplier }}
          </td>
          <td class="text-center">Mobile Number</td>
          <td class="text-center" colspan="2">
            {{ MobileNumberofProject }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Email Address</td>
          <td class="text-center" colspan="2">
            {{ EmailAddressofSupplier }}
          </td>
          <td class="text-center">Email Address</td>
          <td class="text-center" colspan="2">
            {{ EmailAddressofProject }}
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="10">
            Dear Sirs<br />
            We are pleased to order the following resources from your company
            and please mobilize as required.
          </td>
        </tr>
        <tr>
          <td class="text-center">SN</td>
          <td class="text-center">Category & Discipline</td>
          <td class="text-center">Job Profession</td>
          <td class="text-center">Qualif.</td>
          <td class="text-center">Qty.</td>
          <td class="text-center">Rate (SR/Hr)</td>
          <td class="text-center">FAT Beared By</td>

          <td class="text-center">Reference PO. No.</td>
          <td class="text-center">PO Status</td>
          <td class="text-center">Remarks</td>
        </tr>
        <tr
          :class="[{ 'tr-active': item.OutofGuidancePrice === 1 }]"
          v-for="(item, index) in CostAllocationDetails"
          :key="index"
        >
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{ item.Category | positionCategoryListFrom(positionCategoryList) }}
          </td>
          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">
            {{ item.Qualif | labelFormater(opinions1) }}
          </td>
          <td class="text-center">
            {{ item.Qty }}
          </td>
          <td class="text-center">
            {{ item.Rate }}
          </td>
          <td class="text-center">
            {{ item.FATBearedBy | labelFormater(opinions2) }}
          </td>
          <td class="text-center">
            {{ item.ReferencePONO }}
          </td>
          <td class="text-center">
            {{ item.POStatus | labelFormater(opinions3) }}
          </td>
          <td class="text-center">
            {{ item.Remarks }}
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="10">Note:</td>
        </tr>
        <tr>
          <td class="text-center">A</td>
          <td class="text-left" colspan="9">
            The hourly rate is fixed and applied to normal working hours,
            overtime working hours on weekend and holidays. This hourly rate is
            not including VAT and the VAT invoice shall apply with VAT
            management requirement of Saudi Arabia.
          </td>
        </tr>
        <tr>
          <td class="text-center">B</td>
          <td class="text-left" colspan="9">
            The supplier shall bear these costs of its employees, such as
            salary, sick leaving pay, vacation pay, mobilization/demobilization
            fees, health insurance and medical expenses.
          </td>
        </tr>
        <tr>
          <td class="text-center">C</td>
          <td class="text-left" colspan="9">
            The basic PPE of supplier employee shall be equipped by supplier. If
            PPE is provided by
            <span style="color: #c00000; font-size: 14px; font-weight: bold"
              >SINOPEC</span
            >, this PPE cost fee is deducted in a lump sum from the first
            month's invoice.
          </td>
        </tr>
        <tr>
          <td class="text-center">D</td>
          <td class="text-left" colspan="9">
            Timesheet shall be started from the date of actual work at jobsite,
            the first month is regarded as probation period, SINOPEC reserve the
            right to release the candidate if his performance is not qualified
            with requirements, and travel time cannot be counted as working
            hours
          </td>
        </tr>
        <tr>
          <td class="text-center">E</td>
          <td class="text-left" colspan="9">
            The supplier shall provide the backup candidates to replace the
            supplier employee who will entitles vacation and long sick leaving
            in advance.
          </td>
        </tr>
        <tr>
          <td class="text-center">F</td>
          <td class="text-left" colspan="9">
            The last one month payment will be suspended if the supplier
            employee do not give written notice for resignation one moth in
            advance.
          </td>
        </tr>
        <tr>
          <td class="text-center">G</td>
          <td class="text-left" colspan="9">
            If supplier employee is absent from normal work and overtime work as
            per work schedule of job site without approval of supervisor or
            manager, it shall be considered as unauthorized leave which will be
            deducted double times absent hours from his total actual working
            hours.
          </td>
        </tr>

        <tr>
          <td class="text-center">H</td>
          <td class="text-left" colspan="9">
            The supplier employees shall work continuously as per SINOPEC’s
            requirement, the payment shall be suspended if the supplier
            employees could not perform his duty more than 1 month.
          </td>
        </tr>
        <tr>
          <td class="text-center">I</td>
          <td class="text-left" colspan="9">
            The work duration will be extended if no any objection from the
            SINOPEC and supplier.
          </td>
        </tr>
        <tr>
          <td class="text-center">J</td>
          <td class="text-left" colspan="9">
            SINOPEC reserve the right to claim the cost and compensation if
            supplier's employee damage the equipment and facility of SINOPEC or
            related party, and if employee demobilized before his
            training/qualification certificate expired which were provided by
            SINOPEC.
          </td>
        </tr>
        <tr v-for="(item, index) in Terms" :key="`${index}+ID`">
          <td class="text-center">
            {{ index | englishLetterFrom(englishLetter) }}
          </td>
          <td class="text-left" colspan="9">
            {{ item.Contents }}
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Manager<br />
            单位/部门领导审批
          </td>
          <td class="text-left text-size" colspan="7">
            <div class="textarea-box">
              {{ ApprovedbyManagerRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyManager"
                  :src="ApprovedbyManager"
                />
              </div>
              <div>
                <span>日期Date：</span><span>{{ ApprovedbyManagerDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by Project Authorized<br />
            项目授权人审批
          </td>
          <td class="text-left text-size" colspan="7">
            <div class="textarea-box">
              {{ ApprovedbyProjectAuthorizedRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyProjectAuthorized"
                  :src="ApprovedbyProjectAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyProjectAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMEC HR Dept.<br />
            中东公司人力资源部审批
          </td>
          <td class="text-left text-size" colspan="7">
            <div class="textarea-box">
              {{ ApprovedbySNEMECHRDeptRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMECHRDept"
                  :src="ApprovedbySNEMECHRDept"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMECHRDeptDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMEC Compliance Dept.<br />
            中东公司合规管理部审批
          </td>
          <td class="text-left text-size" colspan="7">
            <div class="textarea-box">
              {{ ApprovedbySNEMECComplianceDeptRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMECComplianceDept"
                  :src="ApprovedbySNEMECComplianceDept"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMECComplianceDeptDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="3">
            Approved by SNEMEC Authorized<br />
            中东公司授权人审批
          </td>
          <td class="text-left text-size" colspan="7">
            <div class="textarea-box">
              {{ ApprovedbySNEMECAuthorizedRemark }}
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMECAuthorized"
                  :src="ApprovedbySNEMECAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMECAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  allPosition,
  allPositionCategory,
  getProjectsChildren,
} from "@/api/user";
import {
  getPurchaseOrderApplicationSuppliers,
  getGovernmentSystemDicAll,
  addPurchaseOrderApplication,
  editPurchaseOrderApplication,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    purchaseOrderFrom3(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.SupplierName + item.AbbreviationName : null;
    },
    governmentListFrom(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.NameinChinese : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : "";
    },
    positionCategoryListFrom(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.NameCHS : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      waterImg: require("@/assets/image/teding.jpg"),
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      subProjectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      purchaseOrderApplicationList: [],
      GovernmentList: [],
      positionCategoryList: [],
      ID: null,
      KeyID: null,
      ProjectID: null,
      SubProjectID: null,
      PONO: null,
      DurationofUsage: null,
      DurationofUsageto: null,
      Supplier: null,
      SupplierKeyID: null,
      SupplierName: null,
      AbbreviationName: null,
      FirstParty: null,
      FirstPartyName: null,
      CRNOofSupplier: null,
      CRNOofContractor: null,
      CoordinatorName: null,
      CoordinatorIQAMA: null,
      MobileNumberofSupplier: null,
      EmailAddressofSupplier: null,
      Preparedby: null,
      MobileNumberofProject: null,
      EmailAddressofProject: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      Reason: null,
      EditState: 1,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        Category: null,
        CategoryName: null,
        JobProfession: null,
        JobProfessionName: null,
        Qualif: null,
        QualifName: null,
        Qty: null,
        Rate: null,
        FATBearedBy: null,
        FATBearedByName: null,
        ReferencePONO: null,
        POStatus: null,
        Remarks: null,
      },
      opinions1: [
        {
          value: 1,
          label: "Aramco",
        },
        {
          value: 2,
          label: "General",
        },
        {
          value: 3,
          label: "Sabic ",
        },
      ],
      opinions2: [
        {
          value: 1,
          label: "Manpower Supplier",
        },
        {
          value: 2,
          label: "Sinopec",
        },
      ],
      opinions3: [
        {
          value: 1,
          label: "Valid",
        },
        {
          value: 2,
          label: "Terminated",
        },
      ],
      englishLetter: [
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      Terms: [
        {
          ID: null,
          KeyID: null,
          MainKeyID: null,
          Contents: null,
        },
      ],

      ApprovedbyManager: null,
      ApprovedbyManagerRemark: null,
      ApprovedbyManagerDate: null,

      ApprovedbyProjectAuthorized: null,
      ApprovedbyProjectAuthorizedRemark: null,
      ApprovedbyProjectAuthorizedDate: null,

      ApprovedbySNEMECHRDept:
       null,
      ApprovedbySNEMECHRDeptRemark: null,
      ApprovedbySNEMECHRDeptDate: null,

      ApprovedbySNEMECComplianceDept: null,
      ApprovedbySNEMECComplianceDeptRemark: null,
      ApprovedbySNEMECComplianceDeptDate: null,

      ApprovedbySNEMECAuthorized: null,
      ApprovedbySNEMECAuthorizedRemark: null,
      ApprovedbySNEMECAuthorizedDate: null,
    };
  },
  methods: {
    getNew() {
      this.DurationofUsage = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        PONO: this.PONO,
        DurationofUsage: this.DurationofUsage,
        DurationofUsageto: this.DurationofUsageto,
        Supplier: this.Supplier,
        SupplierKeyID: this.SupplierKeyID,
        SupplierName: this.SupplierName,
        AbbreviationName: this.AbbreviationName,
        FirstParty: this.FirstParty,
        FirstPartyName: this.FirstPartyName,
        CRNOofSupplier: this.SupplierKeyID,
        CRNOofContractor: this.CRNOofContractor,
        CoordinatorName: this.CoordinatorName,
        CoordinatorIQAMA: this.CoordinatorIQAMA,
        MobileNumberofSupplier: this.MobileNumberofSupplier,
        EmailAddressofSupplier: this.EmailAddressofSupplier,
        Preparedby: this.Preparedby,
        MobileNumberofProject: this.MobileNumberofProject,
        EmailAddressofProject: this.EmailAddressofProject,
        EditState: EditState,
        Reason: this.Reason,
        Terms: this.Terms,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.Category && item.JobProfession) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (!this.ID) {
        addPurchaseOrderApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editPurchaseOrderApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.PONO = params.PONO;
      this.DurationofUsage = params.DurationofUsage;
      this.DurationofUsageto = params.DurationofUsageto;
      this.Supplier = params.Supplier;
      this.SupplierKeyID = params.SupplierKeyID;
      this.SupplierName = params.SupplierName;
      this.AbbreviationName = params.AbbreviationName;
      this.FirstParty = params.FirstParty;
      this.FirstPartyName = params.FirstPartyName;
      this.CRNOofSupplier = params.CRNOofSupplier;
      this.CRNOofContractor = params.CRNOofContractor;
      this.CoordinatorName = params.CoordinatorName;
      this.CoordinatorIQAMA = params.CoordinatorIQAMA;
      this.MobileNumberofSupplier = params.MobileNumberofSupplier;
      this.EmailAddressofSupplier = params.EmailAddressofSupplier;
      this.Preparedby = params.Preparedby;
      this.MobileNumberofProject = params.MobileNumberofProject;
      this.EmailAddressofProject = params.EmailAddressofProject;
      this.Reason = params.Reason;
      this.Terms = params.Terms;
      this.getProjectsChildren();
      params.Details.forEach((_, index, Array) => {
        if (index > 9) {
          if (params.Details[index]) {
            params.Details[index].Category = params.Details[index].CategoryID;
            params.Details[index].ReferencePONO =
              params.Details[index].ReferencePONO;
            this.CostAllocationDetails.push(
              JSON.parse(JSON.stringify(params.Details[index]))
            );
          }
        } else {
          if (params.Details[index]) {
            params.Details[index].Category = params.Details[index].CategoryID;
            this.CostAllocationDetails[index] = JSON.parse(
              JSON.stringify(params.Details[index])
            );
          }
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();

      this.ApprovedbyManager = params.ApprovedbyManager;
      this.ApprovedbyManagerRemark = params.ApprovedbyManagerRemark;
      this.ApprovedbyManagerDate = params.ApprovedbyManagerDate;

      this.ApprovedbyProjectAuthorized = params.ApprovedbyProjectAuthorized;
      this.ApprovedbyProjectAuthorizedRemark =
        params.ApprovedbyProjectAuthorizedRemark;
      this.ApprovedbyProjectAuthorizedDate =
        params.ApprovedbyProjectAuthorizedDate;

      this.ApprovedbySNEMECHRDept = params.ApprovedbySNEMECHRDept;
      this.ApprovedbySNEMECHRDeptRemark = params.ApprovedbySNEMECHRDeptRemark;
      this.ApprovedbySNEMECHRDeptDate = params.ApprovedbySNEMECHRDeptDate;

      this.ApprovedbySNEMECComplianceDept =
        params.ApprovedbySNEMECComplianceDept;
      this.ApprovedbySNEMECComplianceDeptRemark =
        params.ApprovedbySNEMECComplianceDeptRemark;
      this.ApprovedbySNEMECComplianceDeptDate =
        params.ApprovedbySNEMECComplianceDeptDate;

      this.ApprovedbySNEMECAuthorized = params.ApprovedbySNEMECAuthorized;
      this.ApprovedbySNEMECAuthorizedRemark =
        params.ApprovedbySNEMECAuthorizedRemark;
      this.ApprovedbySNEMECAuthorizedDate =
        params.ApprovedbySNEMECAuthorizedDate;
    },
    clearData() {
      this.ApplicationNumber = null;
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.PONO = null;
      this.DurationofUsage = null;
      this.DurationofUsageto = null;
      this.Supplier = null;
      this.SupplierKeyID = null;
      this.SupplierName = null;
      this.AbbreviationName = null;
      this.FirstParty = null;
      this.FirstPartyName = null;
      this.CRNOofSupplier = null;
      this.CRNOofContractor = null;
      this.CoordinatorName = null;
      this.CoordinatorIQAMA = null;
      this.MobileNumberofSupplier = null;
      this.EmailAddressofSupplier = null;
      this.Preparedby = null;
      this.MobileNumberofProject = null;
      this.EmailAddressofProject = null;
      this.Reason = null;
      this.subProjectList = [];
      this.Terms = [
        {
          ID: null,
          KeyID: null,
          MainKeyID: null,
          Contents: null,
        },
      ];
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }

      this.ApprovedbyManager = null;
      this.ApprovedbyManagerRemark = null;
      this.ApprovedbyManagerDate = null;

      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;

      this.ApprovedbySNEMECHRDept = null;
      this.ApprovedbySNEMECHRDeptRemark = null;
      this.ApprovedbySNEMECHRDeptRemark = null;

      this.ApprovedbySNEMECComplianceDept = null;
      this.ApprovedbySNEMECComplianceDeptRemark = null;
      this.ApprovedbySNEMECComplianceDeptDate = null;

      this.ApprovedbySNEMECAuthorized = null;
      this.ApprovedbySNEMECAuthorizedRemark = null;
      this.ApprovedbySNEMECAuthorizedDate = null;
    },
    changeCategory(index) {
      if (index === this.CostAllocationDetails.length - 1) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },

    changeProjectID() {
      this.SubProjectID = null;
      this.subProjectList = [];
      this.getProjectsChildren();
    },
    getProjectsChildren() {
      if (!this.ProjectID) {
        return;
      }
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    //岗位类别
    allPositionCategory().then((res) => {
      if (res.status === 200) {
        this.positionCategoryList = res.response;
      }
    });
    getPurchaseOrderApplicationSuppliers().then((res) => {
      if (res.status === 200) {
        this.purchaseOrderApplicationList = res.response;
      }
    });
    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.GovernmentList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
.textarea-box {
  min-height: 50px;
  line-height: 20px;
  font-size: 14px;
  color: #606266;
  border: none;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.sign {
  max-width: 200px;
}
</style>