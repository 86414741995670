<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="3">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="10">
            Cost Allocation of SNEMEC 中东公司费用分割单
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Cost Category<br />
            费用类型
          </td>
          <td class="text-center" colspan="4">
            {{ CostCategoryName }}
            {{ SubCostCategoryName ? ` / ${SubCostCategoryName}` : "" }}
          </td>
          <td class="text-center" colspan="3">Amount<br />费用金额</td>
          <td class="text-center" colspan="3">
            <div class="name">
              {{ Amount }}
            </div>
          </td>
          <td class="text-left">Currency SAR<br />金额单位：沙特里亚尔</td>
          <td class="text-center">
            <div class="name">Cost Duration</div>
            <div class="nameEn">费用期间</div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              {{ Duration | tableDateFrom }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="3">
            ITMSA NO.<br />
            借工协议编号
          </td>
          <td class="text-center" colspan="4" rowspan="3">
            <div class="name">
              {{ ITMSANO }}
            </div>
          </td>
          <td class="text-center" colspan="3">
            Company Name of Beneficiary <br />收款单位名称
          </td>
          <td class="text-center" colspan="4">/</td>
          <td class="text-center">
            CR NO. of Beneficiary <br />收款单位营业执照
          </td>
          <td class="text-center">/</td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Name of Bank Account<br />
            银行账户名称
          </td>
          <td class="text-center" colspan="3">
            Name of Bank<br />
            开户行名称
          </td>
          <td class="text-center">
            Bank Code<br />
            银行代码
          </td>
          <td class="text-center" colspan="2">
            IBAN Account<br />
            银行账号
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">/</td>
          <td class="text-center" colspan="3">/</td>
          <td class="text-center">/</td>
          <td class="text-center" colspan="2">/</td>
        </tr>
        <tr>
          <td class="text-center">
            S/N<br />
            序号
          </td>
          <td class="text-center">
            Sponsor<br />
            费用主体
          </td>
          <td class="text-center">
            Project<br />
            项目名称
          </td>
          <td class="text-center">
            SubProject Name<br />
            分项目名称
          </td>
          <td class="text-center">
            Unit<br />
            单位名称
          </td>
          <td class="text-center">
            Department<br />
            部门名称
          </td>
          <td class="text-center">
            Cost center<br />
            成本中心
          </td>
          <td class="text-center">
            Cost Center No.<br />
            成本中心编号
          </td>
          <td class="text-center">
            Qty.<br />
            数量
          </td>
          <td class="text-center">
            PO Rate<br />
            单价
          </td>
          <td class="text-center">
            Percentage<br />
            比例
          </td>
          <td class="text-center">
            Amount of Allocation<br />
            分割金额
          </td>
          <td class="text-center">
            Approval by Working Unit
            <br />
            单位确认
          </td>
          <td class="text-center">
            Approval by Project <br />
            项目部、分项目确认
          </td>
          <td class="text-center">
            Remark<br />
            说明
          </td>
        </tr>
       <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            <div class="name name-size">{{ index + 1 }}</div>
          </td>
          <td class="text-center">
            {{ item.Sponsor | typeFormater(SponsorList) }}
          </td>
          <td class="text-center">
            {{ item.ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            {{ item.SubProjectID | projectFormater(item.SubProjeList) }}
          </td>
          <td class="text-center">
            {{ item.CompanyID | companyFormater(companyList) }}
          </td>
          <td class="text-center">
            {{ item.DepartmentID | departmentFormater(departmentList) }}
          </td>
          <td class="text-center">
            {{ item.CostCenterID | costCenterFormater(item.CostCenterList) }}
          </td>
          <td class="text-center">{{ item.CostCenterCode }}</td>
          <td class="text-center">
            {{ item.Qty }}
          </td>
          <td class="text-center">
            {{ item.PORate }}
          </td>
          <td class="text-center">
            {{ item.Percentage }}
          </td>
          <td class="text-center">
            {{ Amount }}
          </td>
          <td class="text-center">
            {{ item.AdminDepartment }}
          </td>

          <td class="text-center">
            <div v-if="item.ProjectManager">
              <img class="signature" :src="item.ProjectManager" />
            </div>
          </td>
          <td class="text-center">
            {{ item.CostAllocationType | typeFormater(CostAllocationType) }}
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name name-size">10</div>
          </td>
          <td class="text-center" colspan="5">
            <div class="name name-wide">Total 合计</div>
          </td>
          <td class="text-center">
            <div class="name name-wide"></div>
          </td>
          <td class="text-center">
            <div class="name name-wide"></div>
          </td>
          <td class="text-center">
            <div class="name name-wide">{{ QtyAll }}</div>
          </td>
          <td class="text-center">
            <div class="name name-wide"></div>
          </td>
          <td class="text-center"></td>
          <td class="text-center">{{ AmountAll }}</td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">Note 说明</td>
          <td class="text-center" colspan="13">
            {{ Note }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">Approval Record<br />审批记录</td>
          <td class="text-title" colspan="7">
            <div class="title">
              Approved by SNEMC HR Department 中东公司人力资源部
            </div>
            <div class="img-box">
              <img class="img" :src="HRSuperintendent" />
            </div>
          </td>
          <td class="text-title" colspan="6">
            <div class="title">Approved by SNEMC Authorized 中东公司授权人</div>
            <div class="img-box">
              <img class="img" :src="ExecutiveDirector" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  addCostAllocation,
  editCostAllocation,
  allCostCenters,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getProjectsChildren,
  getCostCategoryTree,
} from "@/api/user";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },

    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    typeFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    costCenterFormater(value, data) {
      let name = "";
      data.forEach((item) => {
        if (item.Id === value) {
          name = item.Name;
        }
      });
      return name;
    },
    CostCategoryFormater(CostCategory, tree) {
      // console.log("CostCategory", CostCategory);
      // console.log("tree", tree);
      let name = "";
      if (CostCategory.length !== 0) {
        tree.forEach((item) => {
          if (item.ID === CostCategory[0]) {
            name = name + item.Name;
            let Children = item.Children;
            if (CostCategory.length === 2) {
              Children.forEach((cItem) => {
                if (cItem.ID === CostCategory[1]) {
                  name = name + "/" + cItem.Name;
                }
              });
            }
          }
        });
      }
      return name;
    },
  },
  data() {
    return {
      TableDate: null,
      ListerDept: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      departmentList: [],
      Amount: null, //费用金额
      Duration: null, //费用期间
      ITMSANO: null, //发表号
      BankInformation: null, //收款单位
      BankCode: null,
      BankAccountName: null, //银行名称
      BankName: null, // 开户行名称
      BankAccount: null, //银行账号
      CostAllocationDetails: [],
      CompanyNameofBeneficiary: null,
      CRNumberofBeneficiary: null,
      CostCategory: [],
      Note: null,
      CostAllocationType: [
        {
          value: 1,
          label: "分摊（+）Allocation",
        },
        {
          value: 2,
          label: "返还（-）Refund",
        },
      ],
      SponsorList: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      costAllocationItem: {
        ID: null,
        KeyID: null,
        CostAllocationID: 1,
        Sponsor: null,
        ProjectID: null,
        SubProjectID: null,
        CompanyID: null,
        DepartmentID: null,
        CostCenterID: null,
        CostCenterCode: "",
        CostCenterList: [],
        SubProjeList: [],
        Qty: 0,
        PORate: null,
        Percentage: null,
        Amount: null,
        CostAllocationType: null,
      },
      ID: null,
      KeyID: null,
      HROfficer: null,
      HRSuperintendent: null,
      ExecutiveDirector: null,
      tree: [],
      props: {
        value: "ID",
        label: "Name",
        children: "Children",
        checkStrictly: true,
      },
      SubCostCategoryName: null,
      CostCategoryName: null,
    };
  },

  computed: {
    // 计算属性的getter
    QtyAll() {
      let number = 0;
      this.CostAllocationDetails.forEach((item) => {
        number = number + item.Qty;
      });
      return number;
    },
    // 计算属性的getter
    AmountAll() {
      let number = 0;
      this.CostAllocationDetails.forEach((item) => {
        if (item.Amount) {
          number = number + parseFloat(item.Amount);
        }
      });
      return number;
    },
  },
  methods: {
    getDepartmentList(data1, data2, data3) {
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = getNewDate();
    },
    addCostAllocation(type, EditState, costAllocation) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        TypeID: type,
        CostCategory: this.CostCategory,
        Amount: this.Amount,
        Duration: this.Duration,
        ITMSANO: this.ITMSANO,
        CompanyNameofBeneficiary: this.CompanyNameofBeneficiary,
        CRNumberofBeneficiary: this.CRNumberofBeneficiary,
        BankCode: this.BankCode,
        BankAccountName: this.BankAccountName,
        BankName: this.BankName,
        BankAccount: this.BankAccount,
        ListerCompany: null,
        ListerDept: this.ListerDept,
        TableDate: this.TableDate,
        Currency: "SAR沙特里亚尔",
        Note: this.Note,
        EditState: EditState,
        Details: [],
        costAllocation: costAllocation,
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.ProjectID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      let isTrue = true;
      data.Details.forEach((item) => {
        if (!item.Sponsor) {
          isTrue = false;
        }
      });
      if (!isTrue) {
        this.$message.warning("费用主体不能为空");
        return;
      }

      if (!this.ID) {
        addCostAllocation(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editCostAllocation(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(data1, data2, data3, params) {
      console.log("params", params);
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = params.TableDate;
      this.ListerDept = params.ListerDept;
      this.CostCategoryName = params.CostCategoryName;
      this.Amount = params.Amount;
      this.Duration = params.Duration;
      this.ITMSANO = params.ITMSANO;
      this.BankInformation = params.BankInformation;
      this.BankAccountName = params.BankAccountName;
      this.BankName = params.BankName;
      this.BankAccount = params.BankAccount;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.HROfficer = params.HROfficer;
      this.HRSuperintendent = params.HRSuperintendent;
      this.ExecutiveDirector = params.ExecutiveDirector;
      this.ApplicationNumber = params.ApplicationNumber;
      this.CompanyNameofBeneficiary = params.CompanyNameofBeneficiary;
      this.CRNumberofBeneficiary = params.CRNumberofBeneficiary;
      this.BankCode = params.BankCode;
      this.Note = params.Note;
      this.SubCostCategoryName = params.SubCostCategoryName;
      this.CostCategory = params.CostCategoryIDs.split(",");
      this.CostCategory = this.CostCategory.map(Number);
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].CostAllocationType =
            params.Details[index].TypeID;
          Array[index] = JSON.parse(JSON.stringify(params.Details[index]));
          Array[index].CostCenterList = [];
          Array[index].SubProjeList = [];
          console.log("11111", this.CostAllocationDetails);
          allCostCenters({
            projectId: Array[index].ProjectID,
            companyId: Array[index].CompanyID,
            deptId: Array[index].DepartmentID,
          }).then((res) => {
            if (res.status === 200) {
              Array[index].CostCenterList = res.response;
              if (res.response.length === 1) {
                Array[index].CostCenterID = res.response[0].Id;
                Array[index].CostCenterCode = res.response[0].Code;
              }
            }
          });
          this.getProjectsChildren(index, Array[index].ProjectID);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("11111", this.CostAllocationDetails);
    },
    clearData() {
      this.TableDate = null;
      this.ListerDept = null;
      this.projectList = [];
      this.companyList = [];
      this.departmentList = [];
      this.CostCategoryName = null; //费用项目
      this.Amount = null; //费用金额
      this.Duration = null; //费用期间
      this.ITMSANO = null; //发表号
      this.BankInformation = null; //收款单位
      this.BankAccountName = null; //银行名称
      this.BankName = null; // 开户行名称
      this.BankAccount = null; //银行账号
      this.CostAllocationDetails = [];
      this.HROfficer = null;
      this.HRSuperintendent = null;
      this.ExecutiveDirector = null;
      this.ApplicationNumber = null;
      this.CostCategory = [];
      this.BankCode = null;
      this.CompanyNameofBeneficiary = null;
      this.CRNumberofBeneficiary = null;
      this.Note = null;
      for (let i = 0; i < 9; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.ID = null;
      this.KeyID = null;
    },
    getCostCenters(index, type) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );

      const data = {
        projectId: item.ProjectID,
        companyId: item.CompanyID,
        deptId: item.DepartmentID,
      };
      if (type !== 2) {
        item.SubProjectID = null;
        if (item.ProjectID) {
          this.getProjectsChildren(index, item.ProjectID);
        } else {
          this.SubProjeList = [];
        }
      }
      allCostCenters(data).then((res) => {
        if (res.status === 200) {
          item.CostCenterList = res.response;
          if (res.response.length === 0) {
            this.$message.warning("该选项下不存在成本中心，请重新选择");
          }
          if (res.response.length === 1) {
            item.CostCenterID = res.response[0].Id;
            item.CostCenterCode = res.response[0].Code;
          }
        }
      });
      this.CostAllocationDetails.splice(index, 1, item);
    },

    getProjectsChildren(index, id) {
      getProjectsChildren({ id }).then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          this.CostAllocationDetails[index].SubProjeList = res.response;
        }
      });
    },
    getCostCenterCode(index) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );
      item.CostCenterList.forEach((cItem) => {
        if (cItem.Id === item.CostCenterID) {
          item.CostCenterCode = cItem.Code;
        }
      });

      this.CostAllocationDetails.splice(index, 1, item);
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 9; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getCostCategoryTree().then((res) => {
      if (res.status === 200) {
        this.tree = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 40px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>