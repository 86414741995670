<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="customize-title1" colspan="15">
            中国石化境外公共安全培训报名审批
          </td>
          <td class="text-center">业务编号：</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center">序号</td>
          <td class="text-center">项目</td>
          <td class="text-center">分项目</td>
          <td class="text-center">单位</td>
          <td class="text-center">姓名</td>
          <td class="text-center">性别</td>
          <td class="text-center">护照号</td>
          <td class="text-center">身份证号</td>
          <td class="text-center">文化程度</td>
          <td class="text-center">岗位/工种</td>
          <td class="text-center">联系电话(手机)</td>
          <td class="text-center">身高</td>

          <td class="text-center">体重</td>
          <td class="text-center">鞋号</td>
          <td class="text-center">初/复训</td>

          <td class="text-center">最近一次培训证号</td>
          <td class="text-center">报名班次</td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            {{ item.ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            {{ item.SubProjectID | projectFormater(item.subProjectList) }}
          </td>
          <td class="text-center">
            {{ item.CompanyID | companyFormater(companyList) }}
          </td>
          <td class="text-center">
            {{ item.EmployeeName }}
          </td>
          <td class="text-center">
            {{ item.Gender | labelFormater(opinions1) }}
          </td>

          <td class="text-center">
            {{ item.PassportCode }}
          </td>
          <td class="text-center">
            {{ item.IDNumber }}
          </td>
          <td class="text-center">
            {{ item.Education | educationsFormater(Educations) }}
          </td>

          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">
            {{ item.ContactNumber }}
          </td>
          <td class="text-center">
            {{ item.Height }}
          </td>
          <td class="text-center">
            {{ item.Weight }}
          </td>
          <td class="text-center">
            {{ item.ShoeSize }}
          </td>
          <td class="text-center">
            {{ item.TypeID | labelFormater(opinions2) }}
          </td>
          <td class="text-center">
            {{ item.LastTrainingCode }}
          </td>
          <td class="text-center">
            {{ item.RegistrationDate }}
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">项目发起人</td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img :src="Applicant" />
            </div>
          </td>
          <td class="text-center" colspan="3">项目经理或授权人</td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img :src="ProjectManager" />
            </div>
          </td>
          <td class="text-center" colspan="3">中东公司外事负责人</td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  getProjectAll,
  getCompanyAll,
  getProjectsChildren,
  allPosition,
} from "@/api/user";
import {
  getGeneralBasic,
  getUsersCode,
  getTrainings,
  addRegistrationforPublicSafetyTrainingApplication,
  editInternalTemporaryManpowerApplicationsItem,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      if (data) {
        const item = data.find((item) => {
          return item.ProjectID === id;
        });
        return item ? item.ProjectName : "";
      }
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    companyFormater(id, data) {
      if (data) {
        const item = data.find((item) => {
          return item.ID === id;
        });
        return item ? item.CompanyCHS : null;
      }
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    educationsFormater(id, data) {
      if (data) {
        const item = data.find((item) => {
          return item.ID === id;
        });
        return item ? item.EduNameCHS : null;
      }
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      subProjectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      Educations: [],
      ID: null,
      KeyID: null,
      ApplicationNumber: null,
      Date: null,
      Remark: null,
      Applicant: null,
      ApplicantRemark: null,
      ApplicantDate: null,
      ProjectManager: null,
      ProjectManagerRemark: null,
      ProjectManagerDate: null,
      ApprovedbySNEMCAuthorized: null,
      ApprovedbySNEMCAuthorizedRemark: null,
      ApprovedbySNEMCAuthorizedDate: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      Remark: null,
      EditState: 1,
      evacuationDate: [],
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        ProjectID: null,
        ProjectName: null,
        SubProjectID: null,
        SubProjectName: null,
        CompanyID: null,
        WorkingUnit: null,
        EmployeeID: null,
        EmployeeCode: null,
        EmployeeName: null,
        EmployeeNameEN: null,
        Gender: null,
        Nationality: null,
        NationalityName: null,
        IQAMAID: null,
        PassportCode: null,
        IDNumber: null,
        Education: null,
        EducationName: null,
        JobProfession: null,
        JobProfessionName: null,
        ContactNumber: null,
        Height: null,
        Weight: null,
        ShoeSize: null,
        TypeID: null,
        LastTrainingCode: null,
        RegistrationDate: null,
        subProjectList: [],
      },
      opinions1: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 2,
        },
      ],
      opinions2: [
        {
          label: "初训",
          value: 1,
        },
        {
          label: "复训",
          value: 2,
        },
      ],
    };
  },
  methods: {
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ApplicationNumber: this.ApplicationNumber,
        Date: this.Date,
        Remark: this.Remark,
        Applicant: this.Applicant,
        ApplicantRemark: this.ApplicantRemark,
        ApplicantDate: this.ApplicantDate,
        ProjectManager: this.ApplicantDate,
        ProjectManagerRemark: this.ProjectManagerRemark,
        ProjectManagerDate: this.ProjectManagerDate,
        ApprovedbySNEMCAuthorized: this.ApprovedbySNEMCAuthorized,
        ApprovedbySNEMCAuthorizedRemark: this.ApprovedbySNEMCAuthorizedRemark,
        ApprovedbySNEMCAuthorizedDate: this.ApprovedbySNEMCAuthorizedDate,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        Remark: this.Remark,
        EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.ProjectID && item.PassportCode) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (!this.ID) {
        addRegistrationforPublicSafetyTrainingApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editInternalTemporaryManpowerApplicationsItem(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      this.ApplicationNumber = params.ApplicationNumber;
      this.TableDate = params.CreateDate;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ApplicationNumber = params.ApplicationNumber;
      this.Date = params.Date;
      this.Remark = params.Remark;
      this.Applicant = params.Applicant;
      this.ApplicantRemark = params.ApplicantRemark;
      this.ApplicantDate = params.ApplicantDate;
      this.ProjectManager = params.ProjectManager;
      this.ProjectManagerRemark = params.ProjectManagerRemark;
      this.ProjectManagerDate = params.ProjectManagerDate;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
      this.ApprovedbySNEMCAuthorizedRemark =
        params.ApprovedbySNEMCAuthorizedRemark;
      this.ApprovedbySNEMCAuthorizedDate = params.ApprovedbySNEMCAuthorizedDate;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      params.Details.forEach((item, index, Array) => {
        if (params.Details[index]) {
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails.forEach((item, index) => {
        if (item.ProjectID) {
          this.getProjectsChildren(item.ProjectID, index);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.ApplicationNumber = null;
      this.TableDate = null;
      this.ID = null;
      this.KeyID = null;
      this.FirstProject = null;
      this.SecondProject = null;
      this.FirstCompany = null;
      this.SecondCompany = null;
      this.ITMSANO = null;
      this.DurationofSupply = null;
      this.DurationofSupplyto = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.subProjectList = [];
      this.Terms = [
        {
          ID: null,
          KeyID: null,
          MainKeyID: null,
          Contents: null,
        },
      ];
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }

      this.ApprovedbyManager = null;
      this.ApprovedbyManagerRemark = null;
      this.ApprovedbyManagerDate = null;

      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;

      this.ApprovedbySNEMECHRDept = null;
      this.ApprovedbySNEMECHRDeptRemark = null;
      this.ApprovedbySNEMECHRDeptRemark = null;

      this.ApprovedbySNEMECAuthorized = null;
      this.ApprovedbySNEMECAuthorizedRemark = null;
      this.ApprovedbySNEMECAuthorizedDate = null;
    },

    //通过护照读取数据
    async changePassportCode(index) {
      const value = this.CostAllocationDetails[index].PassportCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 1 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (userInfo) {
        console.log("userInfo", userInfo);
        this.CostAllocationDetails[index].ProjectID = userInfo.ProjectID;
        this.CostAllocationDetails[index].SubProjectID = userInfo.SubProjectID;
        this.CostAllocationDetails[index].CompanyID = userInfo.CompanyID;
        this.CostAllocationDetails[index].EmployeeName = userInfo.ChineseName;
        this.CostAllocationDetails[index].Gender = userInfo.Gender;
        this.CostAllocationDetails[index].IDNumber = userInfo.IDNumber;
        this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
        this.getProjectsChildren(userInfo.ProjectID, index);
        getTrainings({ ppCode: value }).then((res) => {
          if (res.status === 200) {
            if (res.response && res.response.length !== 0)
              this.CostAllocationDetails[index].LastTrainingCode =
                res.response[0].LastTrainingCode;
          }
        });
      }
    },
    changeProjectID(index) {
      this.CostAllocationDetails[index].SubProjectID = null;
      this.CostAllocationDetails[index].subProjectList = [];
      if (this.CostAllocationDetails[index].ProjectID) {
        this.getProjectsChildren(
          this.CostAllocationDetails[index].ProjectID,
          index
        );
      }
    },
    getProjectsChildren(id, index) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.CostAllocationDetails[index].subProjectList = res.response;
          this.CostAllocationDetails = this.CostAllocationDetails.concat();
        }
      });
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.Educations = res.response.Educations;
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
</style>