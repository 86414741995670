<template>
  <div class="main">
    <div id="target-element">
      <ManpoweTable ref="manpoweTable" v-if="type == 1"></ManpoweTable>
      <ApprovalTable ref="approvalTable" v-if="type == 2"></ApprovalTable>
      <UnitPrice ref="unitPrice" v-if="type == 3"></UnitPrice>
      <CostTable
        ref="costTable"
        v-if="
          type == 4 ||
          type == 5 ||
          type == 6 ||
          type == 19 ||
          type == 20 ||
          type == 21 ||
          type == 22
        "
      ></CostTable>
      <Leave ref="leave" :CreateDate="CreateDate" v-if="type == 7"></Leave>
      <Overtime
        ref="overtime"
        :CreateDate="CreateDate"
        v-if="type == 8"
      ></Overtime>
      <MLeave ref="mLeave" :CreateDate="CreateDate" v-if="type == 9"></MLeave>
      <MOvertime
        ref="mOvertime"
        :CreateDate="CreateDate"
        v-if="type == 10"
      ></MOvertime>
      <PlaneTicket ref="paneTicket" v-if="type == 11"></PlaneTicket>
      <Invite ref="invite" v-if="type == 12 || type == 29"></Invite>
      <Visa ref="visa" v-if="type == 13"></Visa>
      <VILTable ref="vilTable" v-if="type == 14"></VILTable>
      <TicketPurchase ref="ticketPurchase" v-if="type == 15"></TicketPurchase>
      <TicketChange ref="ticketChange" v-if="type == 16"></TicketChange>
      <Ljsoos ref="ljsoos" v-if="type == 17"></Ljsoos>
      <InternalBorrowingAgreement
        ref="internalBorrowingAgreement"
        v-if="type == 18"
      ></InternalBorrowingAgreement>
      <InternalBorrowingAgreementCostTable
        ref="internalBorrowingAgreementCostTable"
        v-if="type == 23"
      ></InternalBorrowingAgreementCostTable>
      <OverseasWorksApplications
        ref="overseasWorksApplication"
        v-if="type == 24"
      ></OverseasWorksApplications>
      <OverseasPublicSafetyTraining
        ref="overseasPublicSafetyTraining"
        v-if="type == 25"
      ></OverseasPublicSafetyTraining>
      <ManpowerSupplierMonthlyCostApplication
        ref="manpowerSupplierMonthlyCostApplication"
        v-if="type == 26"
      ></ManpowerSupplierMonthlyCostApplication>
      <ForeignAffairsBusiness
        ref="foreignAffairsBusiness"
        v-if="type == 27 || type == 28"
      ></ForeignAffairsBusiness>
      <EmployeeMedicalInsuranceRegistration
        ref="employeeMedicalInsuranceRegistration"
        v-if="type == 30"
      ></EmployeeMedicalInsuranceRegistration>
      <EmployeeMedicalInsuranceAdjustmentmentApplication
        ref="employeeMedicalInsuranceAdjustmentmentApplication"
        v-if="type == 31"
      ></EmployeeMedicalInsuranceAdjustmentmentApplication>
      <WorkVisaApplication
        ref="workVisaApplication"
        v-if="type == 32 || type == 33 || type == 34"
      ></WorkVisaApplication>
      <PositionAdjustmentApplicantion
        ref="positionAdjustmentApplicantion"
        v-if="type == 35"
      ></PositionAdjustmentApplicantion>
    </div>
    <div class="buttons">
      <el-button plain icon="el-icon-back" @click="goUrl">返回</el-button>
      <el-button
        type="primary"
        @click="generateImage2"
        icon="el-icon-takeaway-box"
        v-if="export1"
        plain
        >导出</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-printer"
        plain
        v-print="'#target-element'"
        v-if="print"
      >
        打印</el-button
      >
    </div>
  </div>
</template>
<script>
import ManpoweTable from "@/components/tables/manpoweTable2.vue";
import ApprovalTable from "@/components/tables/approvalTable2.vue";
import CostTable from "@/components/tables/costTable2.vue";
import UnitPrice from "@/components/tables/unitPrice2.vue";
import Leave from "@/components/tables/leave2.vue";
import Overtime from "@/components/tables/overtime2.vue";
import MLeave from "@/components/tables/mLeave2.vue";
import MOvertime from "@/components/tables/mOvertime2.vue";
import PlaneTicket from "@/components/tables/planeTicket2.vue"; //机票撤离
import Invite from "@/components/tables/invite2.vue"; //邀请函
import Visa from "@/components/tables/visa2.vue";
import VILTable from "@/components/tables/vILTable2.vue";
import TicketPurchase from "@/components/tables/ticketPurchase2.vue";
import TicketChange from "@/components/tables/ticketChange2.vue";
import Ljsoos from "@/components/tables/ljsoos2.vue";
import ManpowerSupplierMonthlyCostApplication from "@/components/tables/manpowerSupplierMonthlyCostApplication2.vue";

import InternalBorrowingAgreement from "@/components/fTable/internalBorrowingAgreement2.vue"; //内部借工协议
import OverseasWorksApplications from "@/components/fTable/overseasWorksApplications2.vue"; //出国境任务
import OverseasPublicSafetyTraining from "@/components/fTable/overseasPublicSafetyTraining2.vue"; //境外公共安全培训
import ForeignAffairsBusiness from "@/components/fTable/ForeignAffairsBusiness2.vue";
import InternalBorrowingAgreementCostTable from "@/components/fTable/internalBorrowingAgreementCostTable2.vue";
import EmployeeMedicalInsuranceRegistration from "@/components/fTable/employeeMedicalInsuranceRegistration2.vue";
import EmployeeMedicalInsuranceAdjustmentmentApplication from "@/components/fTable/employeeMedicalInsuranceAdjustmentmentApplication2.vue";
import WorkVisaApplication from "@/components/fTable/workVisaApplication2.vue";
import PositionAdjustmentApplicantion from "@/components/fTable/positionAdjustmentApplicantion2";
import html2canvas from "html2canvas";
import {
  getCostAllocationItem,
  getManpowerRequestItem,
  getManpowerSupplierItem,
  getManpowerRecruitmentHourlyRateItem,
} from "@/api/user";
import {
  getDemobilizationApplicationsItem,
  getDemobilizationApplicationsItem2,
  getInvitationLetterApplicationItem,
  getEmployeeVisaApplicationsItem,
  getInvitationLetterApplicationItem2,
  getFlightTicketApplicationsItem,
  getFlightTicketApplicationsItem2,
  getFlightTicketRescheduleApplicationItem,
  getFlightTicketRescheduleApplicationItem2,
  getPurchaseOrderApplicationItem,
  getInternalTemporaryManpowerApplicationsItem,
  getOverseasWorksApplicationsItem,
  getRegistrationforPublicSafetyTrainingApplicationsItem,
  getManpowerSupplierMonthlyCostApplicationDetailsItem,
  getTransferSponsorApplicationsItem,
  getVistVisaofNonWorkingCountryApplicationsItem,
  getInvitationLetterApplicationItem22,
  getEmployeeMedicalInsuranceRegistrationApplicationsItem,
  getEmployeeMedicalInsuranceAdjustmentmentApplicationsItem,
  getWorkVisaApplicationsItem,
  getPositionAdjustmentApplicantionsItem,
  getPositionAdjustmentApplicantionsItem2,
} from "@/api/table";

import html2Canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
  data() {
    return {
      type: null,
      print: null,
      export1: null,
      CreateDate: "",
      name: "pdf报告",
      ordinary: null,
    };
  },
  components: {
    ManpoweTable,
    ApprovalTable,
    CostTable,
    UnitPrice,
    Leave,
    Overtime,
    MLeave,
    MOvertime,
    PlaneTicket,
    Invite,
    Visa,
    VILTable,
    TicketPurchase,
    TicketChange,
    Ljsoos,
    InternalBorrowingAgreement,
    OverseasWorksApplications,
    OverseasPublicSafetyTraining,
    ManpowerSupplierMonthlyCostApplication,
    ForeignAffairsBusiness,
    InternalBorrowingAgreementCostTable,
    EmployeeMedicalInsuranceRegistration,
    EmployeeMedicalInsuranceAdjustmentmentApplication,
    WorkVisaApplication,
    PositionAdjustmentApplicantion,
  },
  methods: {
    goUrl() {
      switch (this.type) {
        case "1":
          this.$router.push("/module/manpower");
          break;
        case "2":
          this.$router.push("/module/outsourcingApproval");
          break;
        case "3":
          this.$router.push("/module/oInformation");
          break;
        case "4":
          this.$router.push("/module/cost1");
          break;
        case "5":
          this.$router.push("/module/cost2");
          break;
        case "6":
          this.$router.push("/module/cost3");
          break;
        case "7":
          this.$router.push("/ordinary/leave");
          break;
        case "8":
          this.$router.push("/ordinary/overtime");
          break;
        case "9":
          this.$router.push("/module/leave");
          break;
        case "10":
          this.$router.push("/module/overtime");
          break;
        case "11":
          if (this.ordinary) {
            this.$router.push("/ordinary/evacuate");
          } else {
            this.$router.push("/module/planeTicket");
          }
          break;
        case "12":
          this.$router.push("/module/invite");
          break;
        case "13":
          this.$router.push("/module/visaApproval");
          break;
        case "14":
          this.$router.push("/module/visitInvitationLetter");
          break;
        case "15":
          if (this.ordinary) {
            this.$router.push("/ordinary/ticketRequisition");
          } else {
            this.$router.push("/module/ticketPurchase");
          }
          break;
        case "16":
          if (this.ordinary) {
            this.$router.push("/ordinary/ticketChange");
          } else {
            this.$router.push("/module/ticketChange");
          }
          break;
        case "17":
          this.$router.push("/module/ljsoo");
          break;
        case "18":
          this.$router.push("/module/internalBorrowingAgreement");
          break;
        case "19":
          this.$router.push("/module/splitAirfareCosts");
          break;
        case "20":
          this.$router.push("/module/SHICost");
          break;
        case "21":
          this.$router.push("/module/GOSICost");
          break;
        case "22":
          this.$router.push("/module/EOSBCost");
          break;
        case "23":
          this.$router.push("/module/internalBorrowingAgreementCost");
          break;
        case "24":
          this.$router.push("/module/overseasMission");
          break;
        case "25":
          this.$router.push("/module/overseasPublicSafetyTraining");
          break;
        case "26":
          this.$router.push("/module/monthlyCredit");
          break;
        case "27":
          this.$router.push("/module/sponsorChange");
          break;
        case "28":
          this.$router.push("/module/thirdCountryVisaApplication");
          break;
        case "29":
          this.$router.push("/module/foreignVisitsToChina");
          break;
        case "30":
          this.$router.push("/module/employeeMedicalInsuranceRegistration");
          break;
        case "31":
          this.$router.push(
            "/module/EmployeeMedicalInsuranceAdjustmentmentApplication"
          );
          break;
        case "32":
          this.$router.push("/module/workVisaApplication1");
          break;
        case "33":
          this.$router.push("/module/workVisaApplication2");
          break;
        case "34":
          this.$router.push("/module/workVisaApplication3");
          break;
        case "35":
          if (this.ordinary) {
            this.$router.push("/ordinary/positionAdjustmentApplicantion");
          } else {
            this.$router.push("/module/positionAdjustmentApplicantion");
          }
          break;
      }
    },
    generateImage() {
      const element = document.getElementById("target-element"); // 替换为目标元素的id或class
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = "image.png";
        link.click();
      });
    },
    generateImage2() {
      setTimeout(() => {
        let content_html = document.getElementById("target-element");
        let scale = 2;
        let opts = {
          scrollY: 0,
          scrollX: 0,
          scale: 2,
          background: "#fff",
          dpi: 192, //导出pdf清晰度
          useCORS: true,
        };
        html2Canvas(content_html, opts).then((canvas) => {
          var contentWidth = canvas.width;
          var contentHeight = canvas.height;
          //一页pdf显示html页面生成的canvas高度;
          var pageHeight = (contentWidth / 592.28) * 841.89;
          //未生成pdf的html页面高度
          var leftHeight = contentHeight;
          //pdf页面偏移
          var position = 0;
          //html页面生成的canvas在pdf中图片的宽高（a4纸的尺寸[595.28,841.89]）
          var imgWidth = 595.28;
          var imgHeight = (592.28 / contentWidth) * contentHeight;
          var pageData = canvas.toDataURL("image/jpeg", 1.0);
          var pdf = new jsPDF("", "pt", "a4");
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              //避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          pdf.save(`${this.name}.pdf`);
        });
      }, 500);
    },
    //表单详情
    getCostAllocationItem(key, costAllocation) {
      getCostAllocationItem({ key, costAllocation }).then((res) => {
        if (res.status === 200) {
          this.$refs.costTable.geteditData(
            res.extend.Projects,
            res.extend.Companies,
            res.extend.Departments,
            res.response
          );
        }
      });
    },
    getCostAllocationItem23(key, costAllocation) {
      getCostAllocationItem({ key, costAllocation }).then((res) => {
        if (res.status === 200) {
          this.$refs.internalBorrowingAgreementCostTable.geteditData(
            res.extend.Projects,
            res.extend.Companies,
            res.extend.Departments,
            res.response
          );
        }
      });
    },
    getCostAllocationItem2(key, costAllocation) {
      getCostAllocationItem({ key, costAllocation }).then((res) => {
        if (res.status === 200) {
          this.$refs.leave.getEditData(res.response);
        }
      });
    },
    getCostAllocationItem3(key, costAllocation) {
      getCostAllocationItem({ key, costAllocation }).then((res) => {
        if (res.status === 200) {
          this.$refs.overtime.getEditData(res.response);
        }
      });
    },
    getCostAllocationItem4(key, costAllocation) {
      getCostAllocationItem({ key, costAllocation }).then((res) => {
        if (res.status === 200) {
          this.$refs.mLeave.getEditData(res.response);
        }
      });
    },
    getCostAllocationItem5(key, costAllocation) {
      getCostAllocationItem({ key, costAllocation }).then((res) => {
        if (res.status === 200) {
          this.$refs.mOvertime.getEditData(res.response);
        }
      });
    },
    getManpowerRequestItem(key) {
      getManpowerRequestItem({ key }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$refs.manpoweTable.getEditData(
              JSON.parse(localStorage.getItem("projectList")),
              JSON.parse(localStorage.getItem("departmentList")),
              JSON.parse(localStorage.getItem("positionList")),
              res.response
            );
          }, 1);
        }
      });
    },
    getManpowerSupplierItem(key) {
      getManpowerSupplierItem({ key }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$refs.approvalTable.getManpowerSupplierData(res.response);
          }, 1);
        }
      });
    },
    getManpowerRecruitmentHourlyRateItem(key) {
      getManpowerRecruitmentHourlyRateItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.unitPrice.getEditData(
            JSON.parse(localStorage.getItem("departmentList")),
            JSON.parse(localStorage.getItem("positionList")),
            res.response
          );
        }
      });
    },
    //获取详情
    getDemobilizationApplicationsItem(key) {
      if (this.ordinary) {
        getDemobilizationApplicationsItem2({ key }).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$refs.paneTicket.geteditData(res.response);
            }, 1000);
          }
        });
      } else {
        getDemobilizationApplicationsItem({ key }).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$refs.paneTicket.geteditData(res.response);
            }, 1000);
          }
        });
      }
    },
    getInvitationLetterApplicationItem(key) {
      getInvitationLetterApplicationItem({ key }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$refs.invite.geteditData(res.response);
          }, 1000);
        }
      });
    },
    //获取详情
    getEmployeeVisaApplicationsItem(key) {
      getEmployeeVisaApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.visa.geteditData([], [], [], res.response);
        }
      });
    },
    //获取详情
    getInvitationLetterApplicationItem2(key) {
      getInvitationLetterApplicationItem2({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.vilTable.geteditData(res.response);
        }
      });
    },

    //表单详情
    getFlightTicketApplicationsItem(key) {
      if (this.ordinary) {
        getFlightTicketApplicationsItem2({
          key,
        }).then((res) => {
          if (res.status === 200) {
            this.$refs.ticketPurchase.geteditData(res.response);
          }
        });
      } else {
        getFlightTicketApplicationsItem({
          key,
        }).then((res) => {
          if (res.status === 200) {
            this.$refs.ticketPurchase.geteditData(res.response);
          }
        });
      }
    },
    getFlightTicketRescheduleApplicationItem(key) {
      if (this.ordinary) {
        getFlightTicketRescheduleApplicationItem2({
          key,
        }).then((res) => {
          if (res.status === 200) {
            this.$refs.ticketChange.getEditData(res.response);
          }
        });
      } else {
        getFlightTicketRescheduleApplicationItem({
          key,
        }).then((res) => {
          if (res.status === 200) {
            this.$refs.ticketChange.getEditData(res.response);
          }
        });
      }
    },
    //获取详情
    getPurchaseOrderApplicationItem(key) {
      getPurchaseOrderApplicationItem({ key: key }).then((res) => {
        if (res.status === 200) {
          this.$refs.ljsoos.geteditData(res.response);
        }
      });
    },
    //获取详情
    getInternalTemporaryManpowerApplicationsItem(key) {
      getInternalTemporaryManpowerApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$refs.internalBorrowingAgreement.geteditData(res.response);
          }, 1000);
        }
      });
    },
    //获取详情
    getOverseasWorksApplicationsItem(key) {
      getOverseasWorksApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.overseasWorksApplication.geteditData(res.response);
        }
      });
    },
    //获取详情
    getRegistrationforPublicSafetyTrainingApplicationsItem(key) {
      getRegistrationforPublicSafetyTrainingApplicationsItem({ key }).then(
        (res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$refs.overseasPublicSafetyTraining.geteditData(res.response);
            }, 1000);
          }
        }
      );
    },
    getManpowerSupplierMonthlyCostApplicationDetailsItem(key) {
      getManpowerSupplierMonthlyCostApplicationDetailsItem({ key }).then(
        (res) => {
          if (res.status === 200) {
            this.$refs.manpowerSupplierMonthlyCostApplication.getEditData(
              res.response
            );
          }
        }
      );
    },
    //获取详情
    getTransferSponsorApplicationsItem(key) {
      getTransferSponsorApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.foreignAffairsBusiness.geteditData(res.response);
        }
      });
    },
    getVistVisaofNonWorkingCountryApplicationsItem(key) {
      getVistVisaofNonWorkingCountryApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.foreignAffairsBusiness.geteditData(res.response);
        }
      });
    },
    getInvitationLetterApplicationItem22(key) {
      getInvitationLetterApplicationItem22({ key }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$refs.invite.geteditData(res.response);
          }, 1000);
        }
      });
    }, //获取详情
    getEmployeeMedicalInsuranceRegistrationApplicationsItem(key) {
      getEmployeeMedicalInsuranceRegistrationApplicationsItem({ key }).then(
        (res) => {
          if (res.status === 200) {
            this.$refs.employeeMedicalInsuranceRegistration.geteditData(
              res.response
            );
          }
        }
      );
    }, //获取详情
    getEmployeeMedicalInsuranceAdjustmentmentApplicationsItem(key) {
      getEmployeeMedicalInsuranceAdjustmentmentApplicationsItem({ key }).then(
        (res) => {
          if (res.status === 200) {
            this.$refs.employeeMedicalInsuranceAdjustmentmentApplication.geteditData(
              res.response
            );
          }
        }
      );
    },
    //获取详情
    getWorkVisaApplicationsItem(key) {
      getWorkVisaApplicationsItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.workVisaApplication.geteditData(res.response);
        }
      });
    },
    //获取详情
    getPositionAdjustmentApplicantionsItem(key) {
      if (this.ordinary) {
        getPositionAdjustmentApplicantionsItem2({ key }).then((res) => {
          if (res.status === 200) {
            this.$refs.positionAdjustmentApplicantion.geteditData(res.response);
          }
        });
      } else {
        getPositionAdjustmentApplicantionsItem({ key }).then((res) => {
          if (res.status === 200) {
            this.$refs.positionAdjustmentApplicantion.geteditData(res.response);
          }
        });
      }
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.print = this.$route.query.print;
    this.export = this.$route.query.export;
    this.ordinary = this.$route.query.ordinary;
    if (this.print === "true") {
      this.print = true;
    } else {
      this.print = false;
    }
    if (this.export === "true") {
      this.export1 = true;
    } else {
      this.export1 = false;
    }
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
    const type = parseInt(this.type);
    switch (type) {
      case 1:
        this.getManpowerRequestItem(this.$route.query.key);
        break;
      case 2:
        this.getManpowerSupplierItem(this.$route.query.key);
        break;
      case 3:
        this.getManpowerRecruitmentHourlyRateItem(this.$route.query.key);
        break;
      case 4:
      case 5:
      case 6:
      case 19:
      case 20:
      case 21:
      case 22:
        this.getCostAllocationItem(
          this.$route.query.key,
          this.$route.query.costAllocation
        );
        break;
      case 23:
        this.getCostAllocationItem23(
          this.$route.query.key,
          this.$route.query.costAllocation
        );
        break;
      case 7:
        this.getCostAllocationItem2(
          this.$route.query.key,
          "employee/leaveApplication"
        );
        this.CreateDate = this.$route.query.CreateDate;
        break;
      case 8:
        this.getCostAllocationItem3(
          this.$route.query.key,
          "employee/overtimeWorkingApplication"
        );
        this.CreateDate = this.$route.query.CreateDate;
        break;
      case 9:
        this.getCostAllocationItem4(
          this.$route.query.key,
          "EmployeeLeaveApplication"
        );
        this.CreateDate = this.$route.query.CreateDate;
        break;
      case 10:
        this.getCostAllocationItem5(
          this.$route.query.key,
          "EmployeeOvertimeWorkingApplication"
        );
        this.CreateDate = this.$route.query.CreateDate;
        break;
      case 11:
        this.getDemobilizationApplicationsItem(this.$route.query.key);
        break;
      case 12:
        this.getInvitationLetterApplicationItem(this.$route.query.key);
        break;
      case 13:
        this.getEmployeeVisaApplicationsItem(this.$route.query.key);
        break;
      case 14:
        this.getInvitationLetterApplicationItem2(this.$route.query.key);
        break;
      case 15:
        this.getFlightTicketApplicationsItem(this.$route.query.key);
        break;
      case 16:
        this.getFlightTicketRescheduleApplicationItem(this.$route.query.key);
        break;
      case 17:
        this.getPurchaseOrderApplicationItem(this.$route.query.key);
        break;
      case 18:
        this.getInternalTemporaryManpowerApplicationsItem(
          this.$route.query.key
        );
        break;
      case 24:
        this.getOverseasWorksApplicationsItem(this.$route.query.key);
        break;
      case 25:
        this.getRegistrationforPublicSafetyTrainingApplicationsItem(
          this.$route.query.key
        );
        break;

      case 26:
        this.getManpowerSupplierMonthlyCostApplicationDetailsItem(
          this.$route.query.key
        );
        break;
      case 27:
        this.getTransferSponsorApplicationsItem(this.$route.query.key);
        break;
      case 28:
        this.getVistVisaofNonWorkingCountryApplicationsItem(
          this.$route.query.key
        );
        break;
      case 29:
        this.getInvitationLetterApplicationItem22(this.$route.query.key);
        break;
      case 30:
        this.getEmployeeMedicalInsuranceRegistrationApplicationsItem(
          this.$route.query.key
        );
        break;
      case 31:
        this.getEmployeeMedicalInsuranceAdjustmentmentApplicationsItem(
          this.$route.query.key
        );
        break;
      case 32:
      case 33:
      case 34:
        this.getWorkVisaApplicationsItem(this.$route.query.key);
        break;
      case 35:
        this.getPositionAdjustmentApplicantionsItem(this.$route.query.key);
        break;
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;

  #target-element {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    // height: 26000px;
    box-sizing: border-box;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    .el-button {
      width: 30%;
    }
  }
}
</style>