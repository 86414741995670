<template>
  <div class="customize-main">
    <div class="right-title">
      Date of Application 申请日期：{{ CreateDate }}
    </div>
    <table
      class="customize-form"
      v-if="departmentList && companyList && departmentList && projectList"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr>
          <td class="text-center" colspan="2">
            <img class="tbale-img1" src="@/assets/image/table/table-img2.jpg" />
          </td>
          <td class="text-center" colspan="6">
            <h1>中东公司员工加班审批表</h1>
            <h1>Employee Overtime Working Application</h1>
          </td>
          <td class="text-center">
            <p>S/N:{{ ApplicationNumber }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">Project Name</div>
            <div class="nameEn">项目名称</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ ProjectID | projectFormater(projectList) }}
            </div>
          </td>
          <td class="text-center">
            <div class="name">Sub Project Name</div>
            <div class="nameEn">分项目名称</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ SubProjectID | projectFormater(projectList) }}
            </div>
          </td>
          <td class="text-center">
            <div class="name">Working Unit</div>
            <div class="nameEn">用工单位</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ CompanyID | companyFormater(companyList) }}
            </div>
          </td>
          <td class="text-center">
            <div class="name">Working Department</div>
            <div class="nameEn">部门名称</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ DepartmentID | departmentFormater(departmentList) }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">S/N</div>
            <div class="nameEn">序号</div>
          </td>
          <td class="text-center">
            <div class="name">Employee ID</div>
            <div class="nameEn">员工编码</div>
          </td>
          <td class="text-center">
            <div class="name">Employee Name</div>
            <div class="nameEn">员工姓名</div>
          </td>
          <td class="text-center">
            <div class="name">Job Profession</div>
            <div class="nameEn">工作岗位</div>
          </td>
          <td class="text-center">
            <div class="name">Overtime Date</div>
            <div class="nameEn">加班日期</div>
          </td>
          <td class="text-center">
            <div class="name">Start Time</div>
            <div class="nameEn">加班起始时间</div>
          </td>
          <td class="text-center">
            <div class="name">End Time</div>
            <div class="nameEn">加班结束时间</div>
          </td>
          <td class="text-center" colspan="2">
            <div class="name">Description of overtime work</div>
            <div class="nameEn">加班工作内容描述</div>
          </td>
        </tr>

        <tr v-for="(item, index) in Details" :key="index">
          <td class="text-center">
            <div class="name name-small">{{ index + 1 }}</div>
          </td>
          <td class="text-center">
            <div class="name name-small">{{ item.EmployeeCode }}</div>
          </td>
          <td class="text-center">
            <div class="name name-small">{{ item.EmployeeName }}</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ item.JobProfession | positionFormater(positionList) }}
            </div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ item.OvertimeDate }}
            </div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ item.StartTime }}
            </div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ item.EndTime }}
            </div>
          </td>
          <td class="text-center" colspan="2">
            <div class="name name-small">
              {{ item.DescriptionOfOvertimeWork }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size" colspan="2">
            <div class="name">Note说明</div>
          </td>
          <td class="text-left text-size" colspan="7">
            <div class="note-box">
              <div>
                Employee Overtime Working Application shall be completed before
                overtime work and cannot be supplemented later.
              </div>
              <div>加班审批表须在加班之前完成审批，不得后补。</div>
              <div>
                If the project/unit uniformly arranges overtime work, the
                project/unit can submit an application form at one time,
                indicating the scope of overtime personnel, overtime date and
                time.
              </div>
              <div>
                项目部/单位统一安排加班的，可由项目部/单位一次性提报申请表，说明加班人员范围、加班日期和时间。
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size" colspan="2">
            <div class="name">Approved by Manager</div>
            <div class="name">单位/部门领导意见</div>
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input type="textarea" :rows="2" placeholder="请输入内容">
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="false" :src="RequestDepartment" />
              </div>
              <div><span>日期Date：</span><span></span></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size" colspan="2">
            <div class="name">Approved by SNEMC/Project Authorized</div>
            <div class="name">中东公司或项目授权人审批</div>
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input type="textarea" :rows="2" placeholder="请输入内容">
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="false" :src="RequestDepartment" />
              </div>
              <div><span>日期Date：</span><span></span></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size" colspan="2">
            <div class="name">HR/Admin. Verification</div>
            <div class="name">人力资源部登记备案</div>
          </td>
          <td class="text-left text-size" colspan="7">
            <el-input type="textarea" :rows="2" placeholder="请输入内容">
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="false" :src="RequestDepartment" />
              </div>
              <div><span>日期Date：</span><span></span></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  addOvertime2,
  editOvertime2,
  getProjectAll,
  getDepartmentAll,
  allPosition,
  getCompanyAll,
  getProjectsChildren,
  getUsersCode,
} from "@/api/user";
import { jsBezier } from "../ef/jsplumb";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
  },
  props: {
    CreateDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      projectList: [],
      subProjectList: [],
      departmentList: [],
      positionList: [],
      companyList: [],
      ID: null,
      KeyID: null,
      ProjectID: null,
      ProjectName: null,
      SubProjectID: null,
      SubProjectName: null,
      CompanyID: null,
      WorkingUnit: null,
      DepartmentID: null,
      WorkingDepartment: null,
      Note: null,
      EditState: 1,
      ApplicationNumber: null,
      detailsItem: {
        ID: null,
        KeyID: null,
        EmployeeOvertimeWorkingApplicationKeyID: null,
        EmployeeID: null,
        EmployeeCode: null,
        EmployeeName: null,
        JobProfession: null,
        JobProfessionName: null,
        OvertimeDate: null,
        StartTime: null,
        EndTime: null,
        DescriptionOfOvertimeWork: null,
        strarData: "00:00:00",
      },
      Details: [],
      Attachments: null,
      options: [
        {
          name: "病假",
          nameEn: "Sick Leave",
          allName: "病假(Sick Leave)",
          value: 1,
        },
        {
          name: "事假",
          nameEn: "Personal Leave",
          allName: "事假(Personal Leave)",
          value: 2,
        },
        {
          name: "工伤",
          nameEn: "Job Injuries",
          allName: "工伤(Job Injuries)",
          value: 3,
        },
        {
          name: "婚假",
          nameEn: "Marriage Leave",
          allName: "婚假(Marriage Leave)",
          value: 4,
        },
        {
          name: "丧假",
          nameEn: "Bereavement Leave",
          allName: "丧假(Bereavement Leave)",
          value: 5,
        },
        {
          name: "护理假",
          nameEn: "Child Birth Leave",
          allName: "护理假(Child Birth Leave)",
          value: 6,
        },
        {
          name: "朝觐",
          nameEn: "Hajj Leave",
          allName: "朝觐(Hajj Leave)",
          value: 7,
        },
        {
          name: "育儿假",
          nameEn: "Parental Leave",
          allName: "育儿假(Parental Leave)",
          value: 8,
        },
        {
          name: "独生子女护理假",
          nameEn: "Only Child Care Leave",
          allName: "独生子女护理假(Only Child Care Leave)",
          value: 9,
        },
      ],
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      strarData: "00:00:00",
    };
  },

  methods: {
    //创建时接收数据
    getCreateData(data1, data2, data3, data4) {
      for (let i = 0; i < 12; i++) {
        const item = JSON.parse(JSON.stringify(this.detailsItem));
        this.Details.push(item);
      }
    },

    //通过员工编码获取请假人信息
    blurEmployeeCode(index) {
      if (this.Details[index].EmployeeCode) {
        getUsersCode({ code: this.Details[index].EmployeeCode }).then((res) => {
          console.log("res", res);
          if (res.status === 200 && res.response) {
            const Employee = res.response;
            this.Details[index].EmployeeID = Employee.ID;
            this.Details[index].EmployeeName = Employee.ChineseName;
            this.Details[index].JobProfession = Employee.PositionID;
          } else {
            this.$message.warning("员工编号不存在");
            this.Details[index].EmployeeID = null;
            this.Details[index].EmployeeCode = null;
            this.Details[index].EmployeeName = null;
            this.Details[index].JobProfession = null;
          }
        });
      }
    },
    //通过选中项目获取分项目
    changeProjectID(type) {
      console.log("子项目", this.ProjectID);
      if (this.ProjectID) {
        getProjectsChildren({ id: this.ProjectID }).then((res) => {
          if (res.status === 200) {
            console.log("子项目", res.response);
            this.subProjectList = res.response;
            if (type) {
              this.SubProjectID = null;
            }
          }
        });
      }
    },
    changeOvertimeDate(index) {
      if (this.Details[index].OvertimeDate) {
        const dateObj = new Date();
        const year = dateObj.getFullYear(); // 获取当前年份
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // 获取当前月份，其中需要将月份加1，因为月份是从0开始计数的
        const day = ("0" + dateObj.getDate()).slice(-2); // 获取当前日期
        const formattedDate = `${year}-${month}-${day}`; // 格式化日期
        const OvertimeDate = this.Details[index].OvertimeDate;
        console.log(formattedDate, OvertimeDate); // 输出当前时间的年月日
        if (formattedDate === OvertimeDate) {
          var hour = dateObj.getHours();
          var minute = dateObj.getMinutes();
          var second = dateObj.getSeconds();
          hour = hour < 10 ? "0" + hour : hour;
          minute = minute < 10 ? "0" + minute : minute;
          second = second < 10 ? "0" + second : second;
          this.Details[index].strarData = hour + ":" + minute + ":" + second;
        } else {
          this.Details[index].strarData = "00:00:00";
        }
      }
    },
    //判断日期是否有误
    changeDate(e) {
      console.log("e", e);
      if (e) {
        let currentTime = new Date(),
          year = currentTime.getFullYear(),
          month =
            currentTime.getMonth() + 1 < 10
              ? "0" + (currentTime.getMonth() + 1)
              : currentTime.getMonth() + 1,
          day =
            currentTime.getDate() < 10
              ? "0" + currentTime.getDate()
              : currentTime.getDate();
        let nowDate = year + "-" + month + "-" + day;

        var currentDate = new Date(nowDate);
        // 获取待比较的日期
        var compareDate = new Date(e);
        if (compareDate >= currentDate) {
          console.log("日期大于当前日期");
        } else {
          this.$message.error("开始日期不能小于当前日期");
          this.detailsItem.StartDate = null;
        }

        if (this.detailsItem.EndDate) {
          var compareEndDate = new Date(this.detailsItem.EndDate);
          if (compareEndDate <= compareDate) {
            this.$message.error("开始日期不能大于或等于结束日期");
            this.detailsItem.StartDate = null;
          }
        }
      }
    },

    //保存
    addManpowerRequest(type) {
      if (!this.ProjectID) {
        this.$message.warning("请选择所属项目部");
        return;
      }
      if (!this.CompanyID) {
        this.$message.warning("请选择所属用工单位");
        return;
      }
      if (!this.DepartmentID) {
        this.$message.warning("请选择所属部门");
        return;
      }
      let Details = JSON.parse(JSON.stringify(this.Details));
      let array = [];
      Details.forEach((item) => {
        if (item.EmployeeCode) {
          array.push(item);
        }
      });
      let isTrue = false;
      if (array.length !== 0) {
        array.forEach((item) => {
          if (!item.EmployeeCode) {
            isTrue = true;
          }
          if (!item.EmployeeName) {
            isTrue = true;
          }
          if (!item.JobProfession) {
            isTrue = true;
          }
          if (!item.OvertimeDate) {
            isTrue = true;
          }
          if (!item.StartTime) {
            isTrue = true;
          }
          if (!item.EndTime) {
            isTrue = true;
          }
        });
      } else {
        this.$message.warning("只要有一条完整的员工数据");
        return;
      }
      if (isTrue) {
        this.$message.warning("数据不完整请填写完整");
        return;
      }
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        ProjectName: this.ProjectName,
        SubProjectID: this.SubProjectID,
        SubProjectName: this.SubProjectName,
        CompanyID: this.CompanyID,
        WorkingUnit: this.WorkingUnit,
        DepartmentID: this.DepartmentID,
        WorkingDepartment: this.WorkingDepartment,
        Note: this.Note,
        EditState: type,
        Details: array,
        Attachments: this.Attachments,
      };
      if (!data.ID) {
        addOvertime2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editOvertime2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },

    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.ProjectName = null;
      this.SubProjectID = null;
      this.SubProjectName = null;
      this.CompanyID = null;
      this.WorkingUnit = null;
      this.DepartmentID = null;
      this.WorkingDepartment = null;
      this.Note = null;
      this.Attachments = null;
      this.detailsItem.ID = null;
      this.detailsItem.KeyID = null;
      this.detailsItem.EmployeeOvertimeWorkingApplicationKeyID = null;
      this.detailsItem.OvertimeDate = null;
      this.detailsItem.StartTime = null;
      this.detailsItem.EndTime = null;
      this.detailsItem.DescriptionOfOvertimeWork = null;
      this.Details = [];
    },
    //编辑回显
    getEditData(data) {
      console.log("data", data);
      this.ID = data.ID;
      this.KeyID = data.KeyID;
      this.ProjectID = data.ProjectID;
      this.ProjectName = data.ProjectName;
      this.SubProjectID = data.SubProjectID;
      this.SubProjectName = data.SubProjectName;
      this.CompanyID = data.CompanyID;
      this.WorkingUnit = data.WorkingUnit;
      this.DepartmentID = data.DepartmentID;
      this.WorkingDepartment = data.WorkingDepartment;
      this.Note = data.Note;
      this.ApplicationNumber = data.ApplicationNumber;
      this.Attachments = data.Attachments;

      for (let i = 0; i < 12; i++) {
        const item = JSON.parse(JSON.stringify(this.detailsItem));
        this.Details.push(item);
      }
      data.Details.forEach((item, index) => {
        item.OvertimeDate = item.OvertimeDate.slice(0, 10);
        item.StartTime = item.StartTime.slice(11, 16);
        item.EndTime = item.EndTime.slice(11, 16);
        item.strarData = "00:00:00";
        this.Details[index] = item;
      });
      this.Details = this.Details.concat();

      console.log("1111", this.Details);
      this.changeProjectID();
      console.log("this.detailsItem", this.detailsItem);
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.right-title {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.customize-main {
  width: 100%;
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 5px;
      padding-right: 5px;
    }
    .text-center {
      text-align: center;
      .tbale-img1 {
        width: 200px;
      }
      h1 {
        font-size: 28px;
        color: #000;
        margin: 10px 0;
      }
      p {
        font-size: 16px;
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .nameEn {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
    }
    .text-left {
      .flex {
        display: flex;
        font-size: 16px;
        color: #000;
        align-items: center;
        margin: 10px 0;
        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .el-checkbox {
        margin-right: 0;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      text-align: center;
      max-width: 200px;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
}
.note-box {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.name-small {
  font-weight: normal !important;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.sign {
  width: 100%;
  max-width: 100px;
}
</style>